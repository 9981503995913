<template>
  <div
    ref="movable"
    :style="position"
    @mousedown.left="dragStart"
    class="--movable-base"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    offsetTop: {
      default: 0,
      type: Number,
    },
    offsetLeft: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      pos: [0, 0, 0, 0],
      holded: false,
      top: this.offsetTop,
      left: this.offsetLeft,
    }
  },
  computed: {
    px() {
      return x => `${x}px`
    },
    position() {
      return { top: this.px(this.top), left: this.px(this.left) }
    },
  },
  mounted() {
    document.addEventListener('mousemove', this.dragMove)
    document.addEventListener('mouseup', this.dragEnd)
  },
  methods: {
    dragStart(e) {
      this.holded = true
      this.pos[2] = e.clientX
      this.pos[3] = e.clientY
    },
    dragMove(e) {
      if (this.holded) {
        this.pos[0] = this.pos[2] - e.clientX
        this.pos[1] = this.pos[3] - e.clientY
        this.pos[2] = e.clientX
        this.pos[3] = e.clientY
        this.top = this.$refs.movable.offsetTop - this.pos[1]
        this.left = this.$refs.movable.offsetLeft - this.pos[0]
      }
    },
    dragEnd() {
      this.holded = false
    },
  },
  destroyed() {
    document.removeEventListener('mousemove', this.dragMove)
    document.removeEventListener('mouseup', this.dragEnd)
  },
}
</script>
<style scoped>
.--movable-base {
  position: absolute;
  z-index: 9;
  text-align: center;
  cursor: pointer;
}
</style>
