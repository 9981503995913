<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306.57 266.21">
    <path
      :fill="color"
      d="M119.35,132.96c-10.48-4.13-20.05-7.92-29.64-11.69-9.16-3.6-18.39-7.04-27.46-10.85-1.87-.79-3.64-2.63-4.69-4.43-12.25-21-24.34-42.08-36.49-63.14C14.54,31.51,7.99,20.18,1.45,8.84-.89,4.78-.25,.97,3.18,.14c1.53-.37,3.48,.05,4.98,.69C31.3,10.75,54.4,20.75,77.5,30.76c36.59,15.85,73.17,31.74,109.76,47.59,38.19,16.55,76.39,33.09,114.59,49.62,2.23,.97,3.72,2.26,3.73,4.98,.01,2.7-1.39,4.06-3.64,5.04-32.6,14.1-65.19,28.21-97.78,42.33-42.19,18.28-84.38,36.59-126.57,54.87-22.98,9.96-45.98,19.87-68.96,29.85-2.66,1.15-5.37,2.14-7.5-.51-1.99-2.48-1.01-5.1,.45-7.63,18.46-31.95,36.9-63.91,55.28-95.9,1.68-2.93,3.68-4.98,6.98-6.24,17.46-6.66,34.84-13.55,52.24-20.37,.79-.31,1.57-.67,3.28-1.41Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SendIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
