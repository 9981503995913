<template>
  <ModalLayout id="DeleteAccountModal">
    <template v-slot:title>Delete Account</template>
    <template v-slot:body>
      <p>Type your password to finalise the deletion of your account</p>
      <form class="form-group my-3">
        <!-- This is here to prevent browser warnings for better forms -->
        <input hidden type="text" autocomplete="username" value="username" />
        <input
          v-model="current_pass"
          data-test="curr-pass"
          type="password"
          class="form-control"
          :class="{
            'is-invalid':
              v$.current_pass.$invalid || (error && errorType === 'wrong-pass'),
          }"
          placeholder="Password"
          autocomplete="current-password"
        />
        <small
          v-if="v$.current_pass.required.$invalid"
          class="text-danger mt-2 ms-2"
        >
          This field is required!
        </small>
      </form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-custom" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        data-test="submit-reset-pass"
        class="btn btn-danger"
        type="submit"
        @click="deleteAccount"
        :disabled="v$.$invalid || deleteAccountLoader"
      >
        <span
          v-if="deleteAccountLoader"
          class="spinner-border spinner-border-sm mx-3 text-info"
        ></span>
        <span v-else>Delete</span>
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Auth } from 'aws-amplify'
import { Modal } from 'bootstrap'
import ModalLayout from './ModalLayout.vue'

export default {
  components: {
    ModalLayout,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    current_pass: {
      required,
    },
  },
  data() {
    return {
      current_pass: '',
      error: false,
      errorMsg: '',
      errorType: '',
    }
  },
  methods: {
    async deleteAccount() {
      const DeleteAccountModal = Modal.getOrCreateInstance(
        document.getElementById('DeleteAccountModal'),
      )
      this.$store.dispatch('loadingManager/start', 'deleteAccount')

      const cognitoUser = await Auth.currentAuthenticatedUser()
      Auth.changePassword(cognitoUser, this.current_pass, this.current_pass)
        .then(async () => {
          const userSub = this.user.sub
          const userEmail = this.user.email
          this.leaveOrganizations(userSub, userEmail).then(() => {
            setTimeout(() => {
              cognitoUser.deleteUser()
              Auth.signOut().then(() => {
                this.$store.dispatch('loadingManager/end', 'deleteAccount')
                this.$store.commit('resetOrganization')
                this.$store.commit('resetIntegration')
                this.$store.commit('profile/setUserData', null)
                this.$router.push({ path: '/authorize' })
              })
            }, 1000)
            DeleteAccountModal.hide()
          })
        })
        .catch(err => {
          this.$store.dispatch('loadingManager/end', 'deleteAccount')
          if (err.code === 'NotAuthorizedException') {
            this.error = true
            this.errorType = 'wrong-pass'
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: 'danger',
              msg: 'Wrong password',
            })
          }
          if (err.code === 'LimitExceededException') {
            this.error = true
            this.errorType = 'wrong-pass'
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: 'danger',
              msg: 'Attempt limit exceeded, please try after some time.',
            })
          }
        })
    },
    async leaveOrganizations(userSub, userEmail) {
      this.organizations.forEach(async org => {
        const orgId = org.tablePartitionKey.split('#')[1]

        this.$store
          .dispatch('organization/getUserIntegrations', {
            org: orgId,
            userSub,
          })
          .then(async integrationsList => {
            const sourceList = integrationsList.map(
              integration => integration.tableSortKey,
            )

            if (sourceList?.length) {
              this.$store.dispatch('organization/removeUserFromIntegrations', {
                sourceList,
                org: orgId,
                userSub,
              })
            }

            this.$store.dispatch('organization/removeUserFromOrganization', {
              org: orgId,
              userSub,
              userEmail,
            })
          })
      })
    },
  },
  computed: {
    user() {
      return this.$store.getters['profile/user']
    },
    organizations() {
      return this.$store.getters['organization/joined']
    },
    deleteAccountLoader() {
      return this.$store.getters['loadingManager/isLoading']('deleteAccount')
    },
  },
}
</script>
