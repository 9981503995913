<template>
  <div v-if="!isRestrictedRole" class="mx-3 mb-2">
    <div class="d-flex justify-content-between pb-1">
      <div class="d-flex align-items-center">
        <div class="d-flex image align-items-center">
          <BaseIcon
            v-bind="
              notificationSourceIcon[data.dataSource.split('#')[2]] ||
              notificationSourceIcon.settingsIcon
            "
            size="15"
          />
        </div>
        <div class="mx-1 font-weight-bold">
          {{ data.title }}
        </div>
      </div>
      <div>
        <BaseIcon
          :class="notificationClassIcon[data.classification].class"
          :name="notificationClassIcon[data.classification].name"
          :size="notificationClassIcon[data.classification].size"
          :color="notificationClassIcon[data.classification].color"
        />
      </div>
    </div>
    <div class="font-weight-bold pb-1">{{ data.messageBody }}</div>
    <div
      v-if="data.actionGetters !== 'null' && data.actionGetters !== null"
      class="d-flex justify-content-center pt-2"
    >
      <div class="font-weight-bold text-center flex-fill">
        <button
          class="btn btn-primary full-width"
          type="submit"
          @click.prevent="acceptAction"
        >
          Accept
        </button>
      </div>
      <div
        v-if="cancel !== undefined"
        class="font-weight-bold text-center flex-fill"
      >
        <button
          class="btn btn-secondary full-width"
          type="submit"
          @click.prevent="cancelAction"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between pt-2">
      <div class="font-weight-bold">
        <router-link
          v-if="org"
          :to="'/organization/' + data.organization"
          class="nav-item text-start"
          ><strong>{{ org.displayName }}</strong>
        </router-link>
      </div>
      <div class="text-muted">
        {{ data.timestamp }}
      </div>
    </div>
    <div class="row-1 mx-3 pt-1">
      <hr class="my-2" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '../common/icons/BaseIcon.vue'
import moduleConstructors from '../../helpers/dynamicOrgConstructur'

export default {
  components: {
    BaseIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notificationClassIcon: {
        warning: {
          class: 'warn-icon',
          name: 'exclamationcircle',
          size: 16,
          color: 'orange',
        },
        info: {
          class: 'info-icon',
          name: 'help',
          size: 16,
          color: '#00b0eb',
        },
        error: {
          class: 'error-icon',
          name: 'xmarkcircle',
          size: 16,
          color: 'red',
        },
      },
      notificationSourceIcon: {
        Jira: {
          name: 'jira',
          color: '#0d6efd',
        },
        settingsIcon: {
          name: 'gear',
        },
      },
    }
  },
  computed: {
    isRestrictedRole() {
      const role = this.$store.getters['organization/current'].organizationRole
      return role === 'READER' || role === 'VIEWER'
    },
    org() {
      return this.$store.getters['organization/joined'].find(organization =>
        organization.tablePartitionKey.includes(this.data.organization),
      )
    },
    accept() {
      const { accept } =
        this.$store.getters[`notifications/actions/${this.data.actionGetters}`]
      return accept
    },
    cancel() {
      const { cancel } =
        this.$store.getters[`notifications/actions/${this.data.actionGetters}`]
      return cancel
    },
  },
  methods: {
    async acceptAction() {
      await this.loadOrgMembers()
      this.$store.dispatch(`notifications/actions/${this.accept}`, this.data)
    },
    async cancelAction() {
      await this.loadOrgMembers()
      this.$store.dispatch(`notifications/actions/${this.cancel}`, this.data)
    },
    async loadOrgMembers() {
      // if organization is not loaded, load organization and get users
      if (
        !this.$store.hasModule([
          'organization',
          `org#${this.data.organization}`,
        ])
      ) {
        const { displayName, tablePartitionKey, tableSortKey, slug, settings } =
          this.org
        await this.$store.registerModule(
          ['organization', `org#${this.data.organization}`],
          moduleConstructors.singleOrgModule({
            displayName,
            tablePartitionKey,
            tableSortKey,
            slug,
            mainTab: settings?.mainTab,
            activeTabs: settings?.activeTabs,
          }),
        )

        await this.$store.dispatch(
          `organization/org#${this.data.organization}/getOrganizationPeople`,
          this.data.organization,
        )
      }
    },
  },
}
</script>

<style scoped>
.color-gray {
  background-color: var(--custom-gray-light);
}
img {
  max-width: 100%;
  max-height: 100%;
}
.image {
  height: 15px;
}
.warn-icon {
  color: orange;
}
.error-icon {
  color: red;
}
.info-icon {
  color: #00b0eb;
}
</style>
