import { API, graphqlOperation } from 'aws-amplify'
import { recordsByTablePartitionKey } from '../../graphql/queries'
import { deleteIntegrationDataByQuery } from '../../graphql/mutations'

export default {
  namespaced: true,
  state: {
    integrations: [],
    current: null,
    new: {
      settings: {},
      title: '',
      dataSource: '',
      description: '',
      enabled: true,
      organization: '',
      integrationTag: '',
    },
    isInvalid: true,
  },
  actions: {
    async getAll({ dispatch, commit, getters, rootGetters }) {
      const org =
        rootGetters['organization/current'].tablePartitionKey.split('#')[1]
      const getAll = await API.graphql(
        graphqlOperation(recordsByTablePartitionKey, {
          tablePartitionKey: `organization#${org}`,
          tableSortKey: {
            beginsWith: `integrationSettings#`,
          },
        }),
      ).catch(() => {
        dispatch(
          'notifications/alerts/appendAlert',
          {
            type: 'danger',
            msg: 'Error getting integrations',
          },
          { root: true },
        )
        commit('setAll', false)
      })

      commit('setAll', [...getAll.data.recordsByTablePartitionKey.items])
      if (getters.current) dispatch('getCurrent', getters.current.tableSortKey)
    },
    getCurrent({ commit, getters, rootGetters }, sortKey) {
      const partitionKey = `organization#${
        rootGetters['organization/current'].tablePartitionKey.split('#')[1]
      }`
      const current = getters.all.find(
        item =>
          item.tablePartitionKey === partitionKey &&
          item.tableSortKey === sortKey,
      )
      if (current?.settings) current.settings = JSON.parse(current.settings)

      commit('setCurrent', current)
      if (!current) commit('clearCurrent')
    },
    deleteData(
      { dispatch },
      { tablePartitionKey, tableSortKey, title, settings },
    ) {
      return API.graphql({
        query: deleteIntegrationDataByQuery,
        variables: {
          input: {
            organizationID: tablePartitionKey.split('#')[1],
            integrationSource: tableSortKey,
            deleteIntegration: true,
            title,
            settings: JSON.stringify(settings),
          },
        },
      })
        .then(async () => {
          await dispatch('integrations/getAll')
        })
        .catch(err => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'danger',
              msg: 'Error deleting integration',
            },
            { root: true },
          )
          throw err
        })
    },
  },
  mutations: {
    setAll(state, integrations) {
      state.integrations = integrations
    },
    setCurrent(state, value) {
      state.current = value
    },
    clearCurrent(state) {
      state.current = null
    },
    setNew(state, value) {
      state.new = value
    },
    clearNew(state) {
      state.new = {
        settings: {},
        title: '',
        dataSource: '',
        description: '',
        enabled: true,
        organization: '',
        integrationTag: '',
      }
    },
    setIsInvalid(state, value) {
      state.isInvalid = !!value
    },
  },
  getters: {
    all: state => state.integrations,
    current: state => state.current,
    new: state => state.new,
    isInvalid: state => state.isInvalid,
  },
}
