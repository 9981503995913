import { API } from 'aws-amplify'
import { searchIntegrationData } from '../../graphql/queries'
import filters from './reports/filters'

export default {
  namespaced: true,
  modules: {
    filters,
  },
  state: {
    data: [],
    error: false,
    lastPageData: false,
    selectedFilters: {}, // for export module
    export: [],
    pageToken: null,
    dateStart: {
      type: 'preset',
      value: 'today',
    },
  },
  actions: {
    applyFilters({ dispatch, commit }) {
      commit('loading', null, { root: true })
      dispatch('getCurrentUserWorklog')
    },
    getCurrentUserWorklog({ dispatch, getters }) {
      dispatch('clearAllStorages').then(async () => {
        await dispatch('pullWorklogData', {
          searchAll: '',
          pageToken: getters.pageToken,
          limit: 100,
        })
      })
    },
    async pullWorklogData({ commit, rootGetters, getters, dispatch }, payload) {
      // Temp fix TODO refactor const
      const granularity =
        getters['filters/selectedOption']('granularity') === 'None' ||
        getters['filters/selectedOption']('granularity') === ''
          ? 'Day'
          : getters['filters/selectedOption']('granularity')

      const sort = {
        field: 'timestamp',
        direction: 'desc',
      }
      const primaryHasLogs = true
      const secondaryPayload = { payload, sort, granularity, primaryHasLogs }
      // limit field is null to get aggregate data. Should be set to int if we need to get actual worklogs
      await API.graphql({
        query: searchIntegrationData,
        variables: {
          organizationID:
            rootGetters['organization/current'].tablePartitionKey.split('#')[1],
          worklogOwner: rootGetters['profile/user'].sub,
          timezone: rootGetters['profile/user']['custom:timezone'],
          limit: payload.limit || null,
          sort,
          groupBy: 'Date',
          granularity,
          nextToken: payload.pageToken,
          searchString: payload.searchAll,
          fields: payload.fields,
        },
      })
        .then(async response => {
          // If no items are returned, skip the formatation
          if (
            response.data.searchIntegrationData.items !== null &&
            response.data.searchIntegrationData.items.length > 0
          ) {
            // Primary account, and has worklogs. Visualize them and try to get worklogs from secondary accounts
            commit('addData', response.data.searchIntegrationData.items)
            dispatch('getSecondaryAccountsWorkLog', secondaryPayload)
          } else {
            // Primary account, and has no worklogs. Try to get worklogs from secondary accounts
            secondaryPayload.primaryHasLogs = false
            dispatch('getSecondaryAccountsWorkLog', secondaryPayload)
          }
          const { nextToken } = response.data.searchIntegrationData
          commit('setPageToken', nextToken)
          if (nextToken) {
            await dispatch('pullWorklogData', {
              searchAll: '',
              pageToken: nextToken,
              limit: 100,
            })
          }
          // Pagination for workbits
          if (
            response.data.searchIntegrationData.nextToken !== null &&
            response.data.searchIntegrationData.items.length < 10
          ) {
            commit('setLastPageDataFlag', true)
          }
          if (getters.data.length === 0) {
            commit('setError', 'No data found')
            commit('loaded', null, { root: true })
            dispatch('loadingManager/end', 'feedLoader', { root: true })
          }
        })
        .catch(() => {
          commit('setError', 'No data found')
          commit('loaded', null, { root: true })
          dispatch('loadingManager/end', 'feedLoader', { root: true })
        })
      return true
    },
    async getSecondaryAccountsWorkLog(
      { commit, dispatch, getters, rootGetters },
      payload,
    ) {
      const memberOptions = getters['filters/membersOptions']
      if (
        memberOptions.filter(
          user => user.sub === rootGetters['profile/user'].sub,
        ).length > 0
      ) {
        if (
          memberOptions.filter(
            user => user.sub === rootGetters['profile/user'].sub,
          )[0].secondaryAccounts !== null &&
          memberOptions.filter(
            user => user.sub === rootGetters['profile/user'].sub,
          )[0].secondaryAccounts !== undefined
        ) {
          const { secondaryAccounts } = memberOptions.filter(
            user => user.sub === rootGetters['profile/user'].sub,
          )[0]

          JSON.parse(secondaryAccounts).forEach(account => {
            API.graphql({
              query: searchIntegrationData,
              variables: {
                organizationID:
                  rootGetters['organization/current'].tablePartitionKey.split(
                    '#',
                  )[1],
                worklogOwner: account,
                timezone: rootGetters['profile/user']['custom:timezone'],
                limit: payload.payload.limit || null,
                sort: payload.sort,
                groupBy: 'Date',
                granularity: payload.granularity,
                nextToken: payload.payload.pageToken,
                searchString: payload.payload.searchAll,
                fields: payload.payload.fields,
              },
            })
              .then(async response => {
                if (
                  response.data.searchIntegrationData.items !== null &&
                  response.data.searchIntegrationData.items.length > 0
                ) {
                  commit('addData', response.data.searchIntegrationData.items)
                } else if (payload.primaryHasLogs === false) {
                  // No primary logs and no secondary worklogs either
                  commit('setError', 'No data found')
                  commit('loaded', null, { root: true })
                  dispatch('loadingManager/end', 'feedLoader', { root: true })
                }
              })
              .catch(() => {
                commit('setError', 'No data found')
                commit('loaded', null, { root: true })
                dispatch('loadingManager/end', 'feedLoader', { root: true })
              })
          })
        } else {
          // No primary logs and no secondary accounts
          if (getters.data.length === 0) commit('setError', 'No data found')
          commit('loaded', null, { root: true })
          dispatch('loadingManager/end', 'feedLoader', { root: true })
        }
      }
    },
    clearAllStorages({ commit }) {
      // Reset the lastPage flag
      commit('setLastPageDataFlag', false)
      // Reset the previously collected data
      commit('clearData')
      commit('clearPageToken')
      // Reset the persistent JS reduce method storage
      // commit('clearPersistentReduceStorage')
      // Reset the not formatted data (original data, received by the GraphQL) storage
      // commit('clearNotFormattedData')
    },
  },
  mutations: {
    setDateStart(state, value) {
      state.dateStart = value
    },
    setSelectedFilters(state, value) {
      state.selectedFilters = value
    },
    addData(state, items) {
      state.data.push(...items)
      state.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
    modifyData(state, item) {
      const index = state.data.findIndex(workbit => {
        return workbit.tableSortKey === item.tableSortKey
      })
      state.data[index] = item
      state.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
    clearData(state) {
      state.data = []
    },
    setLastPageDataFlag(state, value) {
      state.lastPageData = value
    },
    setError(state, value) {
      state.error = value
    },
    clearError(state) {
      state.error = false
    },
    setPageToken(state, value) {
      state.pageToken = value
    },
    clearPageToken(state) {
      state.pageToken = null
    },
    loaded(state) {
      state.loading = false
    },
  },
  getters: {
    lastPageDataFlag: state => state.lastPageData,
    getSelectedFilters: state => state.selectedFilters,
    dateStart: state => state.dateStart,
    data: state => state.data,
    error: state => state.error,
    pageToken: state => state.pageToken,
  },
}
