import { API, graphqlOperation } from 'aws-amplify'
import { DateTime } from 'luxon'
import { getNotificationsData } from '../../graphql/queries'
import { deleteNotification } from '../../graphql/mutations'

import alerts from '../notifications/alerts'
import actions from '../notifications/actions'

export default {
  namespaced: true,
  modules: {
    alerts,
    actions,
  },
  state: {
    shownNotifications: {
      types: ['warning', 'error'],
      searchString: '',
    },
    notifications: {
      info: [],
      warning: [],
      error: [],
    },
    lastPageData: false,
    error: false,
  },
  actions: {
    clearNotifications({ commit }) {
      commit('clearNotifications')
      commit('setLastPageDataFlag', false)
      commit('clearError')
    },

    checkIfNoData({ commit }, notifications) {
      if (notifications.length === 0) {
        commit('setError', 'No notifications available')
      }
    },

    filterNotifications({ commit, getters, dispatch }, selectedFilter) {
      commit('setShownNotifications', selectedFilter)
      if (selectedFilter.types.length === 0) {
        commit('setError', 'No filter selected')
      } else {
        commit('clearError')
        dispatch('checkIfNoData', getters.allNotifications)
      }
    },

    addNewNotifications({ commit }, notification) {
      commit('clearError')
      commit('setNewNotification', notification)
    },

    async loadNotifications({ commit, dispatch, getters }) {
      commit('loading', null, { root: true })
      await dispatch('getNotifications')
      dispatch('checkIfNoData', getters.allNotifications)
    },

    async getNotifications({ rootGetters, commit, dispatch }, nextToken) {
      const pulledNotification = {
        info: [],
        warning: [],
        error: [],
      }
      await API.graphql(
        graphqlOperation(getNotificationsData, {
          nextToken,
        }),
      ).then(async response => {
        response.data.getNotificationsData.items.forEach(item => {
          const notification = item
          if (rootGetters['profile/user']['custom:timezone']) {
            notification.timestamp = DateTime.fromISO(notification.timestamp)
              .toUTC()
              .setZone(rootGetters['profile/user']['custom:timezone'])
              .toFormat('dd LLL yyyy h:mm a')
          } else {
            notification.timestamp = DateTime.fromISO(notification.timestamp)
              .toUTC()
              .toFormat('dd LLL yyyy h:mm a')
          }
          pulledNotification[notification.classification].push(notification)
        })
        commit('setNotifications', pulledNotification)

        if (response.data.getNotificationsData.nextToken !== null) {
          await dispatch(
            'getNotifications',
            response.data.getNotificationsData.nextToken,
          )
        } else {
          commit('setLastPageDataFlag', true)
        }
      })
    },
    async deleteNotificationfromTable(state, notificationData) {
      await API.graphql({
        query: deleteNotification,
        variables: {
          input: {
            tablePartitionKey: notificationData.tablePartitionKey,
            tableSortKey: notificationData.tableSortKey,
          },
        },
      })
    },

    removeNotificationFromStore(
      { getters, commit, dispatch },
      notificationData,
    ) {
      commit('removeSingleNotification', notificationData)
      dispatch('checkIfNoData', getters.allNotifications)
    },
  },
  mutations: {
    clearNotifications(state) {
      state.notifications.info = []
      state.notifications.warning = []
      state.notifications.error = []
    },
    setNotifications(state, notifications) {
      state.notifications.info = state.notifications.info.concat(
        notifications.info,
      )
      state.notifications.warning = state.notifications.warning.concat(
        notifications.warning,
      )
      state.notifications.error = state.notifications.error.concat(
        notifications.error,
      )
    },
    setNewNotification(state, notification) {
      state.notifications[notification.classification] =
        state.notifications[notification.classification].concat(notification)
    },
    removeSingleNotification(state, removednotification) {
      state.notifications[removednotification.classification] =
        state.notifications[removednotification.classification].filter(
          notification =>
            notification.tableSortKey !== removednotification.tableSortKey,
        )
    },
    setLastPageDataFlag(state, value) {
      state.lastPageData = value
    },
    setError(state, value) {
      state.error = value
    },
    clearError(state) {
      state.error = false
    },
    setShownNotifications(state, value) {
      state.shownNotifications.types = value.types
      state.shownNotifications.searchString = value.searchString
    },
  },
  getters: {
    allNotifications: state =>
      [
        ...state.notifications.info,
        ...state.notifications.warning,
        ...state.notifications.error,
      ].sort(
        (first, second) =>
          new Date(second.timestamp) - new Date(first.timestamp),
      ),
    /** Below we are using null to handle old notification from the DB.
     * Because of the SQS we need to pass the null value as a string -> "null"
     * After all old notifications are gone from the DB we can remove the check for null
     */
    nonActionableNotifications: state =>
      [
        ...state.notifications.info,
        ...state.notifications.warning,
        ...state.notifications.error,
      ]
        .filter(
          item => item.actionGetters === 'null' || item.actionGetters === null,
        )
        .sort(
          (first, second) =>
            new Date(second.timestamp) - new Date(first.timestamp),
        ),
    actionableNotifications: state =>
      [
        ...state.notifications.info,
        ...state.notifications.warning,
        ...state.notifications.error,
      ]
        .filter(
          item => item.actionGetters !== 'null' && item.actionGetters !== null,
        )
        .sort(
          (first, second) =>
            new Date(second.timestamp) - new Date(first.timestamp),
        ),
    pendingRequests: state =>
      [...state.notifications.info]
        .filter(item => item.actionGetters === 'getJoinOrganizationActions')
        .map(el => el.dataSource.split('#')[2]),
    lastPageDataFlag: state => state.lastPageData,
    error: state => state.error,
    shownNotifications: state => state.shownNotifications,
    infoNotifications: state => state.notifications.info,
    warningNotifications: state => state.notifications.warning,
    errorNotifications: state => state.notifications.error,
  },
}
