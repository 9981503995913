<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305.14 300">
    <path
      :fill="color"
      d="M145.54,217.5c0-17.38,5.08-33.57,13.84-47.17l-20.34-20.33,39.73-39.79c17.58-17.58,27.48-41.42,27.48-66.27v-6.44c10.37,0,18.75-8.38,18.75-18.75S216.62,0,206.25,0H18.75C8.38,0,0,8.38,0,18.75s8.38,18.75,18.75,18.75v6.44c0,24.85,9.9,48.69,27.48,66.27l39.73,39.79-39.73,39.78c-17.58,17.58-27.48,41.43-27.48,66.27v6.45c-10.37,0-18.75,8.38-18.75,18.75s8.38,18.75,18.75,18.75H204.33c-34.21-11.8-58.79-44.28-58.79-82.5ZM69.71,80.48c-8.66-10.15-13.46-23.08-13.46-36.54v-6.44h112.5v6.38c0,13.49-4.82,26.44-13.52,36.6H69.71Zm163.77,65.35c-39.59,0-71.67,32.09-71.67,71.67s32.08,71.66,71.67,71.66,71.66-32.08,71.66-71.66-32.08-71.67-71.66-71.67Zm19.11,81.66h-20.79c-4.59,0-8.32-3.72-8.32-8.32v-33.28c0-4.59,3.72-8.32,8.32-8.32h0c4.59,0,8.32,3.72,8.32,8.32v24.96h12.48c4.59,0,8.32,3.72,8.32,8.32s-3.72,8.32-8.32,8.32Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HourGlassIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
