<template>
  <label editable class="url-holder d-flex">
    <span v-if="path">{{ path }}</span>
    <input
      contenteditable
      :disabled="disableInput"
      class="slugInput fw-bold p-0 m-0 border-0"
      v-model="displayedSlug"
    />
    <button
      :disabled="disableCopy"
      class="btn btn-clear p-0 m-0 border-0"
      @click="copy"
    >
      <BaseIcon name="clipboard" :size="16" />
    </button>
  </label>
</template>

<script>
import BaseIcon from './icons/BaseIcon.vue'

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    path: String,
    regex: {
      type: RegExp,
      default: () => /[^a-z0-9-]/gi,
    },
    disableCopy: Boolean,
    disableInput: Boolean,
  },
  components: {
    BaseIcon,
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.URL)
      this.$store.dispatch('notifications/alerts/appendAlert', {
        type: 'info',
        msg: `${this.URL} copied to clipboard!`,
      })
    },
  },
  computed: {
    URL() {
      return `${process.env.VUE_APP_URL}/organization/${this.displayedSlug}`
    },
    displayedSlug: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value.replace(this.regex, '').toLowerCase())
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.slugInput {
  width: 100%;
  background-color: transparent;
  border: 0;
  color: var(--bs-gray-600);
  padding-bottom: 0.2rem;
}
</style>
