<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 27.615 28.922"
  >
    <g
      id="Group_249"
      data-name="Group 249"
      transform="translate(-717.992 -273.089)"
    >
      <path
        id="Path_6376"
        data-name="Path 6376"
        d="M722.276,290.478a9.534,9.534,0,1,0,13.279-8.769l-3.076,3.078v-3.7l-3.7,3.7V281.44a9.536,9.536,0,0,0-6.5,9.038"
        fill="#ffdfca"
      />
      <path
        id="Path_6377"
        data-name="Path 6377"
        d="M734.575,289.273a1.028,1.028,0,0,0-.011.112.99.99,0,0,0,1.98,0,1.043,1.043,0,0,0-.011-.112Zm-7.489,0a1.028,1.028,0,0,0-.011.112.99.99,0,0,0,1.98,0,1.043,1.043,0,0,0-.011-.112Z"
        fill="#181716"
      />
      <path
        id="Path_6378"
        data-name="Path 6378"
        d="M733.652,292.027a.99.99,0,0,0-.99-.99h-1.705a.99.99,0,0,0,0,1.98h1.705a.99.99,0,0,0,.99-.99"
        fill="#eab6a4"
      />
      <path
        id="Path_6379"
        data-name="Path 6379"
        d="M728.77,294.456v-.738a.705.705,0,0,0-1.41,0v.468c.464.107.935.2,1.41.27m7.489-.266v-.472a.7.7,0,1,0-1.41,0v.741q.712-.11,1.41-.269"
        fill="#fff"
      />
      <path
        id="Path_6380"
        data-name="Path 6380"
        d="M720.724,287.33a2.33,2.33,0,0,0-.366-.037,2.365,2.365,0,1,0,0,4.731c.012,0,.024,0,.036,0a11.074,11.074,0,0,1,.33-4.69"
        fill="#eab6a4"
      />
      <path
        id="Path_6381"
        data-name="Path 6381"
        d="M745.608,289.658a2.365,2.365,0,0,0-2.365-2.365,2.33,2.33,0,0,0-.348.035,11.491,11.491,0,0,1,.444,3.156,11.644,11.644,0,0,1-.114,1.538h.018a2.365,2.365,0,0,0,2.365-2.365"
        fill="#eab6a4"
      />
      <path
        id="Path_6382"
        data-name="Path 6382"
        d="M738.834,288.283a.99.99,0,0,0-.99-.99H725.776a.99.99,0,0,0,0,1.98h12.068a.99.99,0,0,0,.99-.99"
        fill="#eab6a4"
      />
      <path
        id="Path_6383"
        data-name="Path 6383"
        d="M722.47,292.361a9.523,9.523,0,0,0,9.35,7.664h.006a9.494,9.494,0,0,0,9.323-7.664,19.8,19.8,0,0,1-18.679,0"
        fill="#eab6a4"
      />
      <path
        id="Path_6384"
        data-name="Path 6384"
        d="M741.343,290.478a9.534,9.534,0,1,1-12.567-9.038v3.347l3.7-3.7v3.7l3.076-3.079a9.537,9.537,0,0,1,5.788,8.77m.755-5.2a22.96,22.96,0,0,1-2.03-9.463,24.9,24.9,0,0,1,.157-2.731l-4.94,5.046V273.1l-5.031,5.031V273.1c-2.4,2.5-8.059,8.855-9.28,13.44a11.532,11.532,0,1,0,21.124-1.255"
        fill="#8ccbde"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WmLogo',
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
  },
}
</script>
