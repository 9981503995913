import { Auth, API, graphqlOperation } from 'aws-amplify'
import {
  recordsByTablePartitionKey,
  getWildmetricCollectionRecords,
} from '../../graphql/queries'

import accountInfo from './account-info'
import preferences from './preferences'

const profile = {
  namespaced: true,
  modules: {
    accountInfo,
    preferences,
  },
  state: () => ({
    userData: null,
    readerData: [],
    currentReaderIndex: 0,
  }),
  mutations: {
    setUserData(state, data) {
      state.userData = data
    },
    setReaderData(state, data) {
      state.readerData = data
    },
    addReaderData(state, item) {
      state.readerData.push(item)
    },
    setCurrentReader(state, value) {
      state.currentReaderIndex = value
    },
  },
  actions: {
    async updateDefaultOrg({ dispatch }, slug) {
      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, {
        'custom:default_org': slug || '',
      })
        .then(() => {
          dispatch('forceUpdateUser')
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'success',
              msg: 'Success updating default organization',
            },
            { root: true },
          )
        })
        .catch(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: 'Error updating default organization',
            },
            { root: true },
          )
        })
    },
    async saveTimezone({ dispatch }, timezone) {
      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, {
        'custom:timezone': timezone,
      })
        .then(() => {
          dispatch('forceUpdateUser')
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'success',
              msg: 'Success updating default timezone',
            },
            { root: true },
          )
        })
        .catch(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: 'Error updating default timezone',
            },
            { root: true },
          )
        })
    },
    async forceUpdateUser({ commit }) {
      await Auth.currentAuthenticatedUser({ bypassCache: true }).then(data => {
        commit('setUserData', data)
      })
    },
    async fetchReaderData({ rootGetters, commit, dispatch }) {
      commit('setReaderData', [])
      const userData = rootGetters['profile/user']
      const orgId = rootGetters['organization/current'].tablePartitionKey
        .split('#')
        .pop('')

      API.graphql(
        graphqlOperation(recordsByTablePartitionKey, {
          tablePartitionKey: `organization#${orgId}`,
          tableSortKey: {
            beginsWith: `sharedFilter#${userData.sub}`,
          },
        }),
      ).then(response => {
        const data = response.data.recordsByTablePartitionKey.items

        data.forEach((item, index) => {
          const settings = JSON.parse(item.settings)
          const obj = {
            tablePartitionKey: item.tablePartitionKey,
            tableSortKey: item.tableSortKey,
            settings,
            description: item.description,
            name: settings.filterId.split('#')[2],
          }

          API.graphql(
            graphqlOperation(getWildmetricCollectionRecords, {
              tablePartitionKey: `organization#${orgId}`,
              tableSortKey: settings.filterId,
              organization: orgId,
            }),
          ).then(res => {
            const filter = res.data.getWildmetricCollectionRecords?.filters
            if (filter === null) return
            obj.filters = filter
            if (index === 0) dispatch('setDateRangeReader', filter)

            commit('addReaderData', obj)
          })
        })
      })
    },
    setDateRangeReader({ commit, rootGetters }, filter) {
      const dateRangeFilter = filter?.filter(el => el.name === 'dateRange')[0]
      const membersFilter = filter?.filter(el => el.name === 'members')[0].value
      const projectsFilter = filter?.filter(el => el.name === 'projects')[0]
        .value

      const [start, end, quickSelect] = dateRangeFilter.value
      const value = {
        start,
        end,
        quickSelect,
      }

      const memberOptions = rootGetters[
        'reports/filters/membersOptions'
      ].filter(member => member.primaryAccount === null)

      const members = memberOptions.filter(member =>
        membersFilter.includes(member.name),
      )

      const projectOptions = rootGetters['reports/filters/projectsOptions']

      const porjects = projectOptions.filter(project =>
        projectsFilter.includes(project),
      )

      commit(
        'reports/filters/setFilterValue',
        {
          key: 'dateRange',
          value,
        },
        { root: true },
      )

      commit(
        'reports/filters/setFilterValue',
        {
          key: 'members',
          value: members,
        },
        { root: true },
      )

      commit(
        'reports/filters/setFilterValue',
        {
          key: 'projects',
          value: porjects,
        },
        { root: true },
      )
    },
  },
  getters: {
    user: state => (state.userData ? state.userData.attributes : ''),
    readerData: state => state.readerData[state.currentReaderIndex],
    getReader: state => index => state.readerData[index],
    sharedData: state => state.readerData,
    readerIndex: state => state.currentReaderIndex,
  },
}

export default profile
