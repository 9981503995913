import Vue from 'vue'
import Vuex from 'vuex'
// import Amplify from 'aws-amplify'
import integrations from './integrations'
import reports from './modules/reports'
import mywork from './modules/mywork'
import notifications from './modules/notifications'
import onboard from './modules/onboard'
import linkAccount from './modules/linkAccount'
import userRequests from './modules/userRequests'

// refactored modules
import profile from './profile'
import loadingManager from './loading'
import organization from './organization'
import subscriptions from './subscriptions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    organization,
    integrations,
    reports,
    mywork,
    notifications,
    onboard,
    profile,
    linkAccount,
    loadingManager,
    subscriptions,
    userRequests,
  },
  state: {
    navBarToggled: window.innerWidth < 1024,
    loading: true,
    search: '',
    loggedInUser: false,
    userData: null,
    // notifications: [],
    formError: '',
    validToken: '',
  },
  mutations: {
    toggleNavBar(state) {
      state.navBarToggled = !state.navBarToggled
    },
    loaded(state) {
      state.loading = false
    },
    loading(state) {
      state.loading = true
    },
    resetOrganization(state) {
      state.organization = {
        current: false,
        joined: {},
        tags: {
          organizationTags: [],
        },
      }
    },
    resetIntegration(state) {
      state.integrations.integrations = []
    },
    changeFormError(state, message) {
      state.formError = message
    },
  },
  actions: {},
  getters: {
    search: state => state.search.toLowerCase(),
    // notification: state => state.notifications,
    formError: state => state.formError,
    loading: state => state.loading,
  },
})
