/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateIntegrationStatus = /* GraphQL */ `
  subscription OnUpdateIntegrationStatus(
    $tablePartitionKey: String!
    $integrationStatus: String!
  ) {
    onUpdateIntegrationStatus(
      tablePartitionKey: $tablePartitionKey
      integrationStatus: $integrationStatus
    ) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const onCrerateStripeSubscription = /* GraphQL */ `
  subscription OnCrerateStripeSubscription {
    onCrerateStripeSubscription {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const onCreateWildmetricNotifications = /* GraphQL */ `
  subscription OnCreateWildmetricNotifications {
    onCreateWildmetricNotifications {
      tablePartitionKey
      tableSortKey
      organization
      classification
      messageBody
      seen
      title
      timestamp
      ttl
      dataSource
      actionGetters
      createdAt
      updatedAt
      __typename
    }
  }
`
export const onDeleteJoinOrganizationRequest = /* GraphQL */ `
  subscription OnDeleteJoinOrganizationRequest {
    onDeleteJoinOrganizationRequest {
      tablePartitionKey
      tableSortKey
      status
      __typename
    }
  }
`
export const onUpdateUserData = /* GraphQL */ `
  subscription OnUpdateUserData(
    $tablePartitionKey: String!
    $tableSortKey: String!
  ) {
    onUpdateUserData(
      tablePartitionKey: $tablePartitionKey
      tableSortKey: $tableSortKey
    ) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const onDeleteWildmetricNotification = /* GraphQL */ `
  subscription OnDeleteWildmetricNotification {
    onDeleteWildmetricNotification {
      tablePartitionKey
      tableSortKey
      organization
      classification
      messageBody
      seen
      title
      timestamp
      ttl
      dataSource
      actionGetters
      createdAt
      updatedAt
      __typename
    }
  }
`
export const onCreateWildmetricCountriesTimezones = /* GraphQL */ `
  subscription OnCreateWildmetricCountriesTimezones {
    onCreateWildmetricCountriesTimezones {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
export const onUpdateWildmetricCountriesTimezones = /* GraphQL */ `
  subscription OnUpdateWildmetricCountriesTimezones {
    onUpdateWildmetricCountriesTimezones {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
export const onDeleteWildmetricCountriesTimezones = /* GraphQL */ `
  subscription OnDeleteWildmetricCountriesTimezones {
    onDeleteWildmetricCountriesTimezones {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
