export default function SearchFilter(filterInput) {
  const { text } = filterInput || {}
  this.text = text || ''

  this.setValue = value => {
    this.text = value || ''
  }

  this.clearValue = () => {
    this.setValue()
  }

  this.setValueByLabel = input => {
    const [currentText] = input
    this.setValue(currentText)
  }

  this.getValueParams = name => {
    if (!this.text) return null
    const value = [this.text]
    return { name, value }
  }
}
