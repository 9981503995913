<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content" class="no-header-layout">
        <!-- Begin Page Content -->
        <router-view />
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->
    </div>
    <!-- End of Content Wrapper -->
    <div class="alert-container">
      <Alert
        v-for="alert in alerts"
        :key="alert.id"
        :type="alert.type"
        :message="alert.msg"
        :id="alert.id"
      />
    </div>
  </div>
  <!-- End of Page Wrapper -->
</template>

<script>
import Alert from '../components/common/Alert.vue'

export default {
  name: 'SharedLayout',
  components: {
    Alert,
  },
  computed: {
    alerts() {
      return this.$store.getters['notifications/alerts/alerts']
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-container {
  top: 1rem;
}
</style>
