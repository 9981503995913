import { render, staticRenderFns } from "./NotAuthorized.vue?vue&type=template&id=83be3eec&"
var script = {}
import style0 from "./NotAuthorized.vue?vue&type=style&index=0&id=83be3eec&prod&lang=scss&rel=stylesheet%2Fscss&scope=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports