<template>
  <component
    :is="iconComponent"
    :color="color"
    role="img"
    class="inline-block fill-current"
    :style="{ height: `${size}px`, width: `${size}px` }"
  />
</template>

<script>
import stringUtils from '../../../helpers/stringUtils'

const icons = {}

const requireComponents = require.context(
  '../../../assets/icons',
  false,
  /[\w]+Icon\.vue$/,
)

requireComponents.keys().forEach(fileName => {
  const iconName = stringUtils.kebabCase(
    fileName.replace(/^\.\/(.+)Icon\.vue/, '$1'),
  )
  const componentConfig = requireComponents(fileName)
  icons[iconName] = componentConfig.default || componentConfig
})

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      },
    },
    size: {
      type: [Number, String],
      default: 32,
    },
    color: {
      type: [String],
      default: '#858796',
    },
  },

  computed: {
    iconComponent() {
      return icons[this.name]
    },
  },
}
</script>
