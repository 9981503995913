<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      :fill="color"
      d="M16.1,0c8.89,0,16.1,7.21,16.09,16.11,0,8.89-7.21,16.1-16.11,16.09-3.29,0-6.49-1.01-9.19-2.88-.88-.67-1.06-1.94-.38-2.82,.63-.83,1.8-1.04,2.68-.48,5.48,3.81,13,2.46,16.81-3.02,3.81-5.48,2.46-13-3.02-16.81-2.02-1.41-4.43-2.16-6.89-2.16-3.21-.01-6.28,1.26-8.54,3.53l1.94,1.94c.59,.59,.59,1.55,0,2.14-.28,.28-.66,.44-1.06,.44H1.51c-.83,0-1.51-.68-1.51-1.51H0V3.64c0-.83,.68-1.51,1.51-1.51,.4,0,.79,.16,1.07,.44l2.14,2.14C7.72,1.7,11.79,0,16.05,0h.06Zm.08,8.19c.55,0,.99,.45,.99,.99v1.11l.15,.02c.33,.05,1.31,.21,1.71,.31,.53,.14,.84,.68,.72,1.21-.13,.53-.67,.85-1.2,.72h0c-.5-.12-1.01-.21-1.52-.27-.81-.15-1.65-.1-2.43,.15-.57,.22-.81,.52-.86,.87-.14,.41,.05,.86,.44,1.06,.71,.38,1.48,.66,2.27,.82l.09,.02c.97,.21,1.9,.57,2.76,1.06,.48,.3,.86,.73,1.11,1.23,.25,.57,.32,1.2,.19,1.81-.22,1.1-.99,2.01-2.04,2.4-.44,.18-.9,.29-1.38,.34v1.04c0,.55-.44,.99-.99,.99-.55,0-.99-.44-.99-.99h0v-1.08s-.02-.03-.04,0h0c-.97-.17-1.92-.44-2.84-.82-.5-.22-.73-.81-.5-1.31h0c.22-.49,.79-.72,1.28-.5,.76,.3,1.55,.53,2.36,.67,.79,.15,1.6,.1,2.36-.16,.42-.13,.74-.47,.83-.9,.05-.21,.04-.44-.04-.64-.09-.17-.23-.32-.4-.42-.71-.38-1.48-.66-2.27-.82l-.09-.02c-.97-.21-1.9-.57-2.76-1.06-1.05-.64-1.57-1.87-1.3-3.06,.23-1.11,1.03-2.01,2.11-2.36,.42-.16,.86-.26,1.3-.31v-1.08c0-.55,.44-.99,.99-.99h0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SubscriptionsIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
