import { DateTime } from 'luxon'

const optionConfigObject = {
  groupBy: {
    Date: {
      name: 'Date',
      granularity: [
        {
          name: 'Day',
          level: 1,
        },
        {
          name: 'Week',
          level: 2,
        },
        {
          name: 'Month',
          level: 3,
        },
        {
          name: 'Quarter',
          level: 4,
        },
        {
          name: 'Year',
          level: 5,
        },
      ],
      stack: [
        {
          name: 'None',
        },
        {
          name: 'Users',
        },
        {
          name: 'Projects',
        },
        {
          name: 'Issues',
        },
        {
          name: 'Groups',
        },
      ],
    },
    Users: {
      name: 'Users',
      stack: [
        {
          name: 'None',
        },
        {
          name: 'Projects',
        },
        {
          name: 'Issues',
        },
      ],
    },
    Groups: {
      name: 'Groups',
      stack: [
        {
          name: 'None',
        },
        {
          name: 'Users',
        },
        {
          name: 'Issues',
        },
        {
          name: 'Projects',
        },
      ],
    },
    Projects: {
      name: 'Projects',
      stack: [
        {
          name: 'None',
        },
        {
          name: 'Users',
        },
        {
          name: 'Issues',
        },
        {
          name: 'Groups',
        },
      ],
    },
  },
}

export default function OptionsFilter({
  groupBy: initGroupBy,
  granularity: initGranularity,
  stack: initStack,
  optionConfig,
  dateRange,
} = {}) {
  this.groupBy = initGroupBy || 'Date'
  this.stack = initGranularity || 'None'
  this.granularity = initStack || 'Day'
  const config = optionConfig || optionConfigObject
  this.optionConfig = { ...config }

  const getDateDifferenceInMonths = () => {
    const { start, end } = dateRange
    const startDate = DateTime.fromJSDate(start)
    const endDate = DateTime.fromJSDate(end)

    return Math.round(
      Math.abs(endDate.diff(startDate, ['months']).toObject().months),
    )
  }

  const getLevel = months => {
    if (months <= 1) {
      return {
        level: 1,
        defaultGranularity: 1, // Day
      }
    }
    if (months > 1 && months < 3) {
      return {
        level: 1,
        defaultGranularity: 3, // Month
      }
    }
    if (months >= 3 && months < 12) {
      return {
        level: 2,
        defaultGranularity: 4, // Quarter
      }
    }
    if (months >= 12 && months < 24) {
      return {
        level: 3,
        defaultGranularity: 4, // Quarter
      }
    }
    return {
      level: 3,
      defaultGranularity: 5,
    }
  }

  const getCurrentGranularityOption = granularity => {
    const months = getDateDifferenceInMonths()
    const { level, defaultGranularity } = getLevel(months)

    const { groupBy } = config
    const currentValid = groupBy.Date.granularity.filter(gran => {
      const isValid = gran.level >= level
      return isValid
    })

    this.optionConfig = {
      groupBy: {
        ...groupBy,
        Date: {
          ...groupBy.Date,
          granularity: currentValid,
        },
      },
    }

    if (!granularity)
      return currentValid.find(gran => gran.level === defaultGranularity).name

    return granularity
  }

  this.setValue = ({ name, value }) => {
    const acceptable = ['all', 'groupBy', 'stack', 'granularity']
    if (!acceptable.includes(name)) return
    if (name === 'all') this.setAll(value)
    if (name === 'granularity') {
      this.granularity = getCurrentGranularityOption(value)
      return
    }
    this[name] = value || ''
    this.granularity = getCurrentGranularityOption()
  }

  this.clearValue = () => {
    this.setAll({
      groupBy: 'Date',
      stack: 'None',
      granularity: 'Day',
    })
  }

  this.setAll = ({ groupBy, granularity, stack }) => {
    this.setValue({ name: 'groupBy', value: groupBy })
    this.setValue({ name: 'stack', value: stack })
    this.setValue({ name: 'granularity', value: granularity })
  }

  this.setValueByLabel = input => {
    const [groupBy, stack, granularity] = input
    this.setAll({ groupBy, stack, granularity })
  }

  this.getValueParams = name => {
    const value = [this.groupBy, this.stack, this.granularity]
    return { name, value }
  }
}
