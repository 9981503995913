<template>
  <div
    class="modal fade"
    :id="id"
    :ref="id"
    tabindex="-1"
    :aria-labelledby="`${id}Label`"
    aria-hidden="true"
  >
    <div :class="`modal-dialog modal-dialog-centered modal-${size}`">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${id}Label`">
            <slot name="title"></slot>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  name: 'ModalLayout',
  props: {
    id: {
      type: String,
      default: 'example',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modal: null,
    }
  },
  computed: {
    isShown() {
      if (!this.modal) return false
      const { _isShown: isShown } = this.modal

      return isShown
    },
  },
  watch: {
    isShown(value) {
      if (value) return this.$emit('opened')
      return this.$emit('closed')
    },
  },
  mounted() {
    this.modal = Modal.getOrCreateInstance(this.$refs[this.id])
  },
}
</script>

<style lang="scss" scoped>
.modal-header,
.modal-footer {
  border: none;
  padding: 0;
}

.modal-content {
  padding: 1rem;
  border-radius: 5px;
}
</style>
