const accountInfo = {
  namespaced: true,
  state: () => ({
    firstName: '',
    lastName: '',
    email: '',
    sub: '',
  }),
  mutations: {
    initAccountInfo(state, value) {
      state.firstName = value.given_name
      state.lastName = value.family_name
      state.email = value.email
      state.sub = value.sub
    },
    setFirstName(state, value) {
      state.firstName = value
    },
    setLastName(state, value) {
      state.lastName = value
    },
    setEmail(state, value) {
      state.email = value
    },
  },
  actions: {
    setInitialState({ rootGetters, commit }) {
      const userData = rootGetters['profile/user']
      commit('profile/accountInfo/initAccountInfo', userData, { root: true })
    },
    setFirstName: ({ commit }, firstName) => commit('setFirstName', firstName),
    setLastName: ({ commit }, lastName) => commit('setLastName', lastName),
    setEmail: ({ commit }, email) => commit('setEmail', email),
  },
  getters: {
    userInfo: state => state,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    email: state => state.email,
    sub: state => state.sub,
  },
}

export default accountInfo
