import { DateTime } from 'luxon'
import qHelper from '../../helpers/queriesHelper'

const autoSelectMap = {
  today: {
    start: DateTime.now().startOf('day').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  '3m': {
    start: DateTime.now().startOf('day').minus({ months: 3 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  '1y': {
    start: DateTime.now().startOf('day').minus({ years: 1 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  qtd: {
    start: DateTime.now().startOf('day').startOf('quarter').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  ytd: {
    start: DateTime.now().startOf('day').startOf('year').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
}

export default function DateFilter(filterInput) {
  const { start, end, timezone } = filterInput || {}
  this.masks = {
    input: 'DD/MM/YYYY',
  }
  this.quickSelect = false
  this.start = start || DateTime.now().startOf('month').toJSDate()
  this.end = end || DateTime.now().endOf('day').toJSDate()
  this.timezone = timezone || null
  this.setValue = value => {
    if (value.quickSelect && value.quickSelect !== 'false') {
      this.setAutoSelect(value?.quickSelect)
      return
    }

    if (typeof value.start === 'string') {
      const dateRange = qHelper.getDateRange(value?.start, value?.end)
      this.start = dateRange.start ?? DateTime.now().startOf('month').toJSDate()
      this.end = dateRange.end ?? DateTime.now().endOf('day').toJSDate()
    } else {
      this.start = value?.start ?? DateTime.now().startOf('month').toJSDate()
      this.end = value?.end ?? DateTime.now().endOf('day').toJSDate()
    }

    this.quickSelect = false
    if (value?.timezone) this.timezone = value.timezone
  }

  this.setAutoSelect = autoKey => {
    // Checks if autoKey is valid auto select option
    if (!Object.prototype.hasOwnProperty.call(autoSelectMap, `${autoKey}`))
      return
    this.quickSelect = autoKey
    const { start: startDate, end: endDate } = autoSelectMap[autoKey]
    this.start = startDate
    this.end = endDate
  }

  this.clearValue = () => {
    this.setValue()
  }

  this.setValueByLabel = input => {
    const [currentStart, currentEnd, currentQuickSelect] = input
    this.setValue({
      ...qHelper.getDateRange(currentStart, currentEnd),
      quickSelect: currentQuickSelect,
    })
  }

  this.getValueParams = name => {
    const value = [
      qHelper.parseDateRange(this.start),
      qHelper.parseDateRange(this.end),
      this.quickSelect,
    ]
    if (!value.length) return null
    return { name, value }
  }
}
