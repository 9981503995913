<template>
  <div>
    <ul class="navbar-nav ms-auto">
      <li v-if="!isRestrictedRole" class="nav-item d-flex me-2">
        <a
          @click.prevent="openMeta"
          :class="{ loading: isLoading, active: isMetaShown }"
          class="metadata-button"
        ></a>
      </li>
      <li
        v-if="!isRestrictedRole"
        class="nav-item dropdown dropdown--pos-unset no-arrow"
      >
        <a
          class="nav-link dropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <BaseIcon name="bell" :size="16" color="var(--bs-gray-400)" />
        </a>
        <div
          class="
            dropdown-menu dropdown-menu-right
            shadow
            animated--grow-in
            py-2
            flex-column
            justify-content-between
            notifications-dropdown
            align-items-center
          "
          aria-labelledby="notifications"
        >
          <div class="notifications-dropdown__header w-100 px-2">
            <div class="row-1 mx-2 mb-2">
              <h5 class="align-left text-gray-800">Notifications</h5>
            </div>
            <div class="row-1 mx-2 mb-2">
              <div class="dropdown-divider"></div>
            </div>
          </div>
          <div v-if="this.$store.getters['notifications/error']" class="mx-5">
            {{ this.$store.getters['notifications/error'] }}
          </div>
          <div v-else-if="Object.keys(notifications).length === 0" class="mx-5">
            <p>No notifications were found.</p>
          </div>
          <div
            v-else
            class="
              notifications-dropdown__content
              overflow-auto
              flex-grow-1
              w-100
            "
          >
            <SingleNotification
              v-for="data in notifications.slice(0, 5)"
              :key="Math.random().toString() + data.tableSortKey"
              :data="data"
            />
          </div>
          <div class="notifications-dropdown__footer w-100 px-2">
            <div class="row-1 mx-2 mb-2">
              <div class="dropdown-divider"></div>
            </div>
            <a class="row-1 mx-2 mb-2 pb-2">
              <router-link to="/notifications" class="nav-item text-start">
                <strong>View all notifications</strong>
              </router-link>
            </a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown dropdown--pos-unset no-arrow">
        <a
          class="nav-link dropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <BaseIcon name="appdrawer" :size="16" color="var(--bs-gray-400)" />
        </a>
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="appDrawer"
        >
          <div class="app-drawer">
            <AppLink
              class="app-drawer__app"
              v-for="app in filteredAppDrawer"
              :key="app.name"
              :app="app"
            ></AppLink>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown no-arrow">
        <a
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-test="userDropdown"
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          aria-haspopup="true"
        >
          <ul class="list-unstyled ms-2">
            <li class="text-gray-600 small">{{ userFullName }}</li>
            <li v-if="current" class="text-gray-600 small">
              {{ current.displayName }}
            </li>
          </ul>
        </a>

        <!-- Dropdown - User Information -->
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          <div v-if="organizations" class="organization">
            <small class="ps-2">Current organization:</small>

            <router-link
              v-for="org in organizations"
              :key="org.displayName"
              :to="`/organization/${org.tablePartitionKey?.split('#')[1]}`"
              custom
              v-slot="{ navigate }"
              class="dropdown-item"
              @click.native="changeCurrentOrganization(org)"
            >
              <button
                :disabled="org.status === 'disabled'"
                @click="navigate"
                role="link"
                :class="
                  org.displayName === current.displayName
                    ? 'fw-bold'
                    : 'fw-normal'
                "
              >
                {{ org.displayName }}
              </button>
            </router-link>
          </div>

          <div v-if="organizations" class="dropdown-divider" />

          <router-link
            data-test="profile"
            to="/profile"
            class="dropdown-item d-flex align-items-center"
          >
            <BaseIcon
              name="user"
              :size="14"
              class="me-2"
              color="var(--bs-gray-400)"
            />
            <span>Profile</span>
          </router-link>

          <div class="dropdown-divider" />

          <button
            data-test="logout"
            class="dropdown-item d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#logoutModal"
          >
            <BaseIcon
              name="signout"
              :size="14"
              class="me-2"
              color="var(--bs-gray-400)"
            />
            <span>Logout</span>
          </button>
        </div>
      </li>
    </ul>
    <MetadataComponent
      @endLoad="metaLoader = false"
      @startLoad="metaLoader = true"
      @close="isMetaShown = false"
      v-if="isMetaShown"
    />
  </div>
</template>

<script>
import SingleNotification from '../../components/mixins/SingleNotification.vue'
import AppLink from './AppLink.vue'
import MetadataComponent from '../../components/reports/filters/Metadata.vue'
import BaseIcon from '../../components/common/icons/BaseIcon.vue'

export default {
  components: {
    SingleNotification,
    AppLink,
    MetadataComponent,
    BaseIcon,
  },
  data() {
    return {
      integrationStatus: this.$store.getters.notification,
      isMetaShown: false,
      metaLoader: false,
      appDrawer: [
        {
          name: 'Apps',
          route: '/integrations',
          icon: 'apps',
        },
        {
          name: 'Subscriptions',
          route: '/subscriptions',
          icon: 'subscriptions',
        },
        {
          name: 'Filters',
          route: '/filters',
          icon: 'filters',
        },
        {
          name: 'Help',
          click: () => {},
          icon: 'help',
        },
        {
          name: 'Organizations',
          route: '/organizations', // TODO make organizations view
          icon: 'organizations',
        },
      ],
    }
  },
  computed: {
    isRestrictedRole() {
      const role = this.$store.getters['organization/current'].organizationRole
      return role === 'READER' || role === 'VIEWER'
    },
    filteredAppDrawer() {
      const organizationRole =
        this.$store.getters['organization/current']?.organizationRole
      return this.appDrawer.filter(item => {
        const isRestrictedItem = [
          'Apps',
          'Subscriptions',
          'Organizations',
        ].includes(item.name)
        const restrictedAccess =
          organizationRole === 'READER' || organizationRole === 'VIEWER'
        if (isRestrictedItem && restrictedAccess) {
          return false
        }
        return true
      })
    },
    isLoading() {
      return (
        this.metaLoader ||
        this.$store.getters['reports/filters/membersOptions'].length < 1
      )
    },
    userFullName() {
      return `${this.$store.getters['profile/user'].given_name} ${
        this.$store.getters['profile/user'].family_name
          ? this.$store.getters['profile/user'].family_name
          : 'Unknown'
      }`
    },
    userEmail() {
      return this.$store.getters['profile/user'].email
    },
    organizations() {
      return this.$store.getters['organization/joined']
    },
    current() {
      return this.$store.getters['organization/current']
    },
    defaultOrg() {
      return this.$store.getters['profile/user']['custom:default_org']
    },
    notifications() {
      const allNotifications = [
        ...this.$store.getters['notifications/actionableNotifications'],
        ...this.$store.getters[
          'notifications/nonActionableNotifications'
        ].filter(notification =>
          this.$store.getters[
            'notifications/shownNotifications'
          ].types.includes(notification.classification),
        ),
      ]
      return allNotifications
    },
  },
  methods: {
    openMeta() {
      if (this.$store.getters['reports/filters/membersOptions'].length > 0)
        this.isMetaShown = !this.isMetaShown
    },
    changeCurrentOrganization(selectedOrganzation) {
      this.$store
        .dispatch('organization/changeCurrentOrganization', selectedOrganzation)
        .then(() => {
          const organizationRole =
            this.$store.getters['organization/current']?.organizationRole
          const restrictedAccess =
            organizationRole === 'READER' || organizationRole === 'VIEWER'
          if (restrictedAccess) {
            this.$router.push(`/${this.defaultOrg}/reports`)
            return
          }
          // TODO refactor
          document
            .querySelectorAll('[role="tab"]')
            .forEach(function clearActive(el) {
              el.classList.remove('active')
            })
          document
            .querySelectorAll('[role="tab-pane"]')
            .forEach(function clearActive(el) {
              el.classList.remove('active', 'show')
            })

          const tabid = document.getElementById('people-tab')
          const paneid = document.getElementById('people')
          if (typeof tabid !== 'undefined' && tabid !== null) {
            tabid.classList.add('active')
            paneid.classList.add('active', 'show')
          }
        })
    },
  },
  beforeDestroy() {
    this.$store.dispatch('notifications/clearNotifications')
  },
}
</script>

<style lang="scss" scoped>
.app-drawer {
  display: grid;
  grid-template-columns: repeat(3, 6rem);
  gap: 1rem;
  padding: 1rem;
}
.dropdown--pos-unset {
  position: unset !important;
  .dropdown-menu {
    right: 1rem !important;
    padding: 0;
  }
}
.dropdown-menu[data-bs-popper] {
  left: auto;
}
.notifications-dropdown {
  height: 25rem;
  width: 25rem !important;
  &.show {
    display: flex;
  }
}

.notifications-dropdown__content::-webkit-scrollbar {
  display: none;
}

.metadata-button {
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  padding: 2px;
  border: 2px solid darkgray;
  border-radius: 0.6rem;
  color: darkgray;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  align-self: center;
  &:hover {
    border-color: darkslategray;
    color: darkslategray;
  }
  &::after {
    content: '!';
    position: absolute;
    bottom: -3px;
    left: 0.35rem;
  }
  &.active {
    border-color: #467575;
    color: #467575;
  }
  &.loading {
    border-bottom: none;
    border-right: none;
    animation: rotate 1s infinite;
    &::after {
      content: '' !important;
      position: absolute;
      bottom: -3px;
      left: 0.35rem;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
