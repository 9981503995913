import { API } from 'aws-amplify'
import { recordsByTablePartitionKey } from '../../graphql/queries'

const subscriptions = {
  namespaced: true,
  state: {
    plans: null,
  },
  mutations: {
    setPlans(state, value) {
      state.plans = value
    },
  },
  actions: {
    getPlans({ commit }) {
      return API.graphql({
        query: recordsByTablePartitionKey,
        variables: {
          tablePartitionKey: `stripePlan`,
          tableSortKey: {
            beginsWith: `planTier#`,
          },
        },
      }).then(res => {
        const plans = {}
        res.data.recordsByTablePartitionKey.items.forEach(plan => {
          const { planLimits, displayName, description, planPrice } = plan
          const priceString = planPrice === 0 ? 'Free' : `$${planPrice / 100}`

          plans[displayName] = {
            limits: JSON.parse(planLimits),
            tier: displayName,
            description,
            price: planPrice,
            priceString,
          }
        })
        commit('setPlans', plans)
      })
    },
  },
  getters: {
    plans: state => state.plans,
  },
}

export default subscriptions
