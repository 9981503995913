export default function TagsFilter(filterInput) {
  const { and, or } = filterInput || {}
  this.and = and || []
  this.or = or || []

  this.setValue = value => {
    this.and = value?.and ?? []
    this.or = value?.or ?? []
  }

  this.clearValue = () => {
    this.setValue()
  }

  this.setValueByLabel = input => {
    const strToArr = str => {
      const strArr = str.length > 0 ? str.split(',') : []
      return strArr.map(tag => {
        const [type, name] = tag.split('.')
        return { type, name }
      })
    }
    const currentAnd = strToArr(input[0])
    const currentOr = strToArr(input[1])

    this.setValue({ and: currentAnd, or: currentOr })
  }

  this.getValueParams = name => {
    if (!this.and.length && !this.or.length) return null
    const value = [this.and, this.or]
    if (!value.length) return null
    return { name, value }
  }
}
