import { API, graphqlOperation } from 'aws-amplify'
import { linkAccounts, unlinkAccounts } from '../../graphql/mutations'
import { onUpdateUserData } from '../../graphql/subscriptions'

export default {
  namespaced: true,
  state: {
    subscriptionStatus: null,
  },
  getters: {
    returnMessage: state => state.returnMessage,
  },
  actions: {
    async linkAccounts({ rootGetters, dispatch, state }, secondaryAccountSub) {
      const { secondaryAccounts, primaryAccount } = rootGetters[
        `organization/org#${
          rootGetters['organization/current'].tablePartitionKey.split('#')[1]
        }/users`
      ].filter(user => user.sub === rootGetters[`profile/user`].sub)[0]

      if (
        secondaryAccounts !== null &&
        secondaryAccounts.includes(secondaryAccountSub)
      ) {
        dispatch(
          'notifications/alerts/appendAlert',
          {
            type: 'error',
            msg: 'You are already linked with this account!',
          },
          { root: true },
        )
        return
      }
      if (primaryAccount !== null) {
        dispatch(
          'notifications/alerts/appendAlert',
          {
            type: 'error',
            msg: 'Your account is already a secondary to someone.',
          },
          { root: true },
        )
        return
      }

      API.graphql(
        graphqlOperation(linkAccounts, {
          input: {
            linkedAccountSub: secondaryAccountSub,
            organizationID:
              rootGetters['organization/current'].tablePartitionKey.split(
                '#',
              )[1],
            userDetails: JSON.stringify(rootGetters[`profile/user`]),
          },
        }),
      ).then(() => {
        dispatch(
          'notifications/alerts/appendAlert',
          {
            type: 'success',
            msg: 'A link request has been sent to the user.',
          },
          { root: true },
        )
        if (state.subscriptionStatus === null) {
          state.subscriptionStatus = API.graphql({
            query: onUpdateUserData,
            variables: {
              tablePartitionKey:
                rootGetters['organization/current'].tablePartitionKey,
              tableSortKey: `userSub#${rootGetters[`profile/user`].sub}`,
            },
          }).subscribe({
            next: () => {
              // Refresh the users in the organization view.
              const org =
                rootGetters['organization/current'].tablePartitionKey.split(
                  '#',
                )[1]
              dispatch(`organization/org#${org}/getOrganizationPeople`, org, {
                root: true,
              })
            },
          })
        }
      })
    },
    async unlinkAccount({ rootGetters, dispatch }, secondaryAccountSub) {
      API.graphql(
        graphqlOperation(unlinkAccounts, {
          input: {
            linkedAccountSub: secondaryAccountSub,
            organizationID:
              rootGetters['organization/current'].tablePartitionKey.split(
                '#',
              )[1],
            userDetails: JSON.stringify(rootGetters[`profile/user`]),
          },
        }),
      ).then(() => {
        const org =
          rootGetters['organization/current'].tablePartitionKey.split('#')[1]
        dispatch(`organization/org#${org}/getOrganizationPeople`, org, {
          root: true,
        })

        dispatch(
          'notifications/alerts/appendAlert',
          {
            type: 'success',
            msg: 'You have successfully unlinked your account.',
          },
          { root: true },
        )
      })
    },
  },
}
