<template>
  <ModalLayout id="AddAtlassianIdModal">
    <template v-slot:title>Add Manual AtlassianId</template>
    <template v-slot:body>
      <div class="form-group">
        <label class="mb-2">Atlassian ID</label>
        <input
          v-model="newAtlassianID"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.newAtlassianID.$invalid }"
          placeholder="Atlassian ID"
          required
        />
        <small v-if="v$.newAtlassianID.$invalid" class="text-danger mt-2 ms-2">
          This field is required!
        </small>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-custom" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        data-test="submit-reset-pass"
        class="btn btn-primary"
        type="submit"
        :disabled="v$.$invalid"
        @click="addAtlassianId"
      >
        Add
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Modal } from 'bootstrap'
import { API, graphqlOperation } from 'aws-amplify'
import { updateWildmetricCollectionRecords } from '../../../graphql/mutations'
import ModalLayout from './ModalLayout.vue'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    ModalLayout,
  },
  validations: {
    newAtlassianID: {
      required,
    },
  },
  data() {
    return {
      newAtlassianID: '',
    }
  },
  methods: {
    async addAtlassianId() {
      const { userSub } = this.$route.params
      const { orgId } = this.$route.params
      await API.graphql(
        graphqlOperation(updateWildmetricCollectionRecords, {
          input: {
            tablePartitionKey: `organization#${orgId}`,
            tableSortKey: `userSub#${userSub}`,
            atlassianId: this.newAtlassianID,
            manualAtlassianId: true,
            organization: this.$store.getters[
              'organization/current'
            ].tablePartitionKey
              .split('#')
              .pop(''),
          },
        }),
      ).then(() => {
        const AddAtlassianIdModal = Modal.getOrCreateInstance(
          document.getElementById('AddAtlassianIdModal'),
        )
        this.$store.dispatch(
          `organization/org#${orgId}/getOrganizationPeople`,
          orgId,
        )
        this.newAtlassianID = ''
        AddAtlassianIdModal.hide()
      })
    },
  },
}
</script>
