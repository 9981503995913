/* eslint-disable func-names */
function getWeekDate(timestamp) {
  const date = new Date(timestamp)
  const oneJan = new Date(date.getFullYear(), 0, 1)
  const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000))
  const week = Math.ceil((date.getDay() + 1 + numberOfDays) / 7)
  return `W${week}`
}

function getQuarterDate(timestamp) {
  const date = new Date(timestamp)
  const quarter = Math.floor(date.getMonth() / 3) + 1
  return `Q${quarter}`
}

function getFromattedTimeSpent(timespent) {
  const seconds = Number(timespent)

  const h = Math.floor(seconds / 3600)
  const hours = h <= 0 ? '' : `${h}h `

  const m = Math.floor((seconds % 3600) / 60)
  const minutes = m <= 0 ? '' : `${m}m`

  return `${hours}${minutes}`
}

function getTableObject(worklog, owner, groups, date) {
  const ownerGroups = groups
    .filter(
      group =>
        group.initiatorGroupMembers &&
        group.initiatorGroupMembers.includes(owner.email),
    )
    .map(group => group.initiatorGroupName)
  const fullDetails = worklog.details || ' '
  const trimEllip = (str, max) =>
    str.length > max ? `${str.substring(0, max)}...` : str
  const Details = trimEllip(fullDetails, 50)

  return {
    Timestamp: date || ' ',
    Integration: worklog.dataSource || ' ',
    Project: worklog.project || ' ',
    Issue: JSON.parse(worklog.workchunk)?.ticket || ' ',
    Details,
    Seconds: worklog.timespent || ' ',
    Minutes: Math.floor(worklog.timespent / 60) || ' ',
    Total: getFromattedTimeSpent(worklog.timespent) || ' ',
    User: owner.name || ' ',
    Groups: ownerGroups || ' ',
  }
}

function getTableObjectReader(worklog, owner, groups, date) {
  const ownerGroups = groups
    .filter(
      group =>
        group.initiatorGroupMembers &&
        group.initiatorGroupMembers.includes(owner.email),
    )
    .map(group => group.initiatorGroupName)
  const fullDetails = worklog.details || ' '
  const trimEllip = (str, max) =>
    str.length > max ? `${str.substring(0, max)}...` : str
  const Details = trimEllip(fullDetails, 50)

  return {
    Timestamp: date || ' ',
    Integration: worklog.dataSource || ' ',
    Project: worklog.project || ' ',
    Issue: JSON.parse(worklog.workchunk)?.ticket || ' ',
    Details,
    User: owner.name || ' ',
    Groups: ownerGroups || ' ',
  }
}

function getOptions(granularity, groupby) {
  const options = {}
  options.xLabelFormat = '{value}'
  if (groupby === 'Date')
    switch (granularity) {
      case 'Day':
        options.xLabelFormat = '{value:%d %b}'
        break
      case 'Week':
        options.xLabelFormat = "{value:%w'%b}"
        break
      case 'Month':
        options.xLabelFormat = '{value:%b}'
        break
      case 'Quarter':
        options.xLabelFormat = "{value:%q'%y}"
        break
      case 'Year':
        options.xLabelFormat = '{value:%Y}'
        break
      default:
        options.xLabelFormat = '{value}'
        break
    }
  return options
}

function getFormattedDate(
  granularity,
  groupby,
  point,
  timezoneOffset,
  tooltip = false,
) {
  const date = new Date(point.x - timezoneOffset * 60000)
  let formattedDate = point.key

  if (point.key.includes?.('#')) {
    const [xAxis, key] = point.key.split('#')
    formattedDate = tooltip ? key : xAxis
  }

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  if (groupby === 'Date')
    switch (granularity) {
      case 'Day':
        formattedDate = `${date.getDate()} ${
          months[date.getMonth()]
        } ${date.getFullYear()}`
        break
      case 'Week':
        formattedDate = `${getWeekDate(date)}'${months[date.getMonth()]}`
        break
      case 'Month':
        formattedDate = `${months[date.getMonth()]} ${date.getFullYear()}`
        break
      case 'Quarter':
        formattedDate = `${getQuarterDate(date)}'${date.getFullYear()}`
        break
      case 'Year':
        formattedDate = date.getFullYear()
        break
      default:
        formattedDate = point.key
        break
    }

  return formattedDate
}

function getTooltip(granularity, groupby, stack, point, timezoneOffset) {
  const title = getFormattedDate(
    granularity,
    groupby,
    point,
    timezoneOffset,
    true,
  )
  const seriesName = stack !== 'None' ? ` | ${point.series.name}` : ''

  let shownTime = getFromattedTimeSpent(point.y)

  if (point.reader) shownTime = point.y

  return `<table><tr><th colspan="2">${title}${seriesName}</th></tr>
              
              <tr><td style="color: ${point.color}">Time </td>
              <td style="text-align: right"><b>${shownTime}</b></td></tr>
              </table>`
}

function downloadExport(name, format, content) {
  const pom = document.createElement('a')
  const csvContent = content // here we load our csv data
  const blob = new Blob(['\ufeff', csvContent], { type: `${format}` })
  const url = URL.createObjectURL(blob)
  pom.href = url
  pom.setAttribute('download', `${name}.csv`)
  pom.click()
}

function objectToCSV(data) {
  const csv = [
    [...Object.keys(data[0])],
    ...data.map(item => {
      return Object.values(item).map(cel => {
        if (typeof cel === 'string')
          return cel.replaceAll(',', '.').replace(/\r?\n|\r/g, '')
        if (Array.isArray(cel)) return cel.join('; ')
        return cel
      })
    }),
  ]
    .map(e => e.join(',').replace(/\n/g, ''))
    .join('\n')
  return csv
}

async function waitForExportDataInProgress(
  store,
  maxWaitTime = 10000,
  checkInterval = 100,
  waitedTime = 0,
) {
  if (!store.getters['reports/exportDataInprogress']) {
    return
  }
  if (waitedTime >= maxWaitTime) {
    throw new Error('Data export timeout exceeded')
  }
  await new Promise(resolve => setTimeout(resolve, checkInterval))
  await waitForExportDataInProgress(
    store,
    maxWaitTime,
    checkInterval,
    waitedTime + checkInterval,
  )
}

// Public functions
export default {
  setupChart(groupby, granularity, stack) {
    const xType = groupby === 'Date' ? 'datetime' : 'category'
    const { xLabelFormat } = getOptions(granularity, groupby)
    return {
      xType,
      xLabelFormat,
      setupTooltip(point, timezoneOffset) {
        return getTooltip(granularity, groupby, stack, point, timezoneOffset)
      },
      setupLabel(point, timezoneOffset) {
        return getFormattedDate(granularity, groupby, point, timezoneOffset)
      },
    }
  },
  setupQuarter(timestamp) {
    return getQuarterDate(timestamp)
  },
  setupWeek(timestamp) {
    return getWeekDate(timestamp)
  },
  getFromattedTimeSpent(timespent) {
    return getFromattedTimeSpent(timespent)
  },
  getTableObject(worklog, owner, groups, date) {
    return getTableObject(worklog, owner, groups, date)
  },
  getTableObjectReader(worklog, owner, groups, date) {
    return getTableObjectReader(worklog, owner, groups, date)
  },
  waitForExportDataInProgress,
  exportCSV: H => {
    const { pick } = H
    // eslint-disable-next-line func-names
    H.wrap(H.Chart.prototype, 'getCSV', function (p, useLocalDecimalPoint) {
      let csv = ''
      const rows = this.getDataRows()
      const csvOptions = this.options.exporting.csv
      const decimalPoint = pick(
        csvOptions.decimalPoint,
        csvOptions.itemDelimiter !== ',' && useLocalDecimalPoint
          ? (1.1).toLocaleString()[1]
          : '.',
      )
      // use ';' for direct to Excel
      const itemDelimiter = pick(
        csvOptions.itemDelimiter,
        decimalPoint === ',' ? ';' : ',',
      )
      // '\n' isn't working with the js csv data extraction
      const { lineDelimiter } = csvOptions
      // Transform the rows to CSV
      rows.forEach((row, i) => {
        if (!row[1]) return

        // Add the values
        csv += row
          .map((cell, y) => {
            if (!y) {
              if (!i) return csvOptions.label
              return csvOptions.isDateTime ? `${cell.split(' ')[0]}` : `${cell}`
            }

            if (typeof cell !== 'number') {
              return `${cell}`
            }
            if (typeof val === 'number') {
              if (decimalPoint !== '.') {
                return cell.toString().replace('.', decimalPoint)
              }
            }

            return getFromattedTimeSpent(cell)
          })
          .join(itemDelimiter)
        // Add the line delimiter
        if (i < rows.length - 1) {
          csv += lineDelimiter
        }
      })

      return csv
    })
    H.wrap(H.Chart.prototype, 'downloadCSV', function (downloadCSV, data) {
      if (!data) {
        downloadExport(
          `${this.getFilename()}`,
          'text/csv;charset=utf-8;',
          this.getCSV(),
        )
        return
      }

      downloadExport(
        `${this.getFilename()}-raw`,
        'text/csv;charset=utf-8;',
        objectToCSV(data),
      )
    })
  },
  // Get the name of a worklogOwner. It accepts userSub (worklogOwner is a usersub), but returns the display name.
  // The function evaluates if the user has a primary account and returns the primary account name instead.
  getWorklogOwnerName(worklogOwner, memberOptions, link) {
    const owner = memberOptions.filter(user => user.sub === worklogOwner)
    let ownerName = ''
    // Check if the worklogOwner has entry in the membersOptions
    if (owner.length > 0) {
      ownerName = `${owner[0].name}#${owner[0].email}`
      // Check if the worklogOwner has primary account
      if (
        owner[0].primaryAccount !== null &&
        owner[0].primaryAccount !== undefined &&
        link
      ) {
        const { primaryAccount } = memberOptions.filter(
          user => user.sub === worklogOwner,
        )[0]
        if (
          memberOptions.filter(user => user.sub === primaryAccount).length > 0
        ) {
          // worklogOwner's primaryAccount found, use its name
          const linkOwner = memberOptions.filter(
            user => user.sub === primaryAccount,
          )
          ownerName = `${linkOwner[0].name}#${linkOwner[0].email}`
        }
      }
    } else {
      ownerName = worklogOwner
    }
    return ownerName
  },
  getWorklogOwnerSub(worklogOwner, memberOptions) {
    let ownerName = ''
    // Check if the worklogOwner has entry in the membersOptions
    if (memberOptions.filter(user => user.sub === worklogOwner).length > 0) {
      // Check if the worklogOwner has primary account
      if (
        memberOptions.filter(user => user.sub === worklogOwner)[0]
          .primaryAccount !== null &&
        memberOptions.filter(user => user.sub === worklogOwner)[0]
          .primaryAccount !== undefined
      ) {
        const { primaryAccount } = memberOptions.filter(
          user => user.sub === worklogOwner,
        )[0]
        if (
          memberOptions.filter(user => user.sub === primaryAccount).length > 0
        ) {
          // worklogOwner's primaryAccount found, use its name
          ownerName = memberOptions.filter(
            user => user.sub === primaryAccount,
          )[0].sub
        } else {
          // worklogOwner's primaryAccount not found, use worklogOwner name instead
          ownerName = memberOptions.filter(user => user.sub === worklogOwner)[0]
            .sub
        }
      } else {
        // worklogOwner has no primaryAccount, use his Name instead
        ownerName = memberOptions.filter(user => user.sub === worklogOwner)[0]
          .sub
      }
    } else {
      // worklog owner not found in the members list or this is not a user sub
      ownerName = worklogOwner
    }
    return ownerName
  },
  getWorklogOwnerEmail(worklogOwner, memberOptions) {
    let ownerEmail = ''
    // Check if the worklogOwner has entry in the membersOptions
    if (memberOptions.filter(user => user.sub === worklogOwner).length > 0) {
      // Check if the worklogOwner has primary account
      if (
        memberOptions.filter(user => user.sub === worklogOwner)[0]
          .primaryAccount !== null &&
        memberOptions.filter(user => user.sub === worklogOwner)[0]
          .primaryAccount !== undefined
      ) {
        const { primaryAccount } = memberOptions.filter(
          user => user.sub === worklogOwner,
        )[0]
        if (
          memberOptions.filter(user => user.sub === primaryAccount).length > 0
        ) {
          // worklogOwner's primaryAccount found, use its name
          ownerEmail = memberOptions.filter(
            user => user.sub === primaryAccount,
          )[0].email
        } else {
          // worklogOwner's primaryAccount not found, use worklogOwner name instead
          ownerEmail = memberOptions.filter(
            user => user.sub === worklogOwner,
          )[0].email
        }
      } else {
        // worklogOwner has no primaryAccount, use his email instead
        ownerEmail = memberOptions.filter(user => user.sub === worklogOwner)[0]
          .email
      }
    } else {
      ownerEmail = ''
    }
    return ownerEmail
  },
  getWorklogAssigneeEmail(worklogOwner, memberOptions) {
    let ownerEmail = ''
    if (memberOptions.filter(user => user.sub === worklogOwner).length > 0) {
      ownerEmail = memberOptions.filter(user => user.sub === worklogOwner)[0]
        .email
    } else {
      ownerEmail = ''
    }
    return ownerEmail
  },
  /*
  This function checks if bucket.key can be parsed into a primaryAccount userSub
  and if not it just continues.
  Also, if there is more than one bucket with the same bucket.key, after parsing the
  primaryAccount, it aggregates the time and timespent value for the same bucket.key
  buckets into a single bucket.
  */
  mergeSecondaryAccountWorklog(memberOptions, buckets) {
    const onlyPrimaryAccountBuckets = []
    buckets.forEach(bucket => {
      const userData = { ...bucket }
      userData.key = this.getWorklogOwnerSub(bucket.key, memberOptions)
      onlyPrimaryAccountBuckets.push(userData)
    })
    const onlyPrimaryAccountWithAggregatedTime = []
    onlyPrimaryAccountBuckets.forEach(bucket => {
      // Find the buckets that have a same .key more than once
      const sameKeysBuckets = onlyPrimaryAccountBuckets.filter(
        duplicateBucket => duplicateBucket.key === bucket.key,
      )
      if (sameKeysBuckets.length === 1) {
        // Push buckets that occur only once
        onlyPrimaryAccountWithAggregatedTime.push(sameKeysBuckets[0])
      } else if (sameKeysBuckets.length > 1) {
        // Aggregate and push the buckets that have the same key, if they are not already pushed
        if (
          onlyPrimaryAccountWithAggregatedTime.filter(
            sameBucket => sameBucket.key === sameKeysBuckets[0].key,
          ).length === 0
        ) {
          // The same aggregated items are not pushed yet.
          const aggregatedBucket = {}
          let timespent = { value: 0 }
          let time = {}
          let docCount = 0
          if (sameKeysBuckets[0].timespent) {
            time = undefined
            timespent.value = 0
          } else {
            timespent = undefined
            time.buckets = []
          }
          aggregatedBucket.key = sameKeysBuckets[0].key
          sameKeysBuckets.forEach(sameBucket => {
            docCount += sameBucket.doc_count
            if (timespent) {
              timespent.value += sameBucket.timespent.value
            } else {
              sameBucket.time.buckets.forEach(sameTimeBucket => {
                if (
                  time.buckets.filter(
                    timeBucket => timeBucket.key === sameTimeBucket.key,
                  ).length === 0
                ) {
                  // the bucket is not pushed yet
                  time.buckets.push(sameTimeBucket)
                } else {
                  // the bucket is pushed, aggregate the data
                  const tempBucket = time.buckets.filter(
                    timeBucket => timeBucket.key === sameTimeBucket.key,
                  )
                  // remove the bucket, then aggregate it and push it again
                  time.buckets = time.buckets.filter(
                    removeBucket => removeBucket.key !== sameTimeBucket.key,
                  )
                  tempBucket[0].doc_count += sameTimeBucket.doc_count
                  tempBucket[0].timespent.value +=
                    sameTimeBucket.timespent.value
                  time.buckets.push(tempBucket[0])
                }
              })
            }
          })
          aggregatedBucket.doc_count = docCount
          if (timespent) {
            aggregatedBucket.timespent = {}
            aggregatedBucket.timespent.value = timespent.value
          } else {
            time.buckets = time.buckets.sort((a, b) => a.key - b.key)
            aggregatedBucket.time = time
          }
          onlyPrimaryAccountWithAggregatedTime.push(aggregatedBucket)
        }
      }
    })
    return onlyPrimaryAccountWithAggregatedTime
  },
  getYearDates(date) {
    const year = date.getFullYear()
    const firstDay = new Date(year, 0, 1)

    const lastDay = new Date(year, 11, 31)
    return {
      start: firstDay,
      end: lastDay,
    }
  },
  getQuarterDates(date) {
    const quarter = Math.floor((date.getMonth() + 3) / 3)
    const firstDay = date
    let lastDay
    if (quarter === 4) {
      lastDay = new Date(date.getFullYear() + 1, 1, 1)
    } else {
      lastDay = new Date(date.getFullYear(), quarter * 3, 1)
    }
    lastDay.setDate(lastDay.getDate() - 1)
    return {
      start: firstDay,
      end: lastDay,
    }
  },
  getMonthDates(date) {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return {
      start: firstDay,
      end: lastDay,
    }
  },
  isWorkDay(value, workDays, holidays) {
    const daysArray = []

    const holiDates = holidays
      .map(holi => new Date(holi.date).setHours(0, 0, 0, 0))
      .filter(holi => Number.isNaN(holi) === false)

    const dayIndexes = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 0,
    }

    Object.keys(workDays).forEach(key => {
      if (workDays[key].active) {
        daysArray.push(dayIndexes[key])
      }
    })

    const date = new Date(value)
    const compareDate = date.setHours(0, 0, 0, 0)
    const day = date.getDay()
    return daysArray.includes(day) && !holiDates.includes(compareDate)
  },
  getWorkDaysNumber(startDate, endDate, workDays, holidays) {
    const start = new Date(startDate)
    const end = new Date(endDate)

    let count = 0
    while (start <= end) {
      if (this.isWorkDay(start, workDays, holidays)) {
        count += 1
      }
      start.setDate(start.getDate() + 1)
    }
    return count
  },
  getWorkdaysInRange(startDate, endDate, workDays, holidays, range) {
    let workdaysInWeek = 0
    const currentDate = new Date(startDate)

    while (currentDate <= endDate) {
      // Check if the current day is a workday (Monday to Friday)
      if (this.isWorkDay(currentDate, workDays, holidays)) {
        const dateRangeStart = new Date(range.start)
        const dateRangeEnd = new Date(range.end)
        if (currentDate >= dateRangeStart && currentDate <= dateRangeEnd) {
          // Increment the count of workdays for the current week
          workdaysInWeek += 1
        }
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return workdaysInWeek
  },
  getQuarterEndDate(date) {
    const quarter = Math.floor(date.getMonth() / 3)
    const lastMonthOfQuarter = (quarter + 1) * 3
    const endDate = new Date(date.getFullYear(), lastMonthOfQuarter, 0)
    return endDate
  },
}
