<template>
  <router-link
    class="app-link"
    :is="app.route ? 'router-link' : 'span'"
    :to="app.route"
    @click="interact()"
  >
    <BaseIcon class="app-link__icon" :name="app.icon" size="24"></BaseIcon>
    <span class="app-link__name">{{ app.name }}</span>
  </router-link>
</template>

<script>
import BaseIcon from '../../components/common/icons/BaseIcon.vue'

export default {
  components: {
    BaseIcon,
  },
  props: {
    app: Object,
  },
  methods: {
    interact() {
      if (this.app.click) this.app.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--bs-gray-600);
  padding: 1rem;
  &:hover {
    text-decoration: none;
    color: var(--bs-gray-700);
    background-color: var(--bs-gray-200);
    border-radius: 0.3rem;
    cursor: pointer;
  }
  &__icon {
    font-size: 1.4rem;
    margin: 0.5rem;
  }
}
</style>
