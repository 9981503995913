<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      :fill="color"
      d="M32,6.79c.02,3.77-3.01,6.84-6.78,6.86-.03,0-.05,0-.08,0-1.77,.03-3.48-.64-4.76-1.86l-6.72,3.36c.01,.28,0,.57-.01,.85,.02,.28,.03,.57,.01,.85l6.72,3.36c2.73-2.63,7.07-2.54,9.69,.18s2.54,7.07-.18,9.69c-2.73,2.63-7.07,2.54-9.69-.18-1.23-1.28-1.92-2.98-1.92-4.76,0-.28,.01-.57,.05-.85l-6.72-3.36c-2.73,2.63-7.07,2.55-9.7-.18-2.63-2.73-2.55-7.07,.18-9.7,2.66-2.56,6.86-2.56,9.52,0l6.72-3.36c-.03-.28-.05-.57-.05-.85,0-3.79,3.07-6.86,6.86-6.86s6.86,3.07,6.86,6.86h0v-.07ZM6.8,18.22c1.22,.05,2.24-.91,2.29-2.12,0-.05,0-.11,0-.16,0-1.26-1.02-2.28-2.28-2.28h0c-1.24,.04-2.24,1.04-2.29,2.29,0,1.26,1.02,2.28,2.28,2.28h0ZM25.15,4.51c-1.25,.02-2.27,1.03-2.29,2.29-.03,1.26,.98,2.31,2.24,2.33s2.31-.98,2.33-2.24c0-.03,0-.06,0-.1-.02-1.25-1.03-2.26-2.28-2.29Zm0,22.92c1.26,0,2.29-1.02,2.29-2.29s-1.02-2.29-2.29-2.29-2.29,1.02-2.29,2.29h0c0,1.26,1.02,2.28,2.28,2.29h0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SharenodesIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
