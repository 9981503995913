import { API, graphqlOperation } from 'aws-amplify'
import {
  recordsBySortKey,
  recordsByTablePartitionKey,
  getMainOrganizationEntry,
} from '../../graphql/queries'
import {
  updateWildmetricCollectionRecords,
  shareFilterWithReader,
  removeMember,
  deleteOrganization,
} from '../../graphql/mutations'
import tags from './tags'

export default {
  namespaced: true,
  modules: {
    tags,
  },
  state: {
    current: false,
    joined: {}, // This JSON objects holds the organizations with organization.displayName as a key and org data as value
  },
  actions: {
    async joined({ commit, state, rootGetters, dispatch }) {
      if (state.joined.length > 0) {
        return
      }
      await API.graphql({
        query: recordsBySortKey,
        variables: {
          tableSortKey: `userSub#${rootGetters['profile/user'].sub}`,
          tablePartitionKey: {
            beginsWith: 'organization#',
          },
        },
      })
        .then(async joinedOrgs => {
          const orgs = joinedOrgs.data.recordsBySortKey.items
          // Pull the original data for each joined organization from the owner record
          const promises = orgs.map(organization => {
            return dispatch('getMainOrganizationEntry', {
              organization,
            })
          })

          const modifiedOrgs = await Promise.all(promises)
          modifiedOrgs.forEach(organization => {
            commit('appendJoinedOrganization', organization)
          })
        })
        .catch(() => {
          commit('appendJoinedOrganization', [])
        })
    },
    async getMainOrganizationEntry(state, payload) {
      const { organization } = payload
      const organizationID = organization.tablePartitionKey.split('#')[1]
      let mainOrgData = null
      await API.graphql({
        query: getMainOrganizationEntry,
        variables: {
          organizationID,
        },
      }).then(response => {
        const res = response.data.getMainOrganizationEntry

        mainOrgData = {
          ...res,
          organizationRole: organization.organizationRole,
          tableSortKey: organization.tableSortKey,
        }

        if (res.settings) mainOrgData.settings = JSON.parse(res.settings)

        if (mainOrgData.settings.workSchedule === undefined) {
          mainOrgData.settings.workSchedule = {
            sameDay: true,
            sameDayTime: {
              startTime: '',
              endTime: '',
            },
            workDays: {
              monday: { active: true, startTime: '', endTime: '' },
              tuesday: { active: true, startTime: '', endTime: '' },
              wednesday: { active: true, startTime: '', endTime: '' },
              thursday: { active: true, startTime: '', endTime: '' },
              friday: { active: true, startTime: '', endTime: '' },
              saturday: { active: false, startTime: '', endTime: '' },
              sunday: { active: false, startTime: '', endTime: '' },
            },
          }
        }

        if (mainOrgData.settings.holidays === undefined) {
          mainOrgData.settings.holidays = [{ name: '', type: '' }]
        }
      })

      return mainOrgData
    },
    async changeCurrentOrganization(
      { commit, getters, rootGetters, dispatch },
      selectedOrganzation,
    ) {
      // return if the selected organization is current
      if (
        selectedOrganzation.tablePartitionKey ===
        getters.current.tablePartitionKey
      )
        return

      commit('setCurrent', selectedOrganzation)

      // Load Filter Options
      await dispatch('reports/filters/setupFilters', null, { root: true })

      // Check if reports view is active and continue if it is
      if (!rootGetters['reports/reportsPageStatus']) return

      await dispatch('reports/applyFilters', null, { root: true })

      // Refreshes the chart TODO Make chart rerender action
      commit('reports/setLastPageDataFlag', false, { root: true })
      commit('reports/setLastPageDataFlag', true, { root: true })
    },
    async getUserIntegrations(state, payload) {
      const { org, userSub } = payload
      return API.graphql({
        query: recordsByTablePartitionKey,
        variables: {
          tablePartitionKey: `organization#${org}`,
          tableSortKey: {
            beginsWith: `integrationSettings#`,
          },
        },
      }).then(res => {
        const integrationArr = res.data.recordsByTablePartitionKey.items.filter(
          item =>
            JSON.parse(item.settings).users.some(user => user.sub === userSub),
        )
        return integrationArr
      })
    },
    async removeUserFromIntegrations({ rootGetters }, payload) {
      const { sourceList, org, userSub } = payload
      sourceList.forEach(source => {
        API.graphql({
          query: recordsByTablePartitionKey,
          variables: {
            tablePartitionKey: `organization#${org}`,
            tableSortKey: {
              beginsWith: source,
            },
          },
        })
          .then(res => {
            const integration = res.data.recordsByTablePartitionKey.items[0]
            const settings = JSON.parse(integration.settings)
            settings.users = settings.users.filter(user => user.sub !== userSub)
            integration.settings = JSON.stringify(settings)
            return integration
          })
          .then(res => {
            API.graphql(
              graphqlOperation(updateWildmetricCollectionRecords, {
                input: {
                  tableSortKey: res.tableSortKey,
                  tablePartitionKey: res.tablePartitionKey,
                  description: res.description,
                  integrationTag: res.integrationTag,
                  title: res.name,
                  settings: res.settings,
                  organization: rootGetters[
                    'organization/current'
                  ].tablePartitionKey
                    .split('#')
                    .pop(''),
                },
              }),
            )
          })
      })
    },
    async removeUserFromOrganization({ dispatch }, payload) {
      const { org, userSub, userEmail } = payload

      await API.graphql({
        query: removeMember,
        variables: {
          input: {
            organizationID: org,
            tableSortKey: userSub,
            userEmail,
          },
        },
      })
        .then(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'success',
              msg: 'Success removing user.',
            },
            {
              root: true,
            },
          )

          dispatch(`organization/org#${org}/getOrganizationPeople`, org, {
            root: true,
          })
        })
        .catch(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'danger',
              msg: 'Error removing user. Please try again.',
            },
            {
              root: true,
            },
          )
        })
    },
    async deleteOrganization({ dispatch }, payload) {
      const { org } = payload

      await API.graphql({
        query: deleteOrganization,
        variables: {
          input: {
            organizationID: org,
          },
        },
      }).catch(() => {
        this.$store.dispatch('loadingManager/end', 'deleteOrg', {
          root: true,
        })
        dispatch(
          'notifications/alerts/appendAlert',
          {
            type: 'error',
            msg: 'Error deleting organization. Please try again.',
          },
          {
            root: true,
          },
        )
      })
    },
    async shareFilterWithReader({ rootGetters, dispatch }, payload) {
      const { expires, defaultTime, showTime, email, description, filterId } =
        payload
      const current = rootGetters['organization/current']
      const orgId = current.tablePartitionKey.split('#')[1]
      const userSub = rootGetters[`organization/org#${orgId}/userSub`](email)
      const epoch = Date.now()
      const currentDate = new Date()
      const expirationDate = Math.round(
        currentDate.setDate(currentDate.getDate() + expires) / 1000,
      )

      API.graphql(
        graphqlOperation(shareFilterWithReader, {
          input: {
            tableSortKey: `sharedFilter#${userSub}#${epoch}`,
            tablePartitionKey: current.tablePartitionKey,
            description,
            settings: JSON.stringify({
              expires,
              defaultTime,
              showTime,
              filterId,
            }),
            ttl: expirationDate,
            organization: orgId,
          },
        }),
      )
        .then(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'success',
              msg: 'Success sharing filter.',
            },
            {
              root: true,
            },
          )

          const filterName = filterId.split('#')

          const newShared = []

          const filter = rootGetters[
            'reports/filters/customFiltersOptions'
          ].filter(item => item.name === filterName[2])[0]

          newShared.push(email)
          if (filter?.settings?.shared)
            filter.settings.shared.forEach(el => newShared.push(el))

          API.graphql(
            graphqlOperation(updateWildmetricCollectionRecords, {
              input: {
                tableSortKey: filterId,
                tablePartitionKey: current.tablePartitionKey,
                settings: JSON.stringify({ shared: newShared }),
                organization: rootGetters[
                  'organization/current'
                ].tablePartitionKey
                  .split('#')
                  .pop(''),
              },
            }),
          )
        })
        .catch(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: 'Error sharing filter.',
            },
            {
              root: true,
            },
          )
        })
    },
  },
  mutations: {
    setCurrent(state, organization) {
      state.current = organization
    },
    setNewRole(state, { role, organization }) {
      state.current.organizationRole = role
      state.joined[organization].organizationRole = role
    },
    appendJoinedOrganization(state, organization) {
      // Check if the name of the organization is added to avoid duplicate items
      if (!Object.keys(state.joined).includes(organization.displayName)) {
        state.joined = {
          [organization.displayName]: organization,
          ...state.joined,
        }
      }
    },
    setJoined(state, value) {
      state.joined = value
    },
    setSameDay(state, value) {
      state.current.settings.workSchedule.sameDay = value
    },
    setSameDayTime(state, value) {
      state.current.settings.workSchedule.sameDayTime = value
    },
    setWorkDays(state, value) {
      state.current.settings.workSchedule.workDays = value
    },
    setHolidays(state, value) {
      state.current.settings.holidays = value
    },
    addHoliday(state, value) {
      state.current.settings.holidays.push(value)
    },
  },
  getters: {
    current: state => state.current,
    joined: state => Object.keys(state.joined).map(key => state.joined[key]),
    singleJoined: state => name => {
      const org = Object.values(state.joined).filter(
        el => el.tablePartitionKey.split('#')[1] === name,
      )[0]
      return org
    },
    orgExists: state => name => !!state[name],
  },
}
