import { I18n } from 'aws-amplify'

I18n.setLanguage('en')
I18n.putVocabularies({
  en: {
    'Sign in to your account': ' ',
    'Custom auth lambda trigger is not configured for the user pool.':
      'Password cannot be empty',
  },
})
