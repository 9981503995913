import BasicFilter from './constuctors/BasicFilter'
import DateFilter from './constuctors/DateFilter'
import TagsFilter from './constuctors/TagsFilter'
import SearchFilter from './constuctors/SearchFilter'
import CustomFilters from './constuctors/CustomFilter'
import OptionsFilter from './constuctors/OptionsFilter'
import parseFiltersToOS from './utils/OSParser'

function FiltersList(filters = {}) {
  const {
    integrations,
    groups,
    members,
    emails,
    projects,
    dateRange,
    tags,
    search,
    options,
    custom,
  } = filters || {}
  this.integrations = new BasicFilter({ ...integrations, labelKey: 'none' })
  this.groups = new BasicFilter({ ...groups, labelKey: 'initiatorGroupName' })
  this.members = new BasicFilter({ ...members, labelKey: 'name' })
  this.emails = new BasicFilter({ ...emails, labelKey: 'email' })
  this.projects = new BasicFilter({
    ...projects,
    labelKey: 'none',
  })
  this.dateRange = new DateFilter(dateRange)
  this.tags = new TagsFilter(tags)
  this.search = new SearchFilter(search)
  this.options = new OptionsFilter({ ...options, dateRange: this.dateRange })
  this.custom = new CustomFilters(custom)
}

// Filters class constructor function
function Filters(filters = {}) {
  this.list = new FiltersList(filters)

  this.setFilters = input => {
    if (!input) return
    Object.entries(input).forEach(([key, value]) => this.setFilter(key, value))
    this.setQueryParams()
  }
  this.changeFilters = input => {
    this.list = new FiltersList(input)
  }
  this.clearFilters = () => this.changeFilters()

  this.clearFiltersValue = ({ exclude }) => {
    Object.entries(this.list).forEach(([name, filter]) => {
      if (exclude.includes(name)) return
      filter.clearValue?.()
    })
    this.setQueryParams()
  }

  this.parseToOS = () => {
    return parseFiltersToOS(this.list)
  }

  this.parseToQueryArray = () => {
    const queryArray = []
    Object.entries(this.list).forEach(([name, filter]) => {
      const paramObject = filter?.getValueParams?.(name)
      if (!paramObject) return
      queryArray.push(paramObject)
    })

    return queryArray
  }

  this.applyCustomFilter = input => {
    this.clearFiltersValue({ exclude: ['dateRange', 'custom'] })
    input.forEach(({ name, value }) => this.list[name]?.setValueByLabel(value))
  }

  this.applyQueryParams = () => {
    const input = new URLSearchParams(window.location.search)

    const paramMap = Object.fromEntries(
      [...input.keys()].map(key => [[key], { name: key, value: [] }]),
    )

    Array.from(input).forEach(([key, value]) => {
      paramMap[key].value.push(value)
    })

    const paramArray = Object.values(paramMap)

    paramArray.forEach(({ name, value }) =>
      this.list[name]?.setValueByLabel(value, true),
    )
  }

  this.setQueryParams = () => {
    const QueryArray = this.parseToQueryArray()
    const QueryParams = new URLSearchParams()
    // console.log(QueryArray)
    QueryArray.forEach(({ name, value }) => {
      QueryParams.delete(name)
      value.forEach(entry => {
        if (name === 'tags') {
          const tagEntry = entry
            .map(tag => `${tag.type}.${tag.name}`)
            .toString()
          QueryParams.append(name, tagEntry)
        } else {
          QueryParams.append(name, entry)
        }
      })
    })

    // eslint-disable-next-line no-restricted-globals
    history.pushState(
      {},
      null,
      `${window.location.pathname}?${QueryParams.toString()}`,
    )
  }

  const checkOptionQueryParams = (key, value) => {
    const currentKey =
      this.list[key].labelKey === 'none'
        ? value
        : value[this.list[key].labelKey]

    const isInParams = new URLSearchParams(window.location.search)
      .getAll(key)
      .includes(currentKey)

    if (isInParams) this.list[key]?.addValue(value)
  }

  // Filter Utils
  this.addFilterOption = (key, value) => {
    this.list[key]?.addOption?.(value)
    checkOptionQueryParams(key, value)
  }
  this.clearFilterValue = key => this.list[key]?.clearValue?.()
  this.clearFilterOptions = key => this.list[key]?.clearOptions?.()
  this.addFilterValue = (key, value) => {
    this.list[key]?.addValue?.(value)
    this.setQueryParams()
  }
  this.setFilterValue = (key, value, applyQuery = true) => {
    this.list[key]?.setValue?.(value)
    if (key === 'dateRange')
      this.list.options.setValue({ name: 'granularity', value: false })
    if (applyQuery) this.setQueryParams()
  }
  this.setFilterValueByProperty = (key, name, value) =>
    this.list[key]?.setValueByProperty?.(name, value)
  this.removeCustomFilterOption = name => this.list.custom.removeOption(name)
}

export default Filters
