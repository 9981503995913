export default {
  namespaced: true,
  state: {
    organizationName: '',
    organizationJoinOrCreate: true,
    integrationName: '',
    integrationDescription: '',
    integrationTag: '',
    timePicker: '',
    time: '',
    jiraURL: '',
    jiraUser: '',
    slackUsers: '',
    slackChannel: '',
  },
  mutations: {
    setOrganizationName(state, value) {
      state.organizationName = value
    },
    setOrganizationJoinOrCreate(state, value) {
      state.organizationJoinOrCreate = value
    },
    setIntegrationName(state, value) {
      state.integrationName = value
    },
    setIntegrationDescription(state, value) {
      state.integrationDescription = value
    },
    setIntegrationTag(state, value) {
      state.integrationTag = value
    },
    setTimePicker(state, value) {
      state.timePicker = value
    },
    setTime(state, value) {
      state.time = value
    },
    setJiraURL(state, value) {
      state.jiraURL = value
    },
    setJiraUser(state, value) {
      state.jiraUser = value
    },
    setSlackUsers(state, value) {
      state.slackUsers = value
    },
    setSlackChannel(state, value) {
      state.slackChannel = value
    },
  },
  getters: {
    organizationName: state => state.organizationName,
    organizationJoinOrCreate: state => state.organizationJoinOrCreate,
    integrationName: state => state.integrationName,
    integrationDescription: state => state.integrationDescription,
    integrationTag: state => state.integrationTag,
    timePicker: state => state.timePicker,
    time: state => state.time,
    jiraURL: state => state.jiraURL,
    jiraUser: state => state.jiraUser,
    slackUsers: state => state.slackUsers,
    slackChannel: state => state.slackChannel,
  },
}
