<template>
  <ModalLayout id="AddMemberModal">
    <template v-slot:title>Add User</template>
    <template v-slot:body>
      <div class="form-group">
        <label class="mb-2">Email</label>
        <input
          v-model="newMemberEmail"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.newMemberEmail.$invalid }"
          placeholder="Users's email address"
          required
        />
        <small v-if="v$.newMemberEmail.$invalid" class="text-danger mt-2 ms-2">
          This field should be a valid email address!
        </small>
      </div>

      <select
        class="form-select my-3"
        aria-label="User role"
        v-model="userRole"
      >
        <option value="USER">User</option>
        <option value="READER">Reader</option>
        <option value="VIEWER">Viewer</option>
      </select>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-custom" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        data-test="submit-reset-pass"
        class="btn btn-primary"
        type="submit"
        @click="inviteMember"
        :disabled="v$.newMemberEmail.$invalid || addMemberLoader"
      >
        <span
          v-if="addMemberLoader"
          class="spinner-border spinner-border-sm mx-3 text-info"
        ></span>
        <span v-else>Add</span>
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import { Modal } from 'bootstrap'
import { API } from 'aws-amplify'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { addMember } from '../../../graphql/mutations'
import { getSingleUserByEmail } from '../../../graphql/queries'
import ModalLayout from './ModalLayout.vue'

export default {
  components: {
    ModalLayout,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      newMemberEmail: '',
      userRole: 'USER',
    }
  },
  validations: {
    newMemberEmail: {
      required,
      email,
    },
  },
  computed: {
    addMemberLoader() {
      return this.$store.getters['loadingManager/isLoading']('addMember')
    },
    pendingRequests() {
      return this.$store.getters['notifications/pendingRequests']
    },
  },
  methods: {
    inviteMember() {
      const AddMemberModal = Modal.getOrCreateInstance(
        document.getElementById('AddMemberModal'),
      )

      const { orgId } = this.$route.params

      this.$store.dispatch('loadingManager/start', 'addMember')
      API.graphql({
        query: getSingleUserByEmail,
        variables: {
          email: this.newMemberEmail,
        },
      })
        .then(userQueryResponse => {
          const userAttributesObj = Object.assign(
            {},
            ...userQueryResponse.data.getSingleUserByEmail.map(attribute => ({
              [attribute.Name]: attribute.Value,
            })),
          )

          API.graphql({
            query: addMember,
            variables: {
              input: {
                organizationID: orgId,
                tableSortKey: `userSub#${userAttributesObj.sub}`,
                organizationRole: this.userRole,
                userEmail: userAttributesObj.email,
                displayName:
                  this.$store.getters['organization/current'].displayName,
                userFullName: userAttributesObj.family_name
                  ? `${userAttributesObj.given_name} ${userAttributesObj.family_name}`
                  : userAttributesObj.given_name,
              },
            },
          })
            .then(() => {
              this.$store.dispatch('notifications/alerts/appendAlert', {
                type: 'success',
                msg: 'User added successfully.',
              })

              this.$store.dispatch('loadingManager/end', 'addMember')
              AddMemberModal.hide()
              this.$store.dispatch(
                `organization/org#${orgId}/getOrganizationPeople`,
                orgId,
              )
            })
            .catch(err => {
              this.$store.dispatch('loadingManager/end', 'addMember')
              if (err.errors[0].errorType === 'LimitReached') {
                this.$store.dispatch('notifications/alerts/appendAlert', {
                  type: 'error',
                  msg: err.errors[0].message,
                })
              } else {
                this.$store.dispatch('notifications/alerts/appendAlert', {
                  type: 'error',
                  msg: 'Failed adding user to orgnanization.',
                })
              }
            })
        })
        .catch(() => {
          this.$store.dispatch('loadingManager/end', 'addMember')
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'error',
            msg: 'User not found. Please check if the email is correct.',
          })
        })
      return true
    },
  },
}
</script>
