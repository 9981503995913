import { DateTime } from 'luxon'

export default function getFiltersObject({
  search: { text: search },
  dateRange,
  members,
  emails,
  groups,
  integrations,
  projects,
  tags,
}) {
  const qFilters = {
    and: [],
  }

  // Contains Text filter
  /* The query used for search is 'match' as it provides the possiblity to search for phrases.
   * Searching for specific words require full word typing, otherwise it will not match, e.g. test will not match testing.
   */
  if (search !== '') {
    const keyWords = search.split(',')
    if (keyWords.length === 1) {
      qFilters.details = {
        match: keyWords[0].trim(),
      }
    } else {
      const searchFilter = []
      keyWords.forEach(keyword => {
        searchFilter.push({ details: { match: keyword.trim() } })
      })
      qFilters.and.push({ and: searchFilter })
    }
  }

  let startDate = ''
  let endDate = ''

  const { start, end } = dateRange

  // Timestamp filter
  startDate = DateTime.fromJSDate(start).toFormat('yyyy-MM-dd')

  endDate = DateTime.fromJSDate(end).toFormat('yyyy-MM-dd')

  const zone = dateRange.timezone

  if (zone) {
    startDate = DateTime.fromJSDate(start).startOf('day').setZone(zone).toISO()

    endDate = DateTime.fromJSDate(end).endOf('day').setZone(zone).toISO()
  }

  qFilters.timestamp = {
    range: [startDate, endDate],
  }

  /*
   * Members list
   *  if "ALL" selected/nothing is selected for the members or groups filter, get all available options => all organization members
   *  if specific members or groups are selected, get the values
   */
  // Check if either members or groups are selected and create a filter for the worklogOwner
  if (
    members.value.length > 0 ||
    groups.value.length > 0 ||
    emails.value.length > 0
  ) {
    const membersFilter = []

    const parsedMembers = JSON.parse(JSON.stringify(members))

    parsedMembers.value.forEach(member => {
      membersFilter.push({ worklogOwner: { eq: member.sub } })
      if (member.secondaryAccounts)
        JSON.parse(member.secondaryAccounts).forEach(el =>
          membersFilter.push({ worklogOwner: { eq: el } }),
        )
    })
    // Add the group members to the membersFilter
    groups.value.forEach(group =>
      group.initiatorGroupMembers.forEach(member => {
        // Get the sub from the organization members
        const memberSub = members.options.find(
          orgMember => orgMember.email === member,
        ).sub
        membersFilter.push({
          worklogOwner: { eq: memberSub },
        })
      }),
    )

    emails.value.forEach(member => {
      membersFilter.push({ worklogOwner: { eq: member.sub } })
    })

    qFilters.and.push({ or: membersFilter })
  }
  // OR Filter for the integrations
  if (integrations.value.length > 0) {
    qFilters.and.push({
      or: integrations.value.map(integration => {
        return {
          dataSource: {
            eq: integration,
          },
        }
      }),
    })
  }

  // Projects filter
  if (projects.value.length > 0) {
    qFilters.and.push({
      or: projects.value.map(projectVal => {
        return {
          project: {
            eq: projectVal,
          },
        }
      }),
    })
  }

  // Tags filters
  // AND condition tag filter
  if (tags.and && tags.and.length > 0) {
    qFilters.and.push({
      and: tags.and.map(tag => {
        let obj
        if (tag.type === 'integration') {
          obj = {
            integrationTag: {
              match: `#${tag.name}`,
            },
          }
        } else {
          obj = {
            details: {
              match: `#${tag.name}`,
            },
          }
        }

        return obj
      }),
    })
  }

  // OR condition tag filter
  if (tags.or && tags.or.length > 0) {
    qFilters.and.push({
      or: tags.or.map(tag => {
        let obj
        if (tag.type === 'integration') {
          obj = {
            integrationTag: {
              match: `#${tag.name}`,
            },
          }
        } else {
          obj = {
            details: {
              match: `#${tag.name}`,
            },
          }
        }

        return obj
      }),
    })
  }

  // Remove the AND operator when none of the qFilters is using it
  if (qFilters.and.length === 0) {
    delete qFilters.and
  }

  return qFilters
}
