import { DateTime } from 'luxon'

const autoSelectMap = {
  today: {
    start: DateTime.now().startOf('day').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  '3m': {
    start: DateTime.now().startOf('day').minus({ months: 3 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  '1y': {
    start: DateTime.now().startOf('day').minus({ years: 1 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  qtd: {
    start: DateTime.now().startOf('day').startOf('quarter').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
  ytd: {
    start: DateTime.now().startOf('day').startOf('year').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  },
}

export default {
  getDefaultParams() {
    return {
      organization: null,
      dateRange: null,
      integrations: null,
      projects: null,
      members: null,
      groups: null,
      tags: {},
      groupBy: null,
      granularity: null,
      stack: null,
      search: null,
    }
  },
  getDateRange(startDate, endDate) {
    const start = DateTime.fromFormat(startDate, 'd-M-yyyy').toJSDate()
    const end = DateTime.fromFormat(endDate, 'd-M-yyyy').toJSDate()
    return {
      start,
      end,
    }
  },
  parseDateRange(date) {
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  },
  getQuickSelectDates(type) {
    // Checks if autoKey is valid auto select option
    if (!Object.prototype.hasOwnProperty.call(autoSelectMap, `${type}`))
      return false
    const { start: startDate, end: endDate } = autoSelectMap[type]
    return {
      start: startDate,
      end: endDate,
    }
  },
}
