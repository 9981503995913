<template>
  <ModalLayout id="confirmEmail">
    <template v-slot:title> Confirm Email </template>
    <template v-slot:body>
      <div class="form-group">
        <p>Enter the confirmation code that you have received on your Email.</p>
        <p>
          You must receive an email in a couple of minuts. If don't receive an
          email, please click Cancel and check your email.
        </p>
        <input
          id="user-code"
          v-model="code"
          class="form-control"
          :class="{
            'is-invalid': v$.code.$invalid,
          }"
          type="text"
        />
        <small v-if="v$.code.required.$invalid" class="text-danger mt-2 ms-2">
          This field is required!
        </small>
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        @click="Confirmation"
        class="btn btn-primary"
        data-bs-dismiss="modal"
        :disabled="v$.$invalid"
      >
        Confirm
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ModalLayout from './ModalLayout.vue'

export default {
  name: 'ConfirmEmail',
  components: {
    ModalLayout,
  },
  props: {
    curEmail: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    code: {
      required,
    },
  },
  data() {
    return {
      code: '',
      e: '',
      stat: '',
    }
  },
  methods: {
    async Confirmation() {
      const user = await this.$Amplify.Auth.currentAuthenticatedUser()

      await this.$Amplify.Auth.verifyCurrentUserAttributeSubmit(
        'email',
        this.$data.code,
      )
        .then(() => {
          this.$data.stat = 'no-error'
          // eslint-disable-next-line no-alert
          alert('Email was changed successfully.')
        })
        .catch(e => {
          this.$data.e = e
          // eslint-disable-next-line no-alert
          alert(e.message)
        })

      if (this.$data.e !== '') {
        await this.$Amplify.Auth.updateUserAttributes(user, {
          email: this.$props.curEmail,
        })
        this.$store.dispatch('profile/forceUpdateUser')
      } else {
        this.$store.dispatch('profile/forceUpdateUser')
      }
    },
  },
}
</script>
