<template>
  <div class="d-flex w-100 justify-content-center px-2">
    <NavbarTitle title="wildmetric" route="/" />
    <NavbarLinks />
    <NavbarUtils />
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { DateTime } from 'luxon'
import {
  onCreateWildmetricNotifications,
  onDeleteJoinOrganizationRequest,
  onDeleteWildmetricNotification,
} from '../../graphql/subscriptions'
import NavbarUtils from './NavbarUtils.vue'
import NavbarLinks from './NavbarLinks.vue'
import NavbarTitle from './NavbarTitle.vue'

export default {
  components: {
    NavbarUtils,
    NavbarLinks,
    NavbarTitle,
  },
  data() {
    return {
      notificationSubscribe: null,
      removeJoinOrganizatioRequest: null,
      deleteNotificationSubscribe: null,
    }
  },
  created() {
    this.notificationSubscribe = API.graphql({
      query: onCreateWildmetricNotifications,
    }).subscribe({
      next: ({ value }) => {
        const response = value.data.onCreateWildmetricNotifications
        if (response) {
          if (this.$store.getters['profile/user']['custom:timezone']) {
            response.timestamp = DateTime.fromISO(response.timestamp)
              .toUTC()
              .setZone(this.$store.getters['profile/user']['custom:timezone'])
              .toFormat('dd LLL yyyy h:mm a')
          } else {
            response.timestamp = DateTime.fromISO(response.timestamp)
              .toUTC()
              .toFormat('dd LLL yyyy h:mm a')
          }
          this.$store.dispatch('notifications/addNewNotifications', response)

          if (
            response.actionGetters !== 'null' ||
            this.$store.getters[
              'notifications/shownNotifications'
            ].types.includes(response.classification)
          ) {
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: response.classification,
              msg: response.messageBody,
            })
          }
        }
      },
      // TODO fix this
      // eslint-disable-next-line no-console
      error: error => console.log(error),
    })

    this.removeJoinOrganizatioRequest = API.graphql({
      query: onDeleteJoinOrganizationRequest,
    }).subscribe({
      next: async ({ value }) => {
        await this.$store.dispatch('organization/joined')
        const joinedOrg = this.$store.getters['organization/joined'].filter(
          org =>
            org.tablePartitionKey ===
            `organization#${
              value.data.onDeleteJoinOrganizationRequest.tableSortKey.split(
                '#',
              )[2]
            }`,
        )

        if (joinedOrg.length > 0) {
          this.$store.dispatch('profile/forceUpdateUser')
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'success',
            msg: `Organization ${joinedOrg[0].displayName} joined.`,
          })
        }

        this.$store.dispatch(
          'userRequests/removeUserRequest',
          value.data.onDeleteJoinOrganizationRequest,
        )
      },
      // TODO fix this
      // eslint-disable-next-line no-console
      error: error => console.log(error),
    })

    this.deleteNotificationSubscribe = API.graphql({
      query: onDeleteWildmetricNotification,
    }).subscribe({
      next: ({ value }) => {
        const response = value.data.onDeleteWildmetricNotification
        if (response) {
          this.$store.dispatch(
            'notifications/removeNotificationFromStore',
            response,
          )
        }
      },
      // TODO fix this
      // eslint-disable-next-line no-console
      error: error => console.log(error),
    })
  },
  beforeDestroy() {
    // unsubscribe and reset variables
    this.notificationSubscribe.unsubscribe()
    this.notificationSubscribe = null

    this.removeJoinOrganizatioRequest.unsubscribe()
    this.removeJoinOrganizatioRequest = null

    this.deleteNotificationSubscribe.unsubscribe()
    this.deleteNotificationSubscribe = null
  },
}
</script>
