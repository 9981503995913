import { API, graphqlOperation } from 'aws-amplify'
import {
  getSingleUserBySub,
  getOrganizationMember,
  getInitiatorsGroup,
  getOrgSubscriptionData,
  getOrgCustomerData,
  getOrgInvoices,
} from '../graphql/queries'
import {
  createGroups,
  updateWildmetricCollectionRecords,
  deleteGroup,
} from '../graphql/mutations'

export default {
  singleOrgModule(props) {
    const gettersObj = {
      users: state => state.users,
      groups: state => state.groups,
      userEmail: state => sub =>
        state.users.filter(user => user.sub === sub)[0]?.email,
      userSub: state => email =>
        state.users.filter(user => user.email === email)[0]?.sub,
      owner: state => state.users.filter(user => user.role === 'OWNER'),
      subscription: state => state.subscription,
      customer: state => state.customer,
      getSingleInvoice: state => id =>
        state.invoices.filter(invoice => invoice.tableSortKey === id)[0],
      getUser: state => sub => state.users.filter(user => user.sub === sub)[0],
      getUserByAtlassianId: state => atlassianId =>
        state.users.filter(user => user.atlassianId === atlassianId)[0],
    }
    Object.keys(props).forEach(key => {
      gettersObj[key] = state => state[key]
    })
    return {
      namespaced: true,
      state: {
        users: [],
        groups: [],
        subscription: {},
        customer: {
          address: {},
          email: '',
          phone: '',
          name: '',
          tax_ids: [],
        },
        invoices: [],
        ...props,
      },
      actions: {
        async getOrganizationPeople({ dispatch, commit }, orgId) {
          commit('setUsers', [])
          commit('reports/filters/clearFilterOptions', 'members', {
            root: true,
          })

          await dispatch('fetchOrganizationMembers', {
            orgId,
            token: null,
          }).catch(() => {
            commit('setUsers', [])
          })
        },
        async fetchOrganizationMembers({ dispatch, commit }, { orgId, token }) {
          await API.graphql({
            query: getOrganizationMember,
            variables: {
              organizationID: orgId,
              limit: 10,
              nextToken: token,
            },
          })
            .then(async response => {
              const {
                data: {
                  getOrganizationMember: { items, nextToken },
                },
              } = response

              // Loop all of the organization people and pull their meta data
              const promises = items.map(async person => {
                const user = await dispatch('populatePeople', person)
                return user
              })

              const users = await Promise.all(promises)

              users.forEach(user => {
                dispatch('reports/filters/addMember', user, { root: true })
                commit('addUser', user)
              })

              if (!token) {
                dispatch('loadingManager/end', 'usersLoading', { root: true })
              }

              // Push the populated person object with details in the global people array
              if (nextToken) {
                dispatch('fetchOrganizationMembers', {
                  orgId,
                  token: nextToken,
                })
              }
            })
            .catch(({ errors }) => {
              errors.forEach(({ message }) => {
                dispatch(
                  'notifications/alerts/appendAlert',
                  {
                    type: 'error',
                    msg: message,
                  },
                  { root: true },
                )
              })
            })
        },
        async populatePeople(state, person) {
          const personObject = {}

          personObject.role = person.organizationRole
          personObject.joinDate = person.createdAt
          personObject.status = person.status
          personObject.atlassianId = person.atlassianId

          await API.graphql({
            query: getSingleUserBySub,
            variables: {
              userSub: person.tableSortKey.split('#')[1],
            },
          })
            .then(response => {
              // Reformat the received response and push to the object
              response.data.getSingleUserBySub.forEach(attribute => {
                personObject[attribute.Name] = attribute.Value
              })
            })
            .catch(response => {
              /**
               * Set the error as an email in order to be shown on the frontend
               * In most cases, it's "Error: User does not exist."
               */
              const [error] = response.errors
              personObject.email = error.message
            })

          /*
           * This will mix the user values from cognito with the values from DynamoDB.
           * To keep the JSON small we are only pulling limited data from DynamoDB (for reference,
           * check the getOrganizationMember query).
           */
          return { ...person, ...personObject }
        },
        async getOrgSubscriptionData({ dispatch, commit, state }) {
          await API.graphql({
            query: getOrgSubscriptionData,
            variables: {
              organizationID: state.tablePartitionKey.split('#')[1],
            },
          })
            .then(response => {
              const orgSubscriptionData = response.data.getOrgSubscriptionData
              commit('setOrgSubscriptionData', orgSubscriptionData)
              dispatch('getOrgCustomerData')
              dispatch('getOrgInvoices')
            })
            .catch(() => {
              commit('setOrgSubscriptionData', undefined)
            })
        },
        async getOrgCustomerData({ commit, state }) {
          await API.graphql({
            query: getOrgCustomerData,
            variables: {
              organizationID: state.tablePartitionKey.split('#')[1],
            },
          })
            .then(response => {
              const data = JSON.parse(
                response.data.getOrgCustomerData.customerData,
              )

              const orgCustomerData = { ...state.customer, ...data }

              if (!orgCustomerData.address) orgCustomerData.address = {}

              commit('setOrgCustomerData', orgCustomerData)
            })
            .catch(() => {
              commit('setOrgCustomerData', undefined)
            })
        },
        async getOrgInvoices({ commit, state }) {
          let orgInvoices = []
          await API.graphql({
            query: getOrgInvoices,
            variables: {
              organizationID: state.tablePartitionKey.split('#')[1],
            },
          })
            .then(response => {
              orgInvoices = JSON.parse(response.data.getOrgInvoices).items
              commit('setOrgInvoices', orgInvoices)
            })
            .catch(() => {
              commit('setOrgInvoices', undefined)
            })
          return orgInvoices
        },
        async getGroups({ commit, getters, dispatch }, nextToken = null) {
          const organizationID = getters.tablePartitionKey.split('#')[1]
          const groupsArray = []

          API.graphql(
            graphqlOperation(getInitiatorsGroup, {
              organizationID,
              nextToken: nextToken || '',
            }),
          ).then(res => {
            const groupList = res.data.getInitiatorsGroup.items

            groupList.forEach(group => {
              const sortKey = group.tableSortKey
              const name = group.displayName
              const members = group.initiatorGroupMembers
              const currentGroup = {
                sortKey,
                name,
                members,
              }
              groupsArray.push(currentGroup)
            })

            commit('setGroups', groupsArray)

            if (res.data.getInitiatorsGroup.nextToken) {
              dispatch('getInitiatorsGroups', nextToken)
            }
          })
        },
        createGroup({ getters, dispatch }, payload) {
          const organizationID = getters.tablePartitionKey.split('#')[1]
          const groupsArray = getters.groups
          const exist = groupsArray.filter(
            group =>
              group.name === payload.name ||
              group.sortKey.includes(payload.name),
          )

          if (exist.length > 0) {
            dispatch(
              'notifications/alerts/appendAlert',
              {
                type: 'danger',
                msg: 'A group with this name already exists',
              },
              { root: true },
            )
          } else {
            const input = {
              tablePartitionKey: organizationID,
              tableSortKey: `initiatorsGroup#${payload.name}`,
              displayName: payload.name,
              initiatorGroupMembers: payload.members,
            }
            API.graphql(
              graphqlOperation(createGroups, {
                input,
              }),
            )
              .then(() => {
                dispatch(
                  'notifications/alerts/appendAlert',
                  {
                    type: 'success',
                    msg: 'Group created successfully!',
                  },
                  { root: true },
                )

                dispatch('getGroups')
                dispatch('reports/filters/getGroups', null, { root: true })
              })
              .catch(() => {
                dispatch(
                  'notifications/alerts/appendAlert',
                  {
                    type: 'error',
                    msg: 'Creating group failed please try again.',
                  },
                  { root: true },
                )
              })
          }
        },
        editGroup({ getters, dispatch }, payload) {
          const organizationID = getters.tablePartitionKey

          const input = {
            tablePartitionKey: organizationID,
            tableSortKey: payload.sortKey,
            displayName: payload.name,
            initiatorGroupMembers: payload.members,
            organization: getters.tablePartitionKey.split('#').pop(''),
          }
          API.graphql(
            graphqlOperation(updateWildmetricCollectionRecords, {
              input,
            }),
          )
            .then(() => {
              dispatch(
                'notifications/alerts/appendAlert',
                {
                  type: 'success',
                  msg: 'Group edited successfully!',
                },
                { root: true },
              )

              dispatch('getGroups')
              dispatch('reports/filters/getGroups', null, { root: true })
            })
            .catch(() => {
              dispatch(
                'notifications/alerts/appendAlert',
                {
                  type: 'error',
                  msg: 'Group edit failed please try again.',
                },
                { root: true },
              )
            })
        },
        deleteGroup({ getters, dispatch }, payload) {
          const organizationID = getters.tablePartitionKey

          const input = {
            tablePartitionKey: organizationID,
            tableSortKey: payload.sortKey,
          }
          API.graphql(
            graphqlOperation(deleteGroup, {
              input,
            }),
          )
            .then(() => {
              dispatch(
                'notifications/alerts/appendAlert',
                {
                  type: 'success',
                  msg: 'Group deleted successfully!',
                },
                { root: true },
              )

              dispatch('getGroups')
              dispatch('reports/filters/getGroups', null, { root: true })
            })
            .catch(() => {
              dispatch(
                'notifications/alerts/appendAlert',
                {
                  type: 'error',
                  msg: 'Deleting group failed please try again.',
                },
                { root: true },
              )
            })
        },
      },
      mutations: {
        setUsers(state, value) {
          state.users = value
        },
        addUser(state, value) {
          state.users.push(value)
        },
        setOrgSubscriptionData(state, value) {
          state.subscription = value
        },
        setOrgCustomerData(state, value) {
          state.customer = value
        },
        setOrgInvoices(state, value) {
          state.invoices = value
        },
        setGroups(state, value) {
          state.groups = value
        },
        addGroup(state, value) {
          state.groups.push(value)
        },
        setCustomer(state, value) {
          state.customer = value
        },
        setDisplayName(state, value) {
          state.displayName = value
        },
        setMainTab(state, value) {
          state.mainTab = value
        },
        setActiveTabs(state, value) {
          state.activeTabs = value
        },
      },
      getters: gettersObj,
    }
  },
}
