/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSingleUserBySub = /* GraphQL */ `
  query GetSingleUserBySub($userSub: String!) {
    getSingleUserBySub(userSub: $userSub) {
      Name
      Value
      __typename
    }
  }
`
export const getSingleUserByEmail = /* GraphQL */ `
  query GetSingleUserByEmail($email: String!) {
    getSingleUserByEmail(email: $email) {
      Name
      Value
      __typename
    }
  }
`
export const getInitiatorsGroup = /* GraphQL */ `
  query GetInitiatorsGroup(
    $organizationID: String!
    $limit: Int
    $nextToken: String
  ) {
    getInitiatorsGroup(
      organizationID: $organizationID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tableSortKey
        initiatorGroupMembers
        displayName
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getInitiator = /* GraphQL */ `
  query GetInitiator(
    $organizationID: String!
    $limit: Int
    $nextToken: String
  ) {
    getInitiator(
      organizationID: $organizationID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        initiator
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOrganizationMember = /* GraphQL */ `
  query GetOrganizationMember(
    $organizationID: String!
    $limit: Int
    $nextToken: String
  ) {
    getOrganizationMember(
      organizationID: $organizationID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tableSortKey
        organizationRole
        status
        atlassianId
        secondaryAccounts
        primaryAccount
        createdAt
        manualAtlassianId
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getIntegrationData = /* GraphQL */ `
  query GetIntegrationData(
    $organizationID: String!
    $userSub: String
    $limit: Int
    $groupBy: String
    $granularity: String
    $stack: String
    $nextToken: String
    $filter: IntegrationDataFilterInput
    $sort: IntegrationDataSortInput
    $timezone: String
  ) {
    getIntegrationData(
      organizationID: $organizationID
      userSub: $userSub
      limit: $limit
      groupBy: $groupBy
      granularity: $granularity
      stack: $stack
      nextToken: $nextToken
      filter: $filter
      sort: $sort
      timezone: $timezone
    ) {
      items {
        userSub
        timestamp
        project
        tablePartitionKey
        details
        timespent
        initiator
        workchunk
        tableSortKey
        worklogAuthor
        dataSource
        worklogOwner
        tags
        integrationTag
        __typename
      }
      aggregations
      nextToken
      __typename
    }
  }
`
export const getNotificationsData = /* GraphQL */ `
  query GetNotificationsData($nextToken: String) {
    getNotificationsData(nextToken: $nextToken) {
      items {
        tablePartitionKey
        tableSortKey
        organization
        classification
        messageBody
        seen
        title
        timestamp
        ttl
        dataSource
        actionGetters
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOrgSubscriptionData = /* GraphQL */ `
  query GetOrgSubscriptionData(
    $organizationID: String!
    $subscriptionID: String
  ) {
    getOrgSubscriptionData(
      organizationID: $organizationID
      subscriptionID: $subscriptionID
    ) {
      tablePartitionKey
      tableSortKey
      seats
      paidSeats
      tier
      status
      monthlyFee
      billingEnd
      ttl
      __typename
    }
  }
`
export const getMainOrganizationEntry = /* GraphQL */ `
  query GetMainOrganizationEntry($organizationID: String!) {
    getMainOrganizationEntry(organizationID: $organizationID) {
      tablePartitionKey
      tableSortKey
      displayName
      slug
      settings
      __typename
    }
  }
`
export const getOrgCustomerData = /* GraphQL */ `
  query GetOrgCustomerData($organizationID: String!) {
    getOrgCustomerData(organizationID: $organizationID) {
      tablePartitionKey
      tableSortKey
      customerData
      __typename
    }
  }
`
export const getOrgInvoices = /* GraphQL */ `
  query GetOrgInvoices($organizationID: String!) {
    getOrgInvoices(organizationID: $organizationID)
  }
`
export const getCustomFilters = /* GraphQL */ `
  query GetCustomFilters($organizationID: String!, $userSub: String) {
    getCustomFilters(organizationID: $organizationID, userSub: $userSub) {
      items {
        tablePartitionKey
        tableSortKey
        filters {
          name
          value
          __typename
        }
        name
        access
        settings
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getLatestManualAddWorklog = /* GraphQL */ `
  query GetLatestManualAddWorklog($tableSortKey: String!) {
    getLatestManualAddWorklog(tableSortKey: $tableSortKey) {
      userSub
      timestamp
      project
      tablePartitionKey
      details
      timespent
      initiator
      workchunk
      tableSortKey
      worklogAuthor
      dataSource
      worklogOwner
      tags
      integrationTag
      __typename
    }
  }
`
export const searchIntegrationData = /* GraphQL */ `
  query SearchIntegrationData(
    $organizationID: String!
    $worklogOwner: String
    $limit: Int
    $groupBy: String!
    $granularity: String
    $stack: String
    $nextToken: String
    $fields: [String]
    $searchString: String!
    $sort: IntegrationDataSortInput
    $timezone: String
  ) {
    searchIntegrationData(
      organizationID: $organizationID
      worklogOwner: $worklogOwner
      limit: $limit
      groupBy: $groupBy
      granularity: $granularity
      stack: $stack
      nextToken: $nextToken
      fields: $fields
      searchString: $searchString
      sort: $sort
      timezone: $timezone
    ) {
      items {
        userSub
        timestamp
        project
        tablePartitionKey
        details
        timespent
        initiator
        workchunk
        tableSortKey
        worklogAuthor
        dataSource
        worklogOwner
        tags
        integrationTag
        __typename
      }
      aggregations
      nextToken
      __typename
    }
  }
`
export const getTags = /* GraphQL */ `
  query GetTags($organizationID: String!, $limit: Int, $nextToken: String) {
    getTags(
      organizationID: $organizationID
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tableSortKey
        __typename
      }
      nextToken
      error
      __typename
    }
  }
`
export const getUserRequests = /* GraphQL */ `
  query GetUserRequests($nextToken: String) {
    getUserRequests(nextToken: $nextToken) {
      items {
        tablePartitionKey
        tableSortKey
        status
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getWildmetricCollectionRecords = /* GraphQL */ `
  query GetWildmetricCollectionRecords(
    $tablePartitionKey: String!
    $tableSortKey: String!
    $organization: String
  ) {
    getWildmetricCollectionRecords(
      tablePartitionKey: $tablePartitionKey
      tableSortKey: $tableSortKey
      organization: $organization
    ) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const listWildmetricCountriesTimezones = /* GraphQL */ `
  query ListWildmetricCountriesTimezones(
    $code: ID
    $filter: listWildmetricCountriesTimezonesInput
    $limit: Int
    $nextToken: String
    $sortDirection: listSortDirection
  ) {
    listWildmetricCountriesTimezones(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        countryName
        zones
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getWildmetricCountriesTimezones = /* GraphQL */ `
  query GetWildmetricCountriesTimezones($code: ID!) {
    getWildmetricCountriesTimezones(code: $code) {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listWildmetricCountriesTimezoness = /* GraphQL */ `
  query ListWildmetricCountriesTimezoness(
    $code: ID
    $filter: ModelwildmetricCountriesTimezonesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWildmetricCountriesTimezoness(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        countryName
        zones
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const recordsBySortKey = /* GraphQL */ `
  query RecordsBySortKey(
    $tableSortKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelwildmetricCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsBySortKey(
      tableSortKey: $tableSortKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tablePartitionKey
        tableSortKey
        displayName
        organizationRole
        integrationSource
        integrationTag
        details
        worklogAuthor
        initiator
        initiatorGroupMembers
        timespent
        timestamp
        project
        workchunk
        title
        settings
        access
        filters {
          name
          value
          __typename
        }
        filterName
        enabled
        description
        integrationStatus
        dataSource
        organization
        worktime
        userSub
        allUsers
        atlassianId
        status
        createdAt
        updatedAt
        manualAtlassianId
        seats
        paidSeats
        tier
        monthlyFee
        billingEnd
        ttl
        stripePriceId
        stripeCustomerId
        planLimits
        planPrice
        secondaryAccounts
        primaryAccount
        customerData
        invoiceNumber
        invoiceDates
        invoiceLines
        invoiceTotals
        jiraAccessToken
        jiraRefreshToken
        userEmail
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const recordsByTablePartitionKey = /* GraphQL */ `
  query RecordsByTablePartitionKey(
    $tablePartitionKey: String
    $tableSortKey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelwildmetricCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByTablePartitionKey(
      tablePartitionKey: $tablePartitionKey
      tableSortKey: $tableSortKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tablePartitionKey
        tableSortKey
        displayName
        organizationRole
        integrationSource
        integrationTag
        details
        worklogAuthor
        initiator
        initiatorGroupMembers
        timespent
        timestamp
        project
        workchunk
        title
        settings
        access
        filters {
          name
          value
          __typename
        }
        filterName
        enabled
        description
        integrationStatus
        dataSource
        organization
        worktime
        userSub
        allUsers
        atlassianId
        status
        createdAt
        updatedAt
        manualAtlassianId
        seats
        paidSeats
        tier
        monthlyFee
        billingEnd
        ttl
        stripePriceId
        stripeCustomerId
        planLimits
        planPrice
        secondaryAccounts
        primaryAccount
        customerData
        invoiceNumber
        invoiceDates
        invoiceLines
        invoiceTotals
        jiraAccessToken
        jiraRefreshToken
        userEmail
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const recordsByOrganization = /* GraphQL */ `
  query RecordsByOrganization(
    $organization: String
    $tableSortKey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelwildmetricCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByOrganization(
      organization: $organization
      tableSortKey: $tableSortKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tablePartitionKey
        tableSortKey
        displayName
        organizationRole
        integrationSource
        integrationTag
        details
        worklogAuthor
        initiator
        initiatorGroupMembers
        timespent
        timestamp
        project
        workchunk
        title
        settings
        access
        filters {
          name
          value
          __typename
        }
        filterName
        enabled
        description
        integrationStatus
        dataSource
        organization
        worktime
        userSub
        allUsers
        atlassianId
        status
        createdAt
        updatedAt
        manualAtlassianId
        seats
        paidSeats
        tier
        monthlyFee
        billingEnd
        ttl
        stripePriceId
        stripeCustomerId
        planLimits
        planPrice
        secondaryAccounts
        primaryAccount
        customerData
        invoiceNumber
        invoiceDates
        invoiceLines
        invoiceTotals
        jiraAccessToken
        jiraRefreshToken
        userEmail
        __typename
      }
      nextToken
      __typename
    }
  }
`
