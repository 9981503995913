<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
    <path
      :fill="color"
      d="M294.6 166.6L317.3 144 272 98.7l-22.6 22.6L160 210.7 70.6 121.4 48 98.7 2.7 144l22.6 22.6L114.7 256 25.4 345.4 2.7 368 48 413.3l22.6-22.6L160 301.3l89.4 89.4L272 413.3 317.3 368l-22.6-22.6L205.3 256l89.4-89.4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'XmarkIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
