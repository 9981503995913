import { Auth } from 'aws-amplify'
import store from '../store'
import moduleConstructors from './dynamicOrgConstructur'

const loadOrg = async from => {
  await store.dispatch('organization/joined')
  const orgs = store.getters['organization/joined'].filter(
    org => org.status !== 'disabled',
  )

  if (orgs?.length === 0) return 'onboard'

  let defaultOrganizationId =
    store.getters['profile/user']['custom:default_org']
  let defaultOrg = orgs.find(
    org => org.tablePartitionKey?.split('#')?.[1] === defaultOrganizationId,
  )

  if (!defaultOrg) {
    defaultOrganizationId = orgs[0]?.tablePartitionKey?.split('#')?.[1]
    await store.dispatch('profile/updateDefaultOrg', defaultOrganizationId)
    defaultOrg = orgs.find(
      org => org.tablePartitionKey?.split('#')?.[1] === defaultOrganizationId,
    )
  }

  store.commit('organization/setCurrent', defaultOrg)

  const currentOrg = store.getters['organization/current']

  const { displayName, tablePartitionKey, tableSortKey, slug, settings } =
    currentOrg
  const wantedOrg = tablePartitionKey.split('#')[1]
  const orgExists = store.hasModule(['organization', `org#${wantedOrg}`])

  store.dispatch('organization/tags/getTags', wantedOrg)

  if (!orgExists)
    store.registerModule(
      ['organization', `org#${wantedOrg}`],
      moduleConstructors.singleOrgModule({
        displayName,
        tablePartitionKey,
        tableSortKey,
        slug,
        mainTab: settings?.mainTab,
        activeTabs: settings?.activeTabs,
      }),
    )

  const groups = store.getters[`organization/org#${wantedOrg}/groups`]
  const users = store.getters[`organization/org#${wantedOrg}/users`]

  if (users.length === 0)
    store.dispatch(
      `organization/org#${wantedOrg}/getOrganizationPeople`,
      wantedOrg,
    )

  if (groups.length === 0)
    store.dispatch(`organization/org#${wantedOrg}/getGroups`)

  store.dispatch('reports/filters/setupFilters').then(() => {
    store.dispatch('reports/applyFilters')
  })

  await store.dispatch(`organization/org#${wantedOrg}/getOrgSubscriptionData`)

  if (from.path === '/authorize' && currentOrg.settings) {
    if (currentOrg.organizationRole === 'VIEWER') return `filters`

    const { mainTab } = currentOrg.settings
    let path = mainTab
    if (mainTab === 'reports') path = `/${wantedOrg}/${mainTab}`
    return path
  }

  return true
}

// Handle slug param in url
const slugHandler = async orgId => {
  if (!orgId) return false
  const orgs = store.getters['organization/joined']

  const currentOrg = orgs.find(
    org => org.tablePartitionKey.split('#')[1] === orgId,
  )

  if (currentOrg !== store.getters['organization/current']) {
    store.commit('organization/setCurrent', currentOrg)
  }

  const orgExists = store.hasModule(['organization', `org#${orgId}`])
  const { displayName, tablePartitionKey, tableSortKey, slug, settings } =
    store.getters['organization/singleJoined'](orgId)

  store.dispatch('organization/tags/getTags', orgId)

  if (!orgExists) {
    // Create dynamic module for the organization
    store.registerModule(
      ['organization', `org#${orgId}`],
      moduleConstructors.singleOrgModule({
        displayName,
        tablePartitionKey,
        tableSortKey,
        slug,
        mainTab: settings.mainTab,
        activeTabs: settings.activeTabs,
      }),
    )
  }

  const users = store.getters[`organization/org#${orgId}/users`]
  const groups = store.getters[`organization/org#${orgId}/groups`]

  store.dispatch('integrations/getAll')
  if (users.length === 0)
    store.dispatch(`organization/org#${orgId}/getOrganizationPeople`, orgId)

  if (groups.length === 0) store.dispatch(`organization/org#${orgId}/getGroups`)

  return currentOrg
}

const getSubscriptionData = async tablePartitionKey => {
  const orgId = tablePartitionKey.split('#')[1]

  await store.dispatch(`organization/org#${orgId}/getOrgSubscriptionData`)

  const subscription = store.getters[`organization/org#${orgId}/subscription`]

  if (!subscription) return { route: 'subscription-plan' }
  return { subscription }
}

export const authUser = async to => {
  const required = to.matched.some(record => record.meta.requiresAuth)
  const response = await Auth.currentAuthenticatedUser()
    .then(data => {
      return { data, isLogged: true }
    })
    .catch(() => {
      let data
      if (required)
        data = {
          path: '/authorize',
          query: { returnUrl: to.path },
        }
      return { data, isLogged: false }
    })

  store.state.loggedInUser = response.isLogged
  return response
}

export const setupUser = async data => {
  store.commit('profile/setUserData', data)

  // Check if the user has a default timezone attribute and set the current timezone as default if theres isn't one
  if (!data.attributes['custom:timezone'])
    store.dispatch(
      'profile/saveTimezone',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    )

  // get all notifications if not present
  if (store.getters['notifications/allNotifications'].length === 0) {
    store.dispatch('notifications/loadNotifications')
  }
}

export const setupOrganizations = async (to, from) => {
  const { orgId } = to.params
  if (!store.getters['organization/current']) {
    // If org is not selected, i.e. only executes the first time a new tab is loaded, set to correct filter/org from query params
    const orgResponse = await loadOrg(from)
    if (orgResponse) return orgResponse
  }

  let currentOrg = store.getters['organization/current']
  if (orgId) currentOrg = (await slugHandler(orgId)) || currentOrg
  // Fetching subscription and returning it
  const { subscription, route } = await getSubscriptionData(
    currentOrg?.tablePartitionKey,
  )

  if (route) return route

  if (subscription?.status === 'unpaid') {
    let subResponse = false
    switch (to.name) {
      case 'profile':
      case 'notifications':
      case 'subscriptions':
      case 'organizations':
      case 'plans':
        break
      default:
        subResponse = 'subscriptions'
        break
    }
    return subResponse
  }

  if (from.path === '/authorize' && currentOrg.settings) {
    if (currentOrg.organizationRole === 'VIEWER') return 'filters'

    return currentOrg.settings.mainTab
  }

  return false
}

export const setTitle = metaTitle => {
  let title = process.env.VUE_APP_TITLE
  if (metaTitle) title = `${title} - ${metaTitle}`
  document.title = title
}
