var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalLayout',{attrs:{"id":"ChangePasswordModal"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Change Password")]},proxy:true},{key:"body",fn:function(){return [_c('form',{staticClass:"pass-change"},[_c('div',{staticClass:"form-group my-3"},[_c('input',{attrs:{"hidden":"","type":"text","autocomplete":"username","value":"username"}}),_c('label',{staticClass:"mb-2",attrs:{"for":"curr-pass"}},[_vm._v(" Current Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.current_pass),expression:"current_pass"},{name:"debounce",rawName:"v-debounce:500ms",value:(() => _vm.$emit('input', _vm.current_pass)),expression:"() => $emit('input', current_pass)",arg:"500ms"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.v$.current_pass.$invalid ||
                (_vm.error && _vm.errorType === 'wrong-pass'),
            },attrs:{"data-test":"curr-pass","å":"","type":"password","placeholder":"Password","autocomplete":"current-password"},domProps:{"value":(_vm.current_pass)},on:{"input":function($event){if($event.target.composing)return;_vm.current_pass=$event.target.value}}}),(_vm.v$.current_pass.required.$invalid)?_c('small',{staticClass:"text-danger mt-2 ms-2"},[_vm._v(" This field is required! ")]):_vm._e()]),_c('div',{staticClass:"form-group my-3"},[_c('label',{staticClass:"mb-2",attrs:{"for":"new-pass"}},[_vm._v(" New Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_pass),expression:"new_pass"},{name:"debounce",rawName:"v-debounce:500ms",value:(() => _vm.$emit('input', _vm.new_pass)),expression:"() => $emit('input', new_pass)",arg:"500ms"}],staticClass:"form-control",class:{
              'is-invalid': _vm.v$.new_pass.$invalid,
            },attrs:{"data-test":"new-pass","type":"password","placeholder":"Password","autocomplete":"new-password"},domProps:{"value":(_vm.new_pass)},on:{"input":function($event){if($event.target.composing)return;_vm.new_pass=$event.target.value}}}),(_vm.v$.new_pass.required.$invalid)?_c('small',{staticClass:"text-danger mt-2 ms-2"},[_vm._v(" This field is required! ")]):(
              _vm.v$.new_pass.minLength.$invalid || _vm.v$.new_pass.maxLength.$invalid
            )?_c('small',{staticClass:"text-danger mt-2 ms-2"},[_vm._v(" Password must be between 6 and 256 characters! ")]):(_vm.v$.new_pass.cognitoPass.$invalid)?_c('small',{staticClass:"text-danger mt-2 ms-2"},[_vm._v(" Password needs to have at least: "),_c('ul',[_c('li',[_vm._v("1 Number,")]),_c('li',[_vm._v("1 Lowercase letter")]),_c('li',[_vm._v("1 Uppercase letter")]),_c('li',[_vm._v("1 Special Symbol")])])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-2",attrs:{"for":"conf-pass"}},[_vm._v(" Confirm Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.conf_pass),expression:"conf_pass"},{name:"debounce",rawName:"v-debounce:500ms",value:(() => _vm.$emit('input', _vm.conf_pass)),expression:"() => $emit('input', conf_pass)",arg:"500ms"}],staticClass:"form-control",class:{
              'is-invalid': _vm.v$.conf_pass.$invalid,
            },attrs:{"data-test":"conf-pass","type":"password","placeholder":"Password","autocomplete":"new-password"},domProps:{"value":(_vm.conf_pass)},on:{"input":function($event){if($event.target.composing)return;_vm.conf_pass=$event.target.value}}}),(_vm.v$.conf_pass.required.$invalid)?_c('small',{staticClass:"text-danger mt-2 ms-2"},[_vm._v(" This field is required! ")]):(_vm.v$.conf_pass.samePass.$invalid)?_c('small',{staticClass:"text-danger mt-2 ms-2"},[_vm._v(" Must match the new password. ")]):_vm._e()]),_c('div',{staticClass:"form-row col-sm-5"})])]},proxy:true},{key:"footer",fn:function(){return [(_vm.error && _vm.errorType === 'wrong-pass')?_c('div',{staticClass:"invalid-feedback ms-1"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-custom",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"data-test":"submit-reset-pass","type":"submit","disabled":_vm.v$.$invalid},on:{"click":_vm.updatePass}},[_vm._v(" Change password ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }