<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      :fill="color"
      d="M.02,12.06C.02,10.75,0,9.44,.02,8.13-.03,5.92,1.73,4.09,3.94,4.04c.08,0,.15,0,.23,0,2.23-.01,4.45,0,6.68,0,1.6,0,2.3,.7,2.31,2.28,.02,.22,.02,.45,0,.67-.12,.84,.21,1.11,1.06,1.09,1.03-.05,1.9,.74,1.95,1.76,0,.09,0,.18,0,.27,.1,1.01-.64,1.92-1.65,2.02-.1,0-.19,.01-.29,0-.96-.04-1.11,.37-1.07,1.18,.05,1.03,0,2.06,.02,3.09,.01,.58-.23,.8-.8,.78-.86-.03-1.73-.02-2.59,0-.53,.01-.76-.2-.75-.74,.04-.58,0-1.17-.1-1.75-.2-1.02-1.18-1.69-2.21-1.49-.76,.15-1.35,.74-1.49,1.49-.11,.58-.14,1.16-.1,1.75,0,.54-.22,.75-.75,.74-1.17-.02-2.34-.03-3.51,0-.64,.02-.9-.21-.88-.87,.03-1.42,0-2.84,0-4.26M24.7,.03C25.87,.03,27.04,.02,28.2,.03c2.17,0,3.94,1.74,3.98,3.91,.04,2.39,.02,4.79,0,7.18,.08,1.05-.7,1.96-1.75,2.04-.1,0-.19,0-.29,0-.64,.03-1.28,.03-1.92,0-.77-.06-1.09,.16-1.07,1,0,1.13-.92,2.04-2.05,2.03-1.13,0-2.04-.92-2.03-2.05,.01-.77-.23-1.05-1-.99-.75,.06-1.5,0-2.25,.02-.51,.01-.79-.15-.78-.71,.02-.92,.01-1.84,0-2.76,0-.46,.21-.66,.66-.65,.47,0,.95,.02,1.42-.02,1.07-.07,1.89-.99,1.82-2.06-.06-.98-.84-1.76-1.83-1.82-.08,0-.17,0-.25,0-1.82-.05-1.76-.06-1.83-1.92-.1-2.56,.51-3.37,3.24-3.21,.8,.05,1.61,0,2.42,0M6.67,19.09c1.84,0,3.67,.03,5.51-.02,.79-.02,1.03,.28,.99,1.02-.05,.78-.03,1.56,0,2.34,.02,.58-.24,.8-.8,.77-.42-.02-.84-.02-1.25,0-1.06,0-1.92,.85-1.93,1.91h0c-.02,1.08,.84,1.97,1.91,1.99,.01,0,.03,0,.04,0,.63,.05,1.42-.29,1.84,.19,.45,.52,.14,1.34,.17,2.02,.03,.72,0,1.45,0,2.17,0,.47-.15,.73-.67,.73-2.95-.01-5.9,.05-8.85-.03-1.98-.14-3.54-1.76-3.6-3.75-.05-2.87,0-5.74-.03-8.61,0-.6,.27-.76,.82-.76,1.95,.02,3.9,0,5.84,0m8.38,6.54c0-1.89,.02-3.79-.01-5.68-.01-.65,.23-.9,.88-.87,.81,.04,1.61,.03,2.42,0,.6-.02,.86,.2,.84,.82-.05,.56-.02,1.12,.09,1.66,.19,1.02,1.18,1.69,2.2,1.5,.76-.14,1.35-.73,1.5-1.49,.11-.6,.14-1.22,.1-1.83,0-.45,.19-.66,.65-.66,.89,.01,1.78-.03,2.67,.01,.97,.02,1.76,.8,1.78,1.77,.04,2.59,.08,5.18-.02,7.77-.17,1.98-1.79,3.52-3.78,3.58-2.84,.04-5.68,0-8.51,.02-.62,0-.82-.25-.82-.84,.02-1.92,0-3.84,0-5.76"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppsIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
