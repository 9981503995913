import Vue from 'vue' // Framework
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './i18n' // i18n
import vueDebounce from 'vue-debounce'
import { Amplify, API } from 'aws-amplify'
import awsconfig from './aws-exports'
import store from './store' // Vuex Store
import router from './router' // The Router
import App from './App.vue' // Main file

Amplify.configure(awsconfig)
API.configure(awsconfig)

Vue.prototype.$Amplify = Amplify
Vue.config.productionTip = false

Vue.use(vueDebounce)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
