<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.25 18.25">
    <path
      :fill="color"
      d="M2.26,4.51h0C1.01,4.51,0,3.5,0,2.26S1.01,0,2.26,0s2.26,1.01,2.26,2.26h0c0,1.25-1.01,2.26-2.26,2.26m13.73,0h0c-1.25,0-2.26-1.01-2.26-2.26S14.74,0,15.99,0s2.26,1.01,2.26,2.26h0c0,1.25-1.01,2.26-2.26,2.26m-6.87,0h0c-1.25,0-2.26-1.01-2.26-2.26S7.88,0,9.12,0s2.26,1.01,2.26,2.26-1.01,2.26-2.26,2.26h0M2.26,11.38h0c-1.25,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26-1.01,2.26-2.26,2.26h0m13.73,0h0c-1.25,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26c1.25,0,2.26,1.01,2.26,2.26h0c0,1.25-1.01,2.26-2.26,2.26m-6.87,0h0c-1.25,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26-1.01,2.26-2.26,2.26h0m-6.87,6.87h0C1.01,18.25,0,17.23,0,15.99s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26h0c0,1.25-1.01,2.26-2.26,2.26m13.73,0h0c-1.25,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26h0c0,1.25-1.01,2.26-2.26,2.26m-6.87,0h0c-1.25,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26h0c0,1.25-1.01,2.26-2.26,2.26"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppdrawerIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
