<template>
  <svg viewBox="0 0 55 60" xmlns="http://www.w3.org/2000/svg">
    <title>Office365-Calendar</title>
    <path
      d="M28,13h14.533C43.343,13,44,13.657,44,14.467v19.066C44,34.343,43.343,35,42.533,35H28V13z"
      fill="#1976d2"
    />
    <rect width="14" height="15.542" x="28" y="17.958" fill="#fff" />
    <polygon fill="#1976d2" points="27,44 4,39.5 4,8.5 27,4" />
    <path
      fill="#fff"
      d="M15.25,16.5c-3.176,0-5.75,3.358-5.75,7.5s2.574,7.5,5.75,7.5S21,28.142,21,24	S18.426,16.5,15.25,16.5z M15,28.5c-1.657,0-3-2.015-3-4.5s1.343-4.5,3-4.5s3,2.015,3,4.5S16.657,28.5,15,28.5z"
    />
    <rect width="2.7" height="2.9" x="28.047" y="29.737" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="29.737" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="29.737" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="28.047" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="38.25" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="28.047" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="38.25" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="19.112" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="19.112" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="38.25" y="19.112" fill="#1976d2" />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
  props: {
    color: {
      type: [String],
      default: '#1976d2',
    },
  },
}
</script>
