<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
    <path
      :fill="color"
      d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM64 480c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40z"
    />
  </svg>
</template>

<script>
export default {
  name: 'XmarkIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
