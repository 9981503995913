<template>
  <div :class="`alert alert-${type} d-flex align-items-center`" role="alert">
    <div>{{ message }}</div>
    <button
      v-if="closable"
      type="button"
      class="btn-close ms-2"
      aria-label="Close"
      ata-bs-dismiss="alert"
    ></button>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: String,
    message: String,
    id: String,
    closable: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: 4000,
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit('notifications/alerts/removeOldestAlert')
    }, this.time)
  },
}
</script>
