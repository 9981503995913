/* eslint-disable guard-for-in */
import { API } from 'aws-amplify'
import {
  recordsByTablePartitionKey,
  getIntegrationData,
  getCustomFilters,
} from '../../../graphql/queries'
import { addCustomFilter, removeCustomFilter } from '../../../graphql/mutations'
import Filters from '../../../filters'

export default {
  namespaced: true,
  state: {
    query: new URLSearchParams(),
    filters: new Filters(), // Set Defaults filters from helper
    backward: [],
    forward: [],
  },
  actions: {
    async setupFilters({ commit, dispatch, rootGetters }) {
      await commit('setDefaultFilters')
      await commit(
        'setDateRangeTimeZone',
        rootGetters['profile/user']['custom:timezone'],
      )

      await commit('applyParams')
      await dispatch('pullIntegrationsType')
      await dispatch('pullCustomFilters')
      // Pull the initiator groups for the organization
      await dispatch('getMembers')
      await dispatch('getGroups')
      await dispatch('getProjects', {
        pageToken: null,
      })
    },
    setFilterValue({ commit }, { key, value }) {
      const filterSetters = {
        dateRange() {
          commit('setFilterValue', {
            key: 'dateRange',
            value,
          })
        },
        members() {
          commit('setFilterValue', { key: 'members', value })
        },
        emails() {
          commit('setFilterValue', { key: 'emails', value })
        },
        projects() {
          commit('setFilterValue', { key: 'projects', value })
        },
        integrations() {
          commit('setFilterValue', { key: 'integrations', value })
        },
        groups() {
          commit('setFilterValue', {
            key: 'groups',
            value,
          })
        },
        search() {
          commit('setFilterValue', {
            key: 'search',
            value,
          })
        },
        tags() {
          commit('setFilterValue', { key: 'tags', value })
        },
      }
      filterSetters[key]?.()
    },
    /*
     * Based on the pulled organization members, this method pulls their integrations and creates a unique list with the different integration types
     */
    pullIntegrationsType({ rootGetters, commit }) {
      const runQuery = pageToken => {
        return API.graphql({
          query: recordsByTablePartitionKey,
          variables: {
            tablePartitionKey:
              rootGetters['organization/current'].tablePartitionKey,
            tableSortKey: {
              beginsWith: 'integrationSettings#',
            },
            nextToken: pageToken,
          },
        }).then(async response => {
          // Recursion for the pagination
          if (response.data.recordsByTablePartitionKey.nextToken !== null) {
            await runQuery(response.data.recordsByTablePartitionKey.nextToken)
          }
          response.data.recordsByTablePartitionKey.items.forEach(
            integration => {
              const integrationType = integration.tableSortKey.split('#')[2]
              commit('addFilterOption', {
                key: 'integrations',
                value: integrationType,
              })
            },
          )
        })
      }

      return runQuery(null)
    },
    // Pull the groups for the organization the user is in
    // TODO Implement pagination
    async getGroups({ rootGetters, commit, dispatch }) {
      commit('clearFilterOptions', 'groups')
      const org =
        rootGetters['organization/current'].tablePartitionKey.split('#')[1]
      const groups = rootGetters[`organization/org#${org}/groups`]
      groups.forEach(group => dispatch('addGroup', group))
    },
    addGroup({ commit }, group) {
      const filterGroup = {
        initiatorGroupName: group.name,
        initiatorGroupMembers: group.members,
      }
      commit('addFilterOption', { key: 'groups', value: filterGroup })
    },
    async getMembers({ rootGetters, dispatch }) {
      const org =
        rootGetters['organization/current'].tablePartitionKey.split('#')[1]
      const members = rootGetters[`organization/org#${org}/users`]
      members.forEach(member => dispatch('addMember', member))
    },
    addMember({ commit }, member) {
      const filterPersonObject = {}
      filterPersonObject.name = `${member.given_name} ${
        member.family_name ? member.family_name : 'Null'
      }`

      filterPersonObject.email = member.email
      filterPersonObject.primaryAccount = member.primaryAccount
      filterPersonObject.secondaryAccounts = member.secondaryAccounts
      filterPersonObject.sub = member.sub
      filterPersonObject.name = `${member.given_name} ${member.family_name}`

      commit(
        'reports/filters/addFilterOption',
        {
          key: 'emails',
          value: filterPersonObject,
        },
        { root: true },
      )

      commit(
        'reports/filters/addFilterOption',
        {
          key: 'members',
          value: filterPersonObject,
        },
        { root: true },
      )
    },
    async pullCustomFilters({ dispatch, rootGetters }) {
      await API.graphql({
        query: getCustomFilters,
        variables: {
          organizationID:
            rootGetters['organization/current'].tablePartitionKey.split('#')[1],
          userSub: rootGetters['profile/user'].sub,
        },
      }).then(async response => {
        await dispatch(
          'setCustomFiltersOptions',
          response.data.getCustomFilters.items,
        )
      })
    },
    setCustomFiltersOptions({ commit }, items) {
      items.forEach(item => {
        const settings = JSON.parse(item.settings)
        const obj = { ...item }
        obj.settings = settings
        commit('addFilterOption', { key: 'custom', value: obj })
      })
    },
    /*
     * Pulls all projects of the currently selected organization
     *  first it pulls all the logs in the organization
     *  second it uses the granulated data by projects to get the projects
     *  third it stores the projects options
     */
    async getProjects({ rootGetters, dispatch }, payload) {
      await API.graphql({
        query: getIntegrationData,
        variables: {
          organizationID:
            rootGetters['organization/current'].tablePartitionKey.split('#')[1],
          filter: {},
          timezone: rootGetters['profile/user']['custom:timezone'],
          granularity: 'None',
          groupBy: 'Projects',
          nextToken: payload.pageToken,
        },
      }).then(async response => {
        if (response.data.getIntegrationData.aggregations !== null) {
          const parsedAggregations = JSON.parse(
            response.data.getIntegrationData.aggregations,
          )
          await dispatch(
            'addProjectsFilterValues',
            parsedAggregations.data.buckets,
          )
        }
        // Pagination for workbits
        if (
          response.data.getIntegrationData.nextToken !== null &&
          response.data.getIntegrationData.items.length === 1000
        ) {
          await dispatch('getProjects', {
            pageToken: response.data.getIntegrationData.nextToken,
          })
        }
      })
    },
    addProjectsFilterValues({ commit }, items) {
      items.forEach(item => {
        if ('key' in item) {
          commit('addFilterOption', { key: 'projects', value: item.key })
        }
      })
    },
    getOSFilters({ state }) {
      return state.filters.parseToOS()
    },
    async addCustomFilter(
      { commit, rootGetters },
      { name, filters, access = 'private' },
    ) {
      await API.graphql({
        query: addCustomFilter,
        variables: {
          input: {
            organizationID:
              rootGetters['organization/current'].tablePartitionKey.split(
                '#',
              )[1],
            owner: rootGetters['profile/user'].sub,
            name,
            filters,
            access,
          },
        },
      }).then(response => {
        commit('addFilterOption', {
          key: 'custom',
          value: response.data.addCustomFilter,
        })
      })
    },
    async removeCustomFilter({ commit, rootGetters }, { name, owner }) {
      await API.graphql({
        query: removeCustomFilter,
        variables: {
          input: {
            organizationID:
              rootGetters['organization/current'].tablePartitionKey.split(
                '#',
              )[1],
            owner,
            name,
          },
        },
      }).then(response => {
        commit(
          'removeCustomFilterOption',
          response.data.removeCustomFilter.name,
        )
      })
    },
  },
  mutations: {
    setCurrentQueries(state) {
      state.filters.applyQueryParams(
        new URLSearchParams(window.location.search),
      )
    },
    setDateRangeTimeZone(state, timezone) {
      const { start, end } = state.filters.list.dateRange
      state.filters.setFilterValue('dateRange', { start, end, timezone }, false)
    },
    setDefaultFilters(state) {
      state.filters.clearFilters()
    },
    applyParams(state) {
      state.filters.applyQueryParams()
    },
    setFilterValue(state, { key, value }) {
      state.filters.setFilterValue(key, value)
    },
    addFilterValue(state, { key, value }) {
      state.filters.addFilterValue(key, value)
    },
    setFilterValueByProperty(state, { key, name, value }) {
      state.filters.setFilterValueByProperty(key, name, value)
    },
    addFilterOption(state, { key, value }) {
      state.filters.addFilterOption(key, value)
    },
    removeCustomFilterOption(state, value) {
      state.filters.removeCustomFilterOption(value)
    },
    applyCustomFilter(state, filters) {
      state.filters.applyCustomFilter(filters)
    },
    clearCustom(state) {
      state.filters.clearFiltersValue({ exclude: ['dateRange', 'custom'] })
    },
    clearFilterValue(state, key) {
      state.filters.clearFilterValue(key)
    },
    clearFilterOptions(state, key) {
      state.filters.clearFilterOptions(key)
    },
    setIssue(state, value) {
      state.filters.issue = value
    },
    setTimespent(state, value) {
      state.filters.timespent = value
    },
    addToBackState(state, value) {
      state.backward.push(value)
    },
    removeFromBackState(state) {
      state.backward.pop()
    },
    addToForwardState(state, value) {
      state.forward.push(value)
    },
    removeFromForwardState(state) {
      state.forward.pop()
    },
    resetBackwardState(state) {
      state.backward = []
    },
    resetForwardState(state) {
      state.forward = []
    },
  },
  getters: {
    membersValue: state => state.filters.list.members.value,
    membersOptions: state =>
      state.filters.list.members ? state.filters.list.members.options : '',

    emailsValue: state => state.filters.list.emails.value,
    emailsOptions: state =>
      state.filters.list.emails ? state.filters.list.emails.options : '',
    allMembersFlag: state => !state.filters.list.members.value.length,
    dateRangeValue: state => state.filters.list.dateRange,
    containsTextValue: state => state.filters.list.search.text,
    projectsValue: state => state.filters.list.projects.value,
    projectsOptions: state => state.filters.list.projects.options,
    allProjectsFlag: state => !state.filters.list.projects.value.length,
    integrationsValue: state => state.filters.list.integrations.value,
    integrationsOptions: state => state.filters.list.integrations.options,
    allIntegrationsFlag: state => !state.filters.list.integrations.value.length,
    initiatorGroupsOptions: state => state.filters.list.groups.options,
    customFiltersValue: state => state.filters.list.custom.value,
    customFiltersOptions: state => state.filters.list.custom.options,
    initiatorGroupsValue: state => state.filters.list.groups.value,
    allInitiatorGroupsFlag: state => !state.filters.list.groups.value.length,
    getTagsConditions: state => state.filters.list.tags,
    selectedOption: state => optionName => {
      return state.filters.list.options[optionName]
    },
    options: state => state.filters.list.options.optionConfig,
    getQueryParamsArray: state => state.filters.parseToQueryArray(),
    backward: state => state.backward,
    forward: state => state.forward,
  },
}
