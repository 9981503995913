const alerts = {
  namespaced: true,
  state: () => ({
    alerts: [],
  }),
  mutations: {
    addAlert(state, value) {
      state.alerts.unshift(value)
    },
    removeSpecificAlert(state, value) {
      state.alerts = state.alerts.filter(item => item.id !== value)
    },
    removeOldestAlert(state) {
      state.alerts.pop()
    },
  },
  actions: {
    appendAlert({ commit }, alert) {
      const id = `id-${new Date().getTime()}`
      const newAlert = {
        id,
        type: alert.type,
        msg: alert.msg,
      }

      commit('notifications/alerts/addAlert', newAlert, { root: true })
    },
  },
  getters: {
    alerts: state => state.alerts,
  },
}

export default alerts
