<template>
  <ModalLayout @opened="resetValues" id="addCustomFilter" ref="modal">
    <template v-slot:title>Add Custom Filter</template>
    <template v-slot:body>
      <div class="form-group mb-3">
        <label class="mb-2">Filter Name</label>
        <input
          v-model="filterName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': v$.filterName.$invalid }"
          autocomplete="new-password"
          placeholder="Filter name..."
        />
        <div v-show="v$.filterName.$invalid" class="invalid-feedback">
          Please choose a unique and valid filter name.
        </div>
      </div>
      <div class="d-flex align-items-center">
        <input
          id="is-advanced"
          class="me-2"
          v-model="isAdvanced"
          type="checkbox"
        />
        <label for="is-advanced">Advanced options</label>
      </div>
      <div v-if="isAdvanced" class="form-group my-3">
        <label class="mb-2">Selected filters</label>
        <Multiselect
          v-model="advanced.included"
          mode="multiple"
          :options="filtersToSave"
          placeholder="Select filters to save"
          :class="{ 'is-invalid': v$.advanced.included.$invalid }"
          :hideSelected="false"
          :closeOnSelect="false"
          :canClear="false"
          :multipleLabel="
            value =>
              `${value
                .slice(0, 2)
                .map(filter =>
                  filter.value.replace(/[A-Z]/g, m => ` ${m.toLowerCase()}`),
                )
                .join(', ')}${value.length > 2 ? '...' : ''}`
          "
        />
        <div v-show="v$.advanced.included.$invalid" class="invalid-feedback">
          Please select filters.
        </div>
      </div>
      <div v-else class="py-5"></div>
    </template>
    <template v-slot:footer>
      <div
        class="
          d-flex
          justify-content-between
          w-100
          same-width
          align-items-center
        "
      >
        <button
          @click="addFilter"
          class="btn btn-primary w-100"
          :disabled="v$.filterName.$invalid"
        >
          Add Filter
        </button>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { Modal } from 'bootstrap'
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, maxLength } from '@vuelidate/validators'
import ModalLayout from './ModalLayout.vue'

const alphaNumSymbols = helpers.regex(/^[ А-Яа-яA-Za-z0-9_./&+-]*$/)
const noSpaces = helpers.regex(/^\S+$/)

export default {
  name: 'AddCustomFilter',
  components: {
    ModalLayout,
    Multiselect,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      filterName: '',
      modal: null,
      filterExists: false,
      isAdvanced: false,
      advanced: {
        included: [],
      },
    }
  },
  validations: {
    filterName: {
      required,
      alphaNumSymbols,
      noSpaces,
      maxLength: maxLength(20),
      exists() {
        return !this.filterExists
      },
    },
    advanced: {
      included: {
        required,
      },
    },
  },
  watch: {
    filterName(name) {
      this.filterExists = this.filters.some(filter => filter.name === name)
    },
    isAdvanced() {
      this.advanced.included = this.filtersToSave.map(filter => filter.value)
    },
  },
  computed: {
    filters() {
      return this.$store.getters['reports/filters/customFiltersOptions']
    },
    filtersToSave() {
      return this.$store.getters['reports/filters/getQueryParamsArray'].map(
        filter => ({
          value: filter.name,
          label: filter.name.replace(/[A-Z]/g, m => ` ${m.toLowerCase()}`),
        }),
      )
    },
  },
  mounted() {
    this.modal = Modal.getOrCreateInstance(this.$refs.modal.$el)
  },
  methods: {
    resetValues() {
      this.isAdvanced = false
    },
    addFilter() {
      let filters = this.$store.getters['reports/filters/getQueryParamsArray']

      if (this.isAdvanced)
        filters = filters.filter(filter =>
          this.advanced.included.includes(filter.name),
        )
      const customFilter = {
        name: this.filterName,
        filters,
        access: 'private',
      }
      this.$store
        .dispatch('reports/filters/addCustomFilter', customFilter)
        .then(() => {
          this.modal.hide()
          const currentName = this.filterName
          this.filterName = ''
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: `success`,
            msg: `Success added custom filter - ${currentName}`,
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped></style>
<style src="@vueform/multiselect/themes/default.css"></style>
