<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Sidebar -->
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav
          class="
            navbar navbar-expand navbar-light
            bg-white
            topbar
            mb-0
            static-top
            border-bottom
          "
        >
          <!-- Sidebar Toggle (Topbar) -->
          <button
            id="sidebarToggleTop"
            class="btn btn-link d-md-none rounded-circle me-3"
            @click="$store.commit('toggleNavBar')"
          >
            <BaseIcon name="bars" />
          </button>
          <!-- <Search v-if="$route.name == 'integrations'" /> -->
          <!-- Topbar Navbar -->
          <NavbarShared v-if="role === 'VIEWER'" />
          <Navbar v-else />
        </nav>
        <div class="custom-banner" v-if="showBanner">
          Payment for your organization is overdue! We haven't been able to
          charge your credit card.
          <router-link to="subscriptions">
            Please update your billing information.
          </router-link>
          (We'll have to suspend your organization if you go overdue for more
          than 7 days)
        </div>
        <!-- End of Topbar -->
        <div id="main_content" class="row px-4">
          <!-- Begin Page Content -->
          <router-view />
        </div>
      </div>
      <!-- End of Main Content -->
    </div>
    <!-- End of Content Wrapper -->
    <LogoutModal />
    <ConfirmEmail />
    <JoinOrganization />
    <AddCustomFilter />
    <DeleteAccount />
    <DeleteOrganization />
    <ChangePassword />
    <AddMember />
    <AddAtlassianId />
    <div class="alert-container">
      <Alert
        v-for="alert in alerts"
        :key="alert.id"
        :type="alert.type"
        :message="alert.msg"
        :id="alert.id"
      />
    </div>
  </div>
  <!-- End of Page Wrapper -->
</template>

<script>
import Navbar from './elements/Navbar.vue'
import NavbarShared from './elements/NavbarShared.vue'
import LogoutModal from '../components/common/modals/Logout.vue'
import ChangePassword from '../components/common/modals/ChangePassword.vue'
import AddMember from '../components/common/modals/AddMember.vue'
import ConfirmEmail from '../components/common/modals/ConfirmEmail.vue'
import JoinOrganization from '../components/common/modals/JoinOrganization.vue'
import AddCustomFilter from '../components/common/modals/AddCustomFilter.vue'
import DeleteAccount from '../components/common/modals/DeleteAccount.vue'
import DeleteOrganization from '../components/common/modals/DeleteOrganization.vue'
import AddAtlassianId from '../components/common/modals/AddAtlassianId.vue'
import Alert from '../components/common/Alert.vue'
import BaseIcon from '../components/common/icons/BaseIcon.vue'

export default {
  name: 'AuthorizedLayout',
  components: {
    Navbar,
    NavbarShared,
    LogoutModal,
    ChangePassword,
    AddMember,
    AddCustomFilter,
    ConfirmEmail,
    JoinOrganization,
    DeleteAccount,
    DeleteOrganization,
    AddAtlassianId,
    Alert,
    BaseIcon,
  },
  computed: {
    alerts() {
      return this.$store.getters['notifications/alerts/alerts']
    },
    showBanner() {
      const currentOrg = this.$store.getters['organization/current']
      const wantedOrg = currentOrg?.tablePartitionKey?.split('#')[1]
      const subscription =
        this.$store.getters[`organization/org#${wantedOrg}/subscription`]
      if (subscription?.status === undefined) return false
      if (subscription.status !== 'active') return true

      return false
    },
    role() {
      return this.$store.getters['organization/current'].organizationRole
    },
  },
}
</script>

<style lang="scss" scoped>
#content-wrapper {
  min-height: 100vh;
}
</style>
