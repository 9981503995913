import { API, graphqlOperation } from 'aws-amplify'
import { addTag, editTag } from '../../graphql/mutations'
import { getTags } from '../../graphql/queries'

export default {
  namespaced: true,
  state: {
    organizationTags: [],
  },
  mutations: {
    setOrganizationTags(state, value) {
      state.organizationTags = value
    },
    addOrganizationTag(state, value) {
      state.organizationTags.push(value)
    },
  },
  getters: {
    organizationTags: state => state.organizationTags,
  },
  actions: {
    async deleteTag({ dispatch, rootGetters }, tag) {
      const input = {
        organizationID:
          rootGetters['organization/current'].tablePartitionKey.split('#')[1],
        tag,
        newTag: '',
      }
      API.graphql(
        graphqlOperation(editTag, {
          input,
        }),
      ).catch(err => {
        if (err.errors) {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: err.errors[0].message,
            },
            { root: true },
          )
        }
      })
    },
    async createTag({ dispatch, rootGetters }, newTagName) {
      const input = {
        organizationID:
          rootGetters['organization/current'].tablePartitionKey.split('#')[1],
        tag: newTagName,
      }

      API.graphql(
        graphqlOperation(addTag, {
          input,
        }),
      ).catch(err => {
        if (err.errors) {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: err.errors[0].message,
            },
            { root: true },
          )
        }
      })
    },
    async getTags({ commit, dispatch }, organization, nextToken = null) {
      API.graphql(
        graphqlOperation(getTags, {
          organizationID: organization,
          nextToken: nextToken || '',
        }),
      )
        .then(res => {
          const tagList = res.data.getTags.items
          const tags = []

          Object.values(tagList).forEach(tag => {
            const tagObj = {}
            if (tag.tableSortKey.includes('integration')) {
              const name = tag.tableSortKey.split('#')[2]
              tagObj.name = name
              tagObj.type = 'integration'
              tags.push(tagObj)
            } else {
              const name = tag.tableSortKey.split('#')[1]
              tagObj.name = name
              tagObj.type = 'organization'
              tags.push(tagObj)
            }
          })

          commit('setOrganizationTags', tags)
        })
        .catch(err => {
          if (err.errors) {
            dispatch(
              'notifications/alerts/appendAlert',
              {
                type: 'error',
                msg: err.errors[0].message,
              },
              { root: true },
            )
          }
        })
    },
  },
}
