<template>
  <ModalLayout id="logoutModal">
    <template v-slot:title>Logout</template>
    <template v-slot:body>
      Select "Logout" below if you are ready to end your current session.
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Cancel
      </button>
      <button data-bs-dismiss="modal" class="btn btn-primary" @click="logout">
        Logout
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import ModalLayout from './ModalLayout.vue'

export default {
  name: 'LogoutModal',
  components: {
    ModalLayout,
  },
  methods: {
    async logout() {
      await this.$Amplify.Auth.signOut()
        .then(() => {
          const organizations = this.$store.getters['organization/joined']
          organizations.forEach(org => {
            const orgId = org.tablePartitionKey.split('#')[1]
            const moduleName = `org#${orgId}`
            if (this.$store.hasModule(['organization', moduleName]))
              this.$store.unregisterModule(['organization', moduleName])
          })
          this.$store.commit('resetOrganization')
          this.$store.commit('resetIntegration')
          this.$store.commit('profile/setUserData', null)
          this.$router.push({ path: '/authorize' })
        })
        .catch(() => {
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'error',
            msg: 'Something went wrong. Please try again.',
          })
        })
    },
  },
}
</script>
