// TODO Rename initiator groups to groups
/* eslint-disable guard-for-in */
import { API } from 'aws-amplify'
import { getIntegrationData } from '../../graphql/queries'
import filters from './reports/filters'
import ChartHelper from '../../helpers/chartHelper'

export default {
  namespaced: true,
  modules: {
    filters,
  },
  state: {
    tableStatus: false,
    data: {},
    lastPageData: false,
    error: false,
    notFormattedOriginalData: [],
    exportData: [], // for export module
    selectedFilters: {}, // for export module
    exportDataInprogress: false,
  },
  actions: {
    /*
     * Group the returned data by the API into groups based on the user's choice (by default - timestamp)
     * Group the returned data by type - whenever its overtime or not (additional logic required)
     */
    applyFilters({ commit, dispatch }, clearCustom = true) {
      if (clearCustom) commit('filters/clearFilterValue', 'custom')
      dispatch('prepareFiltersAndDataPull')
    },
    groupChartData({ commit, getters }, data) {
      // TODO
      // Adjust this variable in order to define a certain worklog as "overtime"
      // TODO: This variable's value should be based on the user's settings
      // const isOverTime = false
      // const type = isOverTime ? 'overtime' : 'normal'
      // Define the type as empty object before populating
      const series = []

      const groupType = getters['filters/selectedOption']('groupBy')
      const dataName = groupType === 'Date' ? 'x' : 'name'
      const stackType = getters['filters/selectedOption']('stack')
      const memberOptions = getters['filters/membersOptions']
      const link = !getters['filters/emailsValue'].length
      // Build the series
      function getSeries(buckets, name) {
        const seriesData = buckets.map(item => {
          const point = {}
          point[`${dataName}`] = item.key
          if (groupType === 'Users') {
            // If the main account isn't in the organization, display Unknown user
            point[`${dataName}`] = 'Unknown user'
            point[`${dataName}`] = ChartHelper.getWorklogOwnerName(
              item.key,
              memberOptions,
              link,
            )
          }
          point.y = item.timespent.value
          return point
        })
        const currentSeries = {
          name,
          data: seriesData,
        }
        return currentSeries
      }

      if (stackType === 'None') {
        series.push(getSeries(data.data.buckets, 'Time spent'))
      } else if (stackType === 'Groups') {
        let groupsArray = []
        // If there are selected groups group by them
        if (!getters['filters/allInitiatorGroupsFlag']) {
          groupsArray = getters['filters/initiatorGroupsValue']
          // Else group by all the groups
        } else {
          groupsArray = getters['filters/initiatorGroupsOptions']
        }

        const groupsTimeArray = []

        // Creating an assoc array that looks like this groups all user times in the selected groups
        groupsArray.forEach(group => {
          groupsTimeArray[group.initiatorGroupName] = []
          data.data.buckets.forEach(user => {
            if (group.initiatorGroupMembers.includes(user.key)) {
              groupsTimeArray[group.initiatorGroupName].push(user.time.buckets)
            }
          })
        })

        // We create the series array for the charts
        const seriesArr = Object.entries(groupsTimeArray).map(
          ([key, value]) => {
            const finalArray = {}
            const concatArr = []
            // We concat the user times then we sort them. After that we need to sum the timespent during the same day.
            concatArr
              .concat(...value)
              .sort((a, b) => a.key - b.key)
              .forEach(el => {
                if (finalArray[el.key]) {
                  finalArray[el.key].timespent.value += el.timespent.value
                } else {
                  finalArray[el.key] = el
                }
              })
            return getSeries(Object.values(finalArray), key)
          },
        )

        series.push(...seriesArr)
      } else if (
        groupType === 'Groups' &&
        (stackType === 'Projects' ||
          stackType === 'Issues' ||
          stackType === 'Users')
      ) {
        const seriesArr = data.data.buckets.map(item => {
          return getSeries(item.buckets, item.key)
        })
        series.push(...seriesArr)
      } else {
        const seriesArr = data.data.buckets.map(item => {
          return getSeries(item.time.buckets, item.key)
        })
        series.push(...seriesArr)
      }
      commit('setData', series)
    },
    groupChartData2({ rootGetters, commit, getters }, data) {
      // TODO
      // Adjust this variable in order to define a certain worklog as "overtime"
      // TODO: This variable's value should be based on the user's settings
      // const isOverTime = false
      // const type = isOverTime ? 'overtime' : 'normal'
      // Define the type as empty object before populating
      const series = []

      const readerData = rootGetters['profile/readerData']
      const groupType = getters['filters/selectedOption']('groupBy')
      const granularity = getters['filters/selectedOption']('granularity')
      const dataName = groupType === 'Date' ? 'x' : 'name'
      const stackType = getters['filters/selectedOption']('stack')
      const memberOptions = getters['filters/membersOptions']
      const dateRange = getters['filters/dateRangeValue']
      const link = !getters['filters/emailsValue'].length
      const { workSchedule, holidays } =
        rootGetters['organization/current'].settings
      // Build the series
      function getSeries(buckets, name, occurance = []) {
        const seriesData = buckets.map(item => {
          const point = {}
          point[`${dataName}`] = item.key
          if (groupType === 'Users') {
            // If the main account isn't in the organization, display Unknown user
            point[`${dataName}`] = 'Unknown user'
            point[`${dataName}`] = ChartHelper.getWorklogOwnerName(
              item.key,
              memberOptions,
              link,
            )
          }

          let defaultTime = parseInt(readerData.settings.defaultTime, 10)
          const startDate = new Date(item.key_as_string)
          let endDate

          if (granularity === 'Week') {
            endDate = new Date(startDate)

            // Set the endDate to the next Sunday
            endDate.setDate(startDate.getDate() + (7 - startDate.getDay()))
          }
          if (granularity === 'Month') {
            endDate = new Date(
              startDate.getFullYear(),
              startDate.getMonth() + 1,
              0,
            )
            // Set the endDate to the next Sunday
            endDate = new Date(
              startDate.getFullYear(),
              startDate.getMonth() + 1,
              0,
            )
          }
          if (granularity === 'Quarter') {
            endDate = ChartHelper.getQuarterEndDate(startDate)
          }
          if (granularity === 'Year') {
            endDate = new Date(startDate.getFullYear(), 11, 31)
          }

          if (granularity !== 'Day') {
            const workDays = ChartHelper.getWorkdaysInRange(
              startDate,
              endDate,
              workSchedule.workDays,
              holidays,
              dateRange,
            )
            defaultTime *= workDays
          }

          point.y =
            occurance.length !== 0
              ? defaultTime / occurance[item.key]
              : defaultTime

          if (item.timespent.value === 0) point.y = 0
          return point
        })
        const currentSeries = {
          name,
          data: seriesData,
        }
        return currentSeries
      }

      function countOccurrences(arr) {
        const countObj = {}

        arr.forEach(num => {
          countObj[num] = (countObj[num] || 0) + 1
        })

        return countObj
      }

      if (stackType === 'None') {
        series.push(getSeries(data.data.buckets, 'Time spent'))
      } else if (stackType === 'Groups') {
        let groupsArray = []
        // If there are selected groups group by them
        if (!getters['filters/allInitiatorGroupsFlag']) {
          groupsArray = getters['filters/initiatorGroupsValue']
          // Else group by all the groups
        } else {
          groupsArray = getters['filters/initiatorGroupsOptions']
        }

        const groupsTimeArray = []

        // Creating an assoc array that looks like this groups all user times in the selected groups
        groupsArray.forEach(group => {
          groupsTimeArray[group.initiatorGroupName] = []
          data.data.buckets.forEach(user => {
            if (group.initiatorGroupMembers.includes(user.key)) {
              groupsTimeArray[group.initiatorGroupName].push(user.time.buckets)
            }
          })
        })

        // We create the series array for the charts
        const seriesArr = Object.entries(groupsTimeArray).map(
          ([key, value]) => {
            const finalArray = {}
            const concatArr = []
            // We concat the user times then we sort them. After that we need to sum the timespent during the same day.
            concatArr
              .concat(...value)
              .sort((a, b) => a.key - b.key)
              .forEach(el => {
                if (finalArray[el.key]) {
                  finalArray[el.key].timespent.value += el.timespent.value
                } else {
                  finalArray[el.key] = el
                }
              })
            return getSeries(Object.values(finalArray), key)
          },
        )

        series.push(...seriesArr)
      } else if (
        groupType === 'Groups' &&
        (stackType === 'Projects' ||
          stackType === 'Issues' ||
          stackType === 'Users')
      ) {
        const times = []
        data.data.buckets.forEach(item => {
          const keys = item.buckets.map(time => time.key)
          keys.forEach(key => times.push(key))
        })

        const keyObj = countOccurrences(times)
        const seriesArr = data.data.buckets.map(item => {
          return getSeries(item.buckets, item.key, keyObj)
        })
        series.push(...seriesArr)
      } else {
        const times = []
        data.data.buckets.forEach(item => {
          const keys = item.time.buckets.map(time => time.key)
          keys.forEach(key => times.push(key))
        })

        const keyObj = countOccurrences(times)
        const seriesArr = data.data.buckets.map(item => {
          return getSeries(item.time.buckets, item.key, keyObj)
        })
        series.push(...seriesArr)
      }
      commit('setData', series)
    },
    /*
     * Reformats the filters object in order to be valid for the GraphQL query
     * and triggers the data pull functionality
     */
    prepareFiltersAndDataPull({ dispatch, commit, rootGetters }) {
      const current = rootGetters['organization/current']
      dispatch('clearAllStorages').then(async () => {
        const qFilters = await dispatch('filters/getOSFilters')
        // commit qFilters to be used in exports
        commit('setSelectedFilters', qFilters)

        /* Trigger the data pull once the qFilters are formatted in GraphQL standard
         * Pulle the data for the organization and wait for the iterations to complete
         */

        if (current.organizationRole === 'VIEWER') {
          if (!qFilters.and || qFilters.and.length < 2) {
            const reader = rootGetters['profile/readerData']
            qFilters.and = []

            if (!qFilters.and[0]?.or[0].project) {
              const projects = reader.filters.filter(
                item => item.name === 'projects',
              )[0].value
              // Projects filter
              if (projects.length > 0) {
                qFilters.and.push({
                  or: projects.map(projectVal => {
                    return {
                      project: {
                        eq: projectVal,
                      },
                    }
                  }),
                })
              }
            }

            if (!qFilters.and[0]?.or[0].worklogOwner) {
              const members = reader.filters.filter(
                item => item.name === 'members',
              )[0].value

              const users = rootGetters['reports/filters/membersOptions']
                .filter(member => member.primaryAccount === null)
                .filter(item => members.includes(item.name))

              if (users.length > 0) {
                const membersFilter = []

                users.forEach(member => {
                  membersFilter.push({ worklogOwner: { eq: member.sub } })
                  if (member.secondaryAccounts)
                    JSON.parse(member.secondaryAccounts).forEach(el =>
                      membersFilter.push({ worklogOwner: { eq: el } }),
                    )
                })

                qFilters.and.push({ or: membersFilter })
              }
            }
          }

          await dispatch('pullDataReader', {
            filter: qFilters,
            pageToken: null,
          })
        } else {
          await dispatch('pullData', {
            filter: qFilters,
            pageToken: null,
          })
        }

        // load the chart once everything is pulled
        dispatch('loadChart')

        await dispatch('pullTableData', {
          filter: qFilters,
          pageToken: null,
          limit: 1000,
        })
      })
    },
    async pullData({ commit, dispatch, rootGetters, getters }, payload) {
      commit('startExportData')
      setTimeout(() => {
        commit('stopExportData')
      }, 10000)
      // Temp fix TODO refactor const
      const granularity = getters['filters/selectedOption']('granularity')
      const groupBy = getters['filters/selectedOption']('groupBy')
      const stack = getters['filters/selectedOption']('stack')

      const userSub = payload.member ? payload.member.sub : null
      // limit field is null to get aggregate data. Should be set to int if we need to get actual worklogs
      await API.graphql({
        query: getIntegrationData,
        variables: {
          organizationID:
            rootGetters['organization/current'].tablePartitionKey.split('#')[1],
          userSub,
          filter: payload.filter,
          timezone: rootGetters['profile/user']['custom:timezone'],
          limit: payload.limit || null,
          granularity,
          groupBy,
          stack,
          nextToken: payload.pageToken,
        },
      }).then(async response => {
        // If no items are returned, skip the formatation
        const result = response.data.getIntegrationData
        const members = getters['filters/membersOptions']
        const emails = getters['filters/emailsValue']

        commit('clearExportData')

        if (result.items !== null && result.items.length > 0) {
          commit('addExportData', result.items)
        } else if (result.aggregations !== null) {
          const parsedAggregations = JSON.parse(result.aggregations)

          if (emails.length === 0)
            parsedAggregations.data.buckets =
              ChartHelper.mergeSecondaryAccountWorklog(
                members,
                parsedAggregations.data.buckets,
              )

          const groupedBy = getters['filters/selectedOption']('groupBy')

          let groupsArray = []
          // If there are selected groups group by them
          if (!getters['filters/allInitiatorGroupsFlag']) {
            groupsArray = getters['filters/initiatorGroupsValue']
            // Else group by all the groups
          } else {
            groupsArray = getters['filters/initiatorGroupsOptions']
          }

          if (stack === 'Users' && groupedBy !== 'Groups') {
            const usersBucket = []
            parsedAggregations.data.buckets.forEach(bucket => {
              const userData = { ...bucket }
              const member = members.filter(user => user.sub === bucket.key)
              userData.key = member.length > 0 ? member[0].name : bucket.key
              usersBucket.push(userData)
            })
            parsedAggregations.data.buckets = usersBucket
          }

          if (
            stack === 'Groups' ||
            (groupedBy === 'Groups' && stack === 'None') ||
            (groupBy === 'Groups' && stack === 'Users')
          ) {
            const usersBucket = []
            parsedAggregations.data.buckets.forEach(bucket => {
              const userData = { ...bucket }
              const member = members.filter(user => user.sub === bucket.key)
              userData.key = member.length > 0 ? member[0].email : bucket.key

              userData.name = member.length > 0 ? member[0].name : bucket.key
              usersBucket.push(userData)
            })
            parsedAggregations.data.buckets = usersBucket
          }

          // Check if the selected groupBy is Groups and group the parsedAggregations users by groups
          if (groupedBy === 'Groups' && stack === 'None') {
            // Initialize the object which will hold temporarily the groups and their aggregated time
            const aggrGroups = {}
            parsedAggregations.data.buckets.forEach(bucket => {
              groupsArray.forEach(group => {
                if (group.initiatorGroupMembers.includes(bucket.key)) {
                  // Add the users time to the groups total if it's the first one just assign it to the key
                  aggrGroups[group.initiatorGroupName] =
                    aggrGroups[group.initiatorGroupName] +
                      bucket.timespent.value || bucket.timespent.value
                }
              })
            })

            // Replace the old buckets with the aggregated groups
            parsedAggregations.data.buckets = []
            Object.keys(aggrGroups).forEach(groupName => {
              parsedAggregations.data.buckets.push({
                key: groupName,
                timespent: { value: aggrGroups[groupName] },
              })
            })
          }

          if (
            groupedBy === 'Groups' &&
            (stack === 'Projects' || stack === 'Issues')
          ) {
            parsedAggregations.data.buckets.forEach(bucket => {
              bucket.time.buckets.forEach(user => {
                const newKey = members.filter(member => member.sub === user.key)
                // eslint-disable-next-line no-param-reassign
                if (newKey.length > 0) user.key = newKey[0].email
              })
            })

            // We create an object that that looks like = Project1 : {Group1: timespent, Group2: timespent}
            const aggrGroups = []
            parsedAggregations.data.buckets.forEach(project => {
              if (!aggrGroups[project.key]) aggrGroups[project.key] = {}
              groupsArray.forEach(group => {
                project.time.buckets.forEach(user => {
                  if (group.initiatorGroupMembers.includes(user.key)) {
                    aggrGroups[project.key][group.initiatorGroupName] =
                      aggrGroups[project.key][group.initiatorGroupName] +
                        user.timespent.value || user.timespent.value
                  }
                })
              })
            })

            // Replace the old buckets with the aggregated groups
            parsedAggregations.data.buckets = []
            Object.keys(aggrGroups).forEach(groupName => {
              parsedAggregations.data.buckets.push({
                key: groupName,
                buckets: Object.entries(aggrGroups[groupName]).map(
                  ([key, value]) => {
                    return {
                      key,
                      timespent: {
                        value,
                      },
                    }
                  },
                ),
              })
            })
          }

          if (groupedBy === 'Groups' && stack === 'Users') {
            // We create an object that that looks like = User1 : {Group1: [timespent:{value},...]}
            const aggrGroups = []
            parsedAggregations.data.buckets.forEach(user => {
              groupsArray.forEach(group => {
                if (group.initiatorGroupMembers.includes(user.key)) {
                  // We check if the user is already in the object
                  if (!aggrGroups[user.name]) {
                    aggrGroups[user.name] = {}
                  }
                  // We check if the group is already in the object
                  if (!aggrGroups[group.initiatorGroupName]) {
                    aggrGroups[user.name][group.initiatorGroupName] = []
                  }
                  aggrGroups[user.name][group.initiatorGroupName].push(
                    user.time.buckets[0],
                  )
                }
              })
            })

            // Replace the old buckets with the aggregated groups
            parsedAggregations.data.buckets = []
            Object.keys(aggrGroups).forEach(groupName => {
              parsedAggregations.data.buckets.push({
                key: groupName,
                buckets: Object.entries(aggrGroups[groupName]).map(
                  ([key, value]) => {
                    return {
                      key,
                      timespent: value[0].timespent,
                    }
                  },
                ),
              })
            })
          }

          commit('addOriginalData', parsedAggregations)
        }

        // Pagination for workbits
        if (result.nextToken !== null && result.items.length === 1000) {
          await dispatch('pullData', {
            filter: payload.filter,
            pageToken: result.nextToken,
            member: payload.member,
            limit: payload.limit,
          })
        }
      })
      commit('stopExportData')
      return true
    },

    async pullDataReader({ commit, rootGetters, getters }, payload) {
      // Temp fix TODO refactor const
      const granularity = getters['filters/selectedOption']('granularity')
      const groupBy = getters['filters/selectedOption']('groupBy')
      const stack = getters['filters/selectedOption']('stack')

      const userSub = payload.member ? payload.member.sub : null
      // limit field is null to get aggregate data. Should be set to int if we need to get actual worklogs
      await API.graphql({
        query: getIntegrationData,
        variables: {
          organizationID:
            rootGetters['organization/current'].tablePartitionKey.split('#')[1],
          userSub,
          filter: payload.filter,
          timezone: rootGetters['profile/user']['custom:timezone'],
          limit: payload.limit || null,
          granularity,
          groupBy,
          stack,
          nextToken: payload.pageToken,
        },
      }).then(async response => {
        // If no items are returned, skip the formatation
        const result = response.data.getIntegrationData
        const members = getters['filters/membersOptions']
        const emails = getters['filters/emailsValue']

        commit('clearExportData')

        if (result.aggregations !== null) {
          const parsedAggregations = JSON.parse(result.aggregations)

          if (emails.length === 0)
            parsedAggregations.data.buckets =
              ChartHelper.mergeSecondaryAccountWorklog(
                members,
                parsedAggregations.data.buckets,
              )

          const groupedBy = getters['filters/selectedOption']('groupBy')

          let groupsArray = []
          // If there are selected groups group by them
          if (!getters['filters/allInitiatorGroupsFlag']) {
            groupsArray = getters['filters/initiatorGroupsValue']
            // Else group by all the groups
          } else {
            groupsArray = getters['filters/initiatorGroupsOptions']
          }

          if (stack === 'Users' && groupedBy !== 'Groups') {
            const usersBucket = []
            parsedAggregations.data.buckets.forEach(bucket => {
              const userData = { ...bucket }
              const member = members.filter(user => user.sub === bucket.key)
              userData.key = member.length > 0 ? member[0].name : bucket.key
              usersBucket.push(userData)
            })
            parsedAggregations.data.buckets = usersBucket
          }

          if (
            stack === 'Groups' ||
            (groupedBy === 'Groups' && stack === 'None') ||
            (groupBy === 'Groups' && stack === 'Users')
          ) {
            const usersBucket = []
            parsedAggregations.data.buckets.forEach(bucket => {
              const userData = { ...bucket }
              const member = members.filter(user => user.sub === bucket.key)
              userData.key = member.length > 0 ? member[0].email : bucket.key

              userData.name = member.length > 0 ? member[0].name : bucket.key
              usersBucket.push(userData)
            })
            parsedAggregations.data.buckets = usersBucket
          }

          // Check if the selected groupBy is Groups and group the parsedAggregations users by groups
          if (groupedBy === 'Groups' && stack === 'None') {
            // Initialize the object which will hold temporarily the groups and their aggregated time
            const aggrGroups = {}
            parsedAggregations.data.buckets.forEach(bucket => {
              groupsArray.forEach(group => {
                if (group.initiatorGroupMembers.includes(bucket.key)) {
                  // Add the users time to the groups total if it's the first one just assign it to the key
                  aggrGroups[group.initiatorGroupName] =
                    aggrGroups[group.initiatorGroupName] +
                      bucket.timespent.value || bucket.timespent.value
                }
              })
            })

            // Replace the old buckets with the aggregated groups
            parsedAggregations.data.buckets = []
            Object.keys(aggrGroups).forEach(groupName => {
              parsedAggregations.data.buckets.push({
                key: groupName,
                timespent: { value: aggrGroups[groupName] },
              })
            })
          }

          if (
            groupedBy === 'Groups' &&
            (stack === 'Projects' || stack === 'Issues')
          ) {
            parsedAggregations.data.buckets.forEach(bucket => {
              bucket.time.buckets.forEach(user => {
                const newKey = members.filter(member => member.sub === user.key)
                // eslint-disable-next-line no-param-reassign
                if (newKey.length > 0) user.key = newKey[0].email
              })
            })

            // We create an object that that looks like = Project1 : {Group1: timespent, Group2: timespent}
            const aggrGroups = []
            parsedAggregations.data.buckets.forEach(project => {
              if (!aggrGroups[project.key]) aggrGroups[project.key] = {}
              groupsArray.forEach(group => {
                project.time.buckets.forEach(user => {
                  if (group.initiatorGroupMembers.includes(user.key)) {
                    aggrGroups[project.key][group.initiatorGroupName] =
                      aggrGroups[project.key][group.initiatorGroupName] +
                        user.timespent.value || user.timespent.value
                  }
                })
              })
            })

            // Replace the old buckets with the aggregated groups
            parsedAggregations.data.buckets = []
            Object.keys(aggrGroups).forEach(groupName => {
              parsedAggregations.data.buckets.push({
                key: groupName,
                buckets: Object.entries(aggrGroups[groupName]).map(
                  ([key, value]) => {
                    return {
                      key,
                      timespent: {
                        value,
                      },
                    }
                  },
                ),
              })
            })
          }

          if (groupedBy === 'Groups' && stack === 'Users') {
            // We create an object that that looks like = User1 : {Group1: [timespent:{value},...]}
            const aggrGroups = []
            parsedAggregations.data.buckets.forEach(user => {
              groupsArray.forEach(group => {
                if (group.initiatorGroupMembers.includes(user.key)) {
                  // We check if the user is already in the object
                  if (!aggrGroups[user.name]) {
                    aggrGroups[user.name] = {}
                  }
                  // We check if the group is already in the object
                  if (!aggrGroups[group.initiatorGroupName]) {
                    aggrGroups[user.name][group.initiatorGroupName] = []
                  }
                  aggrGroups[user.name][group.initiatorGroupName].push(
                    user.time.buckets[0],
                  )
                }
              })
            })

            // Replace the old buckets with the aggregated groups
            parsedAggregations.data.buckets = []
            Object.keys(aggrGroups).forEach(groupName => {
              parsedAggregations.data.buckets.push({
                key: groupName,
                buckets: Object.entries(aggrGroups[groupName]).map(
                  ([key, value]) => {
                    return {
                      key,
                      timespent: value[0].timespent,
                    }
                  },
                ),
              })
            })
          }

          commit('addOriginalData', parsedAggregations)
        }
      })
      return true
    },
    async pullTableData({ commit, dispatch, rootGetters, getters }, payload) {
      commit('startExportData')
      setTimeout(() => {
        commit('stopExportData')
      }, 10000)
      // Temp fix TODO refactor const
      const granularity = getters['filters/selectedOption']('granularity')
      const groupBy = getters['filters/selectedOption']('groupBy')
      const stack = getters['filters/selectedOption']('stack')

      const userSub = payload.member ? payload.member.sub : null
      // limit field is null to get aggregate data. Should be set to int if we need to get actual worklogs
      await API.graphql({
        query: getIntegrationData,
        variables: {
          organizationID:
            rootGetters['organization/current'].tablePartitionKey.split('#')[1],
          userSub,
          filter: payload.filter,
          timezone: rootGetters['profile/user']['custom:timezone'],
          limit: payload.limit || null,
          granularity,
          groupBy,
          stack,
          nextToken: payload.pageToken,
        },
      }).then(async response => {
        // If no items are returned, skip the formatation
        const result = response.data.getIntegrationData

        if (result.items !== null && result.items.length > 0) {
          commit('addExportData', result.items)
        }

        // Pagination for workbits
        if (result.nextToken !== null && result.items.length === 1000) {
          await dispatch('pullTableData', {
            filter: payload.filter,
            pageToken: result.nextToken,
            member: payload.member,
            limit: payload.limit,
          })
        }
      })
      commit('stopExportData')
      return true
    },
    /*
     * Exclude the overtime data type if the overtime filter is not selected
     * Format the data in highcharts readable format
     * Convert the time from seconds to hours
     */

    async loadChart({ rootGetters, dispatch, commit, getters }) {
      commit('clearData')

      const current = rootGetters['organization/current']
      const readerData = rootGetters['profile/readerData']

      if (current.organizationRole === 'VIEWER') {
        const typeGroupChart =
          readerData.settings.defaultTime !== '0' ? '2' : ''
        await dispatch(
          `groupChartData${typeGroupChart}`,
          getters.notFormattedData[0],
        )
      } else {
        await dispatch('groupChartData', getters.notFormattedData[0])
      }

      /*
       * Set the lastPage flag to true => all of the data has been pulled.
       * The chart is waiting for this flag to be "true" in order to draw the chart
       */
      commit('setLastPageDataFlag', true)
    },
    clearAllStorages({ commit }) {
      // Reset the lastPage flag
      commit('setLastPageDataFlag', false)
      // Reset the previously collected data
      commit('clearData')
      // Reset the not formatted data (original data, received by the GraphQL) storage
      commit('clearNotFormattedData')
    },
    setFilterValue({ commit, dispatch, state }, { name, value, direction }) {
      const filterObj = state.filters

      if (name === undefined) return

      let oldValue

      if (name === 'granularity' || name === 'groupBy' || name === 'stack') {
        oldValue = filterObj.filters.list.options[name]
      } else if (name === 'apps') {
        oldValue = filterObj.filters.list.integrations.value
      } else {
        oldValue = filterObj.filters.list[name].value
      }

      const filterSetters = {
        dateRange() {
          commit('reports/filters/setFilterValue', {
            key: 'dateRange',
            value,
          })
        },
        members() {
          commit('filters/setFilterValue', { key: 'members', value })
        },
        emails() {
          commit('filters/setFilterValue', {
            key: 'emails',
            value,
          })
        },
        projects() {
          commit('filters/setFilterValue', { key: 'projects', value })
        },
        custom() {
          commit('filters/applyCustomFilter', value.list)
          commit('filters/setFilterValue', { key: 'custom', value })
        },
        apps() {
          commit('filters/setFilterValue', { key: 'integrations', value })
        },
        groups() {
          commit('filters/setFilterValue', {
            key: 'groups',
            value,
          })
        },
        granularity() {
          commit('filters/setFilterValue', {
            key: 'options',
            value: { name: 'granularity', value },
          })
          dispatch('reports/clearAllStorages', null, { root: true })
          dispatch('reports/prepareFiltersAndDataPull', null, { root: true })
        },
        stack() {
          commit('filters/setFilterValue', {
            key: 'options',
            value: { name: 'stack', value },
          })

          dispatch('reports/clearAllStorages', null, { root: true })
          dispatch('reports/prepareFiltersAndDataPull', null, { root: true })
        },
        groupBy() {
          commit('filters/setFilterValue', {
            key: 'options',
            value: { name: 'groupBy', value },
          })
          dispatch('loadChart').then(() =>
            dispatch('prepareFiltersAndDataPull'),
          )
        },
        search() {
          commit('filters/setFilterValue', {
            key: 'search',
            value,
          })
        },
        tags() {
          commit('filters/setFilterValue', { key: 'tags', value })
        },
      }
      filterSetters[name]?.()

      switch (direction) {
        case 'forward':
          if (filterObj.forward.length > 0) {
            filterObj.forward.pop()
            commit('filters/addToBackState', {
              name,
              oldValue,
            })
          }
          break
        case 'backward':
          if (filterObj.backward.length > 0) {
            filterObj.backward.pop()
            commit('filters/addToForwardState', {
              name,
              oldValue,
            })
          }
          break
        default:
          commit('filters/addToBackState', { name, oldValue })
          break
      }
    },
    resetFilters({ commit, dispatch }) {
      commit('filters/clearFilterValue', 'members')
      commit('filters/clearFilterValue', 'emails')
      commit('filters/clearFilterValue', 'projects')
      commit('filters/clearFilterValue', 'custom')
      commit('filters/clearFilterValue', 'apps')
      commit('filters/clearFilterValue', 'groups')
      commit('filters/clearFilterValue', 'granularity')
      commit('filters/clearFilterValue', 'stack')
      commit('filters/clearFilterValue', 'groupBy')
      commit('filters/clearFilterValue', 'search')
      commit('filters/clearFilterValue', 'tags')
      commit('filters/resetBackwardState')
      commit('filters/resetForwardState')

      window.history.pushState({}, null, `${window.location.pathname}`)

      dispatch('reports/clearAllStorages', null, { root: true })
      dispatch('reports/prepareFiltersAndDataPull', null, { root: true })

      dispatch('applyFilters')
    },
  },
  mutations: {
    setData(state, value) {
      state.data = value
    },
    clearData(state) {
      state.data = {}
    },
    setError(state, value) {
      state.error = value
    },
    clearError(state) {
      state.error = false
    },
    setLastPageDataFlag(state, value) {
      state.lastPageData = value
    },
    clearNotFormattedData(state) {
      state.notFormattedOriginalData = []
    },
    clearExportData(state) {
      state.exportData = []
    },
    startExportData(state) {
      state.exportDataInprogress = true
    },
    stopExportData(state) {
      state.exportDataInprogress = false
    },
    addOriginalData(state, items) {
      state.notFormattedOriginalData =
        state.notFormattedOriginalData.concat(items)
    },
    addExportData(state, items) {
      state.exportData = state.exportData.concat(items)
    },
    setSelectedFilters(state, value) {
      state.selectedFilters = value
    },
    setTableStatus(state, value) {
      state.tableStatus = value
    },
  },
  getters: {
    data: state => state.data,
    lastPageDataFlag: state => state.lastPageData,
    error: state => state.error,
    notFormattedData: state => state.notFormattedOriginalData,
    reportsPageStatus: state => state.reportsPageStatus,
    exportData: state => state.exportData,
    exportDataInprogress: state => state.exportDataInprogress,
    getSelectedFilters: state => state.selectedFilters,
    getTagsConditions: state => state.filters.list.tags,
    tableStatus: state => state.tableStatus,
  },
}
