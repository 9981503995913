<template>
  <ModalLayout id="ChangePasswordModal">
    <template v-slot:title>Change Password</template>
    <template v-slot:body>
      <form class="pass-change">
        <div class="form-group my-3">
          <!-- This is here to prevent browser warnings for better forms -->
          <input hidden type="text" autocomplete="username" value="username" />
          <label class="mb-2" for="curr-pass"> Current Password </label>
          <input
            v-model="current_pass"
            v-debounce:500ms="() => $emit('input', current_pass)"
            data-test="curr-pass"
            å
            type="password"
            class="form-control"
            :class="{
              'is-invalid':
                v$.current_pass.$invalid ||
                (error && errorType === 'wrong-pass'),
            }"
            placeholder="Password"
            autocomplete="current-password"
          />
          <small
            v-if="v$.current_pass.required.$invalid"
            class="text-danger mt-2 ms-2"
          >
            This field is required!
          </small>
        </div>
        <div class="form-group my-3">
          <label class="mb-2" for="new-pass"> New Password </label>
          <input
            v-model="new_pass"
            v-debounce:500ms="() => $emit('input', new_pass)"
            data-test="new-pass"
            class="form-control"
            :class="{
              'is-invalid': v$.new_pass.$invalid,
            }"
            type="password"
            placeholder="Password"
            autocomplete="new-password"
          />
          <small
            v-if="v$.new_pass.required.$invalid"
            class="text-danger mt-2 ms-2"
          >
            This field is required!
          </small>
          <small
            v-else-if="
              v$.new_pass.minLength.$invalid || v$.new_pass.maxLength.$invalid
            "
            class="text-danger mt-2 ms-2"
          >
            Password must be between 6 and 256 characters!
          </small>
          <small
            v-else-if="v$.new_pass.cognitoPass.$invalid"
            class="text-danger mt-2 ms-2"
          >
            Password needs to have at least:
            <ul>
              <li>1 Number,</li>
              <li>1 Lowercase letter</li>
              <li>1 Uppercase letter</li>
              <li>1 Special Symbol</li>
            </ul>
          </small>
        </div>
        <div class="form-group">
          <label class="mb-2" for="conf-pass"> Confirm Password </label>
          <input
            v-model="conf_pass"
            v-debounce:500ms="() => $emit('input', conf_pass)"
            data-test="conf-pass"
            class="form-control"
            :class="{
              'is-invalid': v$.conf_pass.$invalid,
            }"
            type="password"
            placeholder="Password"
            autocomplete="new-password"
          />
          <small
            v-if="v$.conf_pass.required.$invalid"
            class="text-danger mt-2 ms-2"
          >
            This field is required!
          </small>
          <small
            v-else-if="v$.conf_pass.samePass.$invalid"
            class="text-danger mt-2 ms-2"
          >
            Must match the new password.
          </small>
        </div>

        <div class="form-row col-sm-5"></div>
      </form>
    </template>
    <template v-slot:footer>
      <div
        v-if="error && errorType === 'wrong-pass'"
        class="invalid-feedback ms-1"
      >
        {{ errorMsg }}
      </div>
      <button type="button" class="btn btn-custom" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        data-test="submit-reset-pass"
        class="btn btn-primary"
        type="submit"
        @click="updatePass"
        :disabled="v$.$invalid"
      >
        Change password
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {
  sameAs,
  required,
  helpers,
  maxLength,
  minLength,
} from '@vuelidate/validators'
import { Modal } from 'bootstrap'
import ModalLayout from './ModalLayout.vue'

const cognitoPass = helpers.regex(
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{6,256}$/,
)

export default {
  components: {
    ModalLayout,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      current_pass: '',
      new_pass: '',
      conf_pass: '',
      error: false,
      errorMsg: '',
      errorType: '',
    }
  },
  validations() {
    return {
      current_pass: {
        required,
      },
      new_pass: {
        maxLength: maxLength(256),
        minLength: minLength(6),
        required,
        cognitoPass,
      },
      conf_pass: {
        samePass: sameAs(this.new_pass),
        required,
      },
    }
  },
  methods: {
    async updatePass() {
      const ChangePasswordModal = Modal.getOrCreateInstance(
        document.getElementById('ChangePasswordModal'),
      )

      this.$Amplify.Auth.currentAuthenticatedUser()
        .then(user => {
          return this.$Amplify.Auth.changePassword(
            user,
            this.current_pass,
            this.new_pass,
          )
        })
        .then(() => {
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'success',
            msg: 'Success updating password',
          })
          ChangePasswordModal.hide()
        })
        .catch(err => {
          if (err.code === 'NotAuthorizedException') {
            this.error = true
            this.errorType = 'wrong-pass'
            this.errorMsg = 'Incorrect password.'
          }

          if (err.code === 'LimitExceededException') {
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: 'error',
              msg: err.message,
            })
          }
        })
    },
  },
}
</script>
