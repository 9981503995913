var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'not-authorized': !_vm.checkLogin },attrs:{"id":"app"}},[(
        _vm.checkLogin &&
        _vm.$route.name !== 'onboard' &&
        _vm.$route.name !== 'subscription-plan' &&
        _vm.$route.name !== 'invoiceInfo'
      )?_c('AuthorizedLayout'):(
        _vm.$route.name === 'shared' ||
        _vm.$route.name === 'onboard' ||
        _vm.$route.name === 'subscription-plan' ||
        _vm.$route.name === 'invoiceInfo'
      )?_c('SharedDocument'):_c('NotAuthorizedLayout')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }