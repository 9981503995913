<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      :fill="color"
      d="M150,300C67.15,300,0,232.85,0,150S67.15,0,150,0s150,67.15,150,150-67.15,150-150,150Zm-14.06-229.69v79.69c0,4.69,2.34,9.08,6.27,11.72l56.25,37.5c6.45,4.34,15.18,2.58,19.51-3.93s2.58-15.18-3.93-19.51l-49.98-33.28V70.31c0-7.79-6.27-14.06-14.06-14.06s-14.06,6.27-14.06,14.06Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClockIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
