<template>
  <router-link
    :to="route"
    class="d-flex align-items-center text-decoration-none"
  >
    <WmLogo />
    <h5 class="mt-3 mx-2 text-uppercase">{{ title }}</h5>
  </router-link>
</template>

<script>
import WmLogo from '../../assets/icons/WmLogo.vue'

export default {
  components: {
    WmLogo,
  },
  props: {
    title: String,
    route: String,
  },
}
</script>

<style scoped>
h5 {
  font-weight: 900;
  color: #858796;
}
h5:hover {
  color: #9395a5;
}
a:hover {
  text-decoration: none !important;
}
</style>
