// Custom Filters Object
function CustomFiltersObject(name, filters = {}, owner, access, settings) {
  this.name = name
  this.list = filters
  this.owner = owner
  this.access = access
  this.settings = settings
}

export default function CustomFilters(options = [], value = []) {
  this.value = value
  this.options = options

  this.setValue = input => {
    if (!input) return
    const filteredValue = this.options.filter(filter => input === filter)
    this.value = filteredValue
  }
  this.addOption = input => {
    if (!input || this.options?.includes(input)) return
    const { name, filters, access, settings } = input
    const owner = input.tableSortKey.split('#')[1]
    const customFilter = new CustomFiltersObject(
      name,
      filters,
      owner,
      access,
      settings,
    )
    this.options.push(customFilter)
  }
  this.clearValue = () => {
    this.value = { name: 'None' }
  }

  this.removeOption = name => {
    this.options = this.options.filter(option => option.name !== name)
  }
  // Sets value of filter based on array of properties and property name
  this.setValueByProperty = (propName, propValue) => {
    if (!propValue) return
    // gets object from options if exist
    const val =
      this.options.find(option => option[propName] === propValue) || []
    this.setValue(val)
  }
}
