<template>
  <svg xmlns="http://www.w3.org/2000/svg" :fill="color" viewBox="0 0 448 384">
    <path
      d="M416,64H32C14.33,64,0,49.67,0,32H0C0,14.33,14.33,0,32,0H416c17.67,0,32,14.33,32,32h0c0,17.67-14.33,32-32,32Zm-112,160H144c-17.67,0-32-14.33-32-32h0c0-17.67,14.33-32,32-32h160c17.67,0,32,14.33,32,32h0c0,17.67-14.33,32-32,32Zm-56,160h-48c-17.67,0-32-14.33-32-32h0c0-17.67,14.33-32,32-32h48c17.67,0,32,14.33,32,32h0c0,17.67-14.33,32-32,32Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'FiltersIcon',
  props: {
    color: {
      type: [String],
      default: '#858796',
    },
  },
}
</script>
