<template>
  <div class="d-flex w-100 justify-content-between px-2">
    <NavbarTitle title="wildmetric" route="/shared" />
    <div class="m-auto">
      <ul class="navbar-nav">
        <li class="nav-item px-2">
          <router-link
            :to="route"
            class="nav-link"
            :class="this.$route.name === 'shared' ? 'router-link-active' : ''"
          >
            <span class="text-center w-100">Reports</span>
          </router-link>
        </li>
      </ul>
    </div>
    <NavbarUtilsShared />
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { DateTime } from 'luxon'
import {
  onCreateWildmetricNotifications,
  onDeleteJoinOrganizationRequest,
  onDeleteWildmetricNotification,
} from '../../graphql/subscriptions'
import NavbarUtilsShared from './NavbarUtilsShared.vue'
import NavbarTitle from './NavbarTitle.vue'

export default {
  components: {
    NavbarUtilsShared,
    NavbarTitle,
  },
  data() {
    return {
      notificationSubscribe: null,
      removeJoinOrganizatioRequest: null,
      deleteNotificationSubscribe: null,
    }
  },
  created() {
    this.notificationSubscribe = API.graphql({
      query: onCreateWildmetricNotifications,
    }).subscribe({
      next: ({ value }) => {
        const response = value.data.onCreateWildmetricNotifications
        if (response) {
          if (this.$store.getters['profile/user']['custom:timezone']) {
            response.timestamp = DateTime.fromISO(response.timestamp)
              .toUTC()
              .setZone(this.$store.getters['profile/user']['custom:timezone'])
              .toFormat('dd LLL yyyy h:mm a')
          } else {
            response.timestamp = DateTime.fromISO(response.timestamp)
              .toUTC()
              .toFormat('dd LLL yyyy h:mm a')
          }
          this.$store.dispatch('notifications/addNewNotifications', response)

          if (
            response.actionGetters !== 'null' ||
            this.$store.getters[
              'notifications/shownNotifications'
            ].types.includes(response.classification)
          ) {
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: response.classification,
              msg: response.messageBody,
            })
          }
        }
      },
      // TODO fix this
      // eslint-disable-next-line no-console
      error: error => console.log(error),
    })

    this.removeJoinOrganizatioRequest = API.graphql({
      query: onDeleteJoinOrganizationRequest,
    }).subscribe({
      next: async ({ value }) => {
        await this.$store.dispatch('organization/joined')
        const joinedOrg = this.$store.getters['organization/joined'].filter(
          org =>
            org.tablePartitionKey ===
            `organization#${
              value.data.onDeleteJoinOrganizationRequest.tableSortKey.split(
                '#',
              )[2]
            }`,
        )

        if (joinedOrg.length > 0) {
          this.$store.dispatch('profile/forceUpdateUser')
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'success',
            msg: `Organization ${joinedOrg[0].displayName} joined.`,
          })
        }

        this.$store.dispatch(
          'userRequests/removeUserRequest',
          value.data.onDeleteJoinOrganizationRequest,
        )
      },
      // TODO fix this
      // eslint-disable-next-line no-console
      error: error => console.log(error),
    })

    this.deleteNotificationSubscribe = API.graphql({
      query: onDeleteWildmetricNotification,
    }).subscribe({
      next: ({ value }) => {
        const response = value.data.onDeleteWildmetricNotification
        if (response) {
          this.$store.dispatch(
            'notifications/removeNotificationFromStore',
            response,
          )
        }
      },
      // TODO fix this
      // eslint-disable-next-line no-console
      error: error => console.log(error),
    })
  },
  beforeDestroy() {
    // unsubscribe and reset variables
    this.notificationSubscribe.unsubscribe()
    this.notificationSubscribe = null

    this.removeJoinOrganizatioRequest.unsubscribe()
    this.removeJoinOrganizatioRequest = null

    this.deleteNotificationSubscribe.unsubscribe()
    this.deleteNotificationSubscribe = null
  },
  computed: {
    route() {
      const orgId =
        this.$store.getters['organization/current'].tablePartitionKey.split(
          '#',
        )[1]

      return `${orgId}/shared`
    },
  },
}
</script>

<style scoped>
.router-link-active::after {
  content: '';
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.6px;
  background-color: rgb(87, 87, 87);
}
.router-link-active span {
  color: rgb(87, 87, 87);
  font-weight: 600;
}
.nav-link {
  min-width: 7rem;
  font-weight: 400;
}
</style>
