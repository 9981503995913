import { API, graphqlOperation } from 'aws-amplify'
import {
  listWildmetricCountriesTimezones,
  getWildmetricCollectionRecords,
} from '../../graphql/queries'
import { updateUserWorktime } from '../../graphql/mutations'

const preferences = {
  namespaced: true,
  state: () => ({
    timezone: '',
    timezones: [],
    defaultOrg: '',
    firstDayWeek: '',
    workSchedule: {
      sameDay: true,
      sameDayTime: {
        startTime: '',
        endTime: '',
      },
      workDays: {
        monday: { active: true, startTime: '', endTime: '' },
        tuesday: { active: true, startTime: '', endTime: '' },
        wednesday: { active: true, startTime: '', endTime: '' },
        thursday: { active: true, startTime: '', endTime: '' },
        friday: { active: true, startTime: '', endTime: '' },
        saturday: { active: false, startTime: '', endTime: '' },
        sunday: { active: false, startTime: '', endTime: '' },
      },
    },
    holidays: [{ name: '', type: '' }],
    err: '',
  }),
  mutations: {
    setInitialState(state, value) {
      state.timezone = value['custom:timezone']
      state.defaultOrg = value['custom:default_org']
      state.firstDayWeek = value['custom:first_day']
    },
    setFirstDayWeek(state, value) {
      state.firstDayWeek = value
    },
    setDefaultOrg(state, value) {
      state.defaultOrg = value
    },
    setTimezone(state, value) {
      state.timezone = value
    },
    setTimezoneOptions(state, value) {
      state.timezones = state.timezones.concat(value)
    },
    setError(state, value) {
      state.err = value
    },
    setHolidays(state, value) {
      state.holidays = value
    },
    addHoliday(state, value) {
      state.holidays.push(value)
    },
    setSameDay(state, value) {
      state.workSchedule.sameDay = value
    },
    setSameDayTime(state, value) {
      state.workSchedule.sameDayTime = value
    },
    setWorkDays(state, value) {
      state.workSchedule.workDays = value
    },
    setInitialWorkSchedule(state, value) {
      state.workSchedule = value
    },
  },
  actions: {
    setInitialState({ rootGetters, commit, dispatch }) {
      const userData = rootGetters['profile/user']
      commit('setInitialState', userData)
      dispatch('fetchWorkData')
    },
    async fetchTimeZones({ commit, dispatch }, nextToken) {
      let timezones = []
      try {
        await API.graphql(
          graphqlOperation(listWildmetricCountriesTimezones, {
            nextToken,
          }),
        ).then(countries => {
          const token =
            countries.data.listWildmetricCountriesTimezones.nextToken
          countries.data.listWildmetricCountriesTimezones.items.forEach(
            zone => {
              const formatedZone = JSON.parse(zone.zones)
              const optionFormat = formatedZone.map(el => {
                return {
                  value: el.zoneName,
                  label: `GMT${el.label} ${el.zoneName}`,
                  sortKey: el.gmtOffset / 3600,
                }
              })
              timezones = timezones.concat(optionFormat)
            },
          )
          commit('setTimezoneOptions', timezones)
          if (token) {
            dispatch('fetchTimeZones', token)
          }
        })
      } catch (err) {
        commit('setError', err)
      }
    },
    async fetchWorkData({ rootGetters, commit }) {
      const userData = rootGetters['profile/user']
      let holidaysHolder = []
      API.graphql(
        graphqlOperation(getWildmetricCollectionRecords, {
          tablePartitionKey: `userSub#${userData.sub}`,
          tableSortKey: `userSettings#${userData.sub}`,
          organization: rootGetters['organization/current'].tablePartitionKey
            .split('#')
            .pop(''),
        }),
      ).then(response => {
        if (response.data.getWildmetricCollectionRecords) {
          const parsedJson = JSON.parse(
            response.data.getWildmetricCollectionRecords.worktime,
          )

          if (parsedJson.holidays) {
            holidaysHolder = parsedJson.holidays
            holidaysHolder.push({ name: '', type: '' })

            commit('setHolidays', holidaysHolder)
          }

          if (parsedJson.workSchedule)
            commit('setInitialWorkSchedule', parsedJson.workSchedule)
        }
      })
    },
    async saveWorkData({ getters, dispatch }, type) {
      const holidayArr = getters.holidays
      holidayArr.pop()

      const input = {
        worktime: JSON.stringify({
          workSchedule: getters.workSchedule,
          holidays: holidayArr,
        }),
      }

      API.graphql(
        graphqlOperation(updateUserWorktime, {
          input,
        }),
      )
        .then(() => {
          dispatch(
            'notifications/alerts/appendAlert',
            { type: 'success', msg: `${type} saved successfully.` },
            { root: true },
          )
        })
        .catch(err => {
          dispatch(
            'notifications/alerts/appendAlert',
            { type: 'error', msg: err.errors[0].message },
            { root: true },
          )
        })
    },
  },
  getters: {
    preferences: state => state,
    timezone: state => state.timezone,
    timezones: state => state.timezones,
    defaultOrg: state => state.defaultOrg,
    firstDayWeek: state => state.firstDayWeek,
    workSchedule: state => state.workSchedule,
    holidays: state => state.holidays,
  },
}

export default preferences
