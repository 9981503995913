import { API } from 'aws-amplify'
import {
  linkSlaveToMaster,
  addMember,
  deleteJoinOrganizationRequest,
} from '../../graphql/mutations'

const actions = {
  namespaced: true,
  state: {
    getLinkAccountActions: {
      accept: 'linkAccounts',
      cancel: 'removeNotification',
    },
    getJoinOrganizationActions: {
      accept: 'addMember',
      cancel: 'removeOrgMembersNotification',
    },
  },
  actions: {
    async linkAccounts({ dispatch }, notificationData) {
      await API.graphql({
        query: linkSlaveToMaster,
        variables: {
          input: {
            linkedAccountSub: notificationData.dataSource.split('#')[1],
            organizationID: notificationData.organization,
          },
        },
      })
        .then(() => {
          dispatch(
            'notifications/deleteNotificationfromTable',
            notificationData,
            {
              root: true,
            },
          )
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'success',
              msg: 'Accounts linked successfully.',
            },
            { root: true },
          )
          dispatch(
            `organization/org#${notificationData.organization}/getOrganizationPeople`,
            notificationData.organization,
            {
              root: true,
            },
          )
        })
        .catch(err => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: err.errors[0].message,
            },
            { root: true },
          )
        })
    },
    async addMember({ rootGetters, dispatch }, notificationData) {
      const splitSource = notificationData.dataSource.split('#')
      const tableSortKey = `${splitSource[0]}#${splitSource[1]}`
      const userEmail = splitSource[2]
      await API.graphql({
        query: addMember,
        variables: {
          input: {
            organizationID: notificationData.organization,
            tableSortKey,
            userEmail,
            organizationRole: 'USER',
            displayName: rootGetters['organization/joined'].find(organization =>
              organization.tablePartitionKey.includes(
                notificationData.organization,
              ),
            ).displayName,
            userFullName: notificationData.messageBody.split('wants')[0].trim(),
          },
        },
      })
        .then(async () => {
          await dispatch('removeOrgMembersNotification', notificationData)
          dispatch(
            `organization/org#${notificationData.organization}/getOrganizationPeople`,
            notificationData.organization,
            {
              root: true,
            },
          )
        })
        .catch(async () => {
          dispatch(
            'notifications/alerts/appendAlert',
            {
              type: 'error',
              msg: 'Failed adding user to orgnanization. He may be a member already.',
            },
            {
              root: true,
            },
          )

          await dispatch('removeNotification', notificationData)
        })
    },
    async removeNotification({ dispatch }, notificationData) {
      dispatch('notifications/deleteNotificationfromTable', notificationData, {
        root: true,
      })
    },
    async removeOrgMembersNotification(
      { dispatch, rootGetters },
      notificationData,
    ) {
      await API.graphql({
        query: deleteJoinOrganizationRequest,
        variables: {
          input: {
            userSub: notificationData.dataSource.split('#')[1],
            organizationID: notificationData.organization,
            notificationID: notificationData.tableSortKey.split('#')[1],
          },
        },
      }).then(() => {
        // Loop over organization members and delete notifications for each
        rootGetters[
          `organization/org#${notificationData.organization}/users`
        ].forEach(user => {
          const userNotification = notificationData
          userNotification.tablePartitionKey = `notification#${user.sub}`
          dispatch(
            'notifications/deleteNotificationfromTable',
            userNotification,
            {
              root: true,
            },
          )
        })
      })
    },
  },
  getters: {
    getLinkAccountActions: state => state.getLinkAccountActions,
    getJoinOrganizationActions: state => state.getJoinOrganizationActions,
  },
}

export default actions
