<template>
  <ModalLayout @opened="resetValues" id="JoinOrganizationModal">
    <template v-slot:title
      >{{ isJoin ? 'Join' : 'Create' }} Organization</template
    >
    <template v-slot:body>
      <div class="d-flex flex-column my-4">
        <div class="form-group">
          <label class="mb-2">Organization {{ isJoin ? 'ID' : 'Name' }}</label>
          <input
            v-model="organizationName"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': v$.organizationName.$invalid,
            }"
            :placeholder="
              isJoin ? 'organization Id...' : 'Organization name...'
            "
          />
          <small
            v-if="v$.organizationName.required.$invalid"
            class="text-danger mt-2 ms-2"
          >
            This field is required!
          </small>
          <small
            v-else-if="v$.organizationName.maxLength.$invalid"
            class="text-danger mt-2 ms-2"
          >
            Max length is 39 character
          </small>
        </div>
        <div v-if="!isJoin">
          <label class="form-text mt-3">Your URL will be: </label>
          <URLInput
            v-model="organizationSlug"
            path="/organization/"
            :disableCopy="v$.organizationSlug.required.$invalid"
            :disableInput="v$.organizationName.required.$invalid"
          />
          <small class="form-text">
            Unique identifier of your organization.
          </small>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex w-100 justify-content-start">
        <span>Want to {{ isJoin ? 'create' : 'join' }} an organization?</span>
        <button class="btn btn-link border-0 ps-1" @click="isJoin = !isJoin">
          {{ isJoin ? 'Create' : 'Join' }}
        </button>
      </div>
      <div
        class="d-flex justify-content-between w-100 align-items-center gap-3"
      >
        <button
          type="button"
          class="btn btn-outline-secondary same-width"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          :disabled="v$.$invalid"
          @click="isJoin ? join() : create()"
          class="btn btn-primary same-width"
        >
          {{ isJoin ? 'Join' : 'Create' }}
        </button>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { Modal } from 'bootstrap'
import { API, graphqlOperation } from 'aws-amplify'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxLength } from '@vuelidate/validators'
import {
  createOrganization,
  joinOrganization,
} from '../../../graphql/mutations'
import URLInput from '../URLInput.vue'
import ModalLayout from './ModalLayout.vue'

export default {
  name: 'JoinOrganization',
  components: {
    URLInput,
    ModalLayout,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      organizationName: '',
      organizationSlug: '',
      isJoin: true,
    }
  },
  validations: {
    organizationName: {
      required,
      maxLength: maxLength(39),
    },
    organizationSlug: {
      required: requiredIf(function isJoin() {
        return !this.isJoin
      }),
    },
  },
  methods: {
    resetValues() {
      this.organizationName = ''
      this.organizationSlug = ''
      this.isJoin = true
    },
    async create() {
      const joinOrgModal = Modal.getOrCreateInstance(
        document.getElementById('JoinOrganizationModal'),
      )
      try {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        const orgs = await this.$store.getters['organization/joined']
        let firstOrg = false
        if (orgs.length === 0) {
          firstOrg = true
        }

        const res = await API.graphql(
          graphqlOperation(createOrganization, {
            input: {
              slug: this.organizationSlug,
              displayName: this.organizationName,
              userEmail: user.attributes.email,
              settings: JSON.stringify({}),
            },
          }),
        )
        const orgId =
          res.data.createOrganization.tablePartitionKey.split('#')[1]

        if (firstOrg) {
          await this.$Amplify.Auth.updateUserAttributes(user, {
            'custom:default_org': this.organizationName,
          }).then(() => {
            this.$store.dispatch('profile/forceUpdateUser')
          })
        }
        await this.$store.dispatch('organization/joined')
        this.$router.push(`/organization/${orgId}`)

        joinOrgModal.hide()
      } catch ({ errors }) {
        this.createsubmission = false
        this.$store.dispatch('notifications/alerts/appendAlert', {
          type: 'error',
          msg: errors[0].message,
        })
      }
    },
    async join() {
      const joinOrgModal = Modal.getOrCreateInstance(
        document.getElementById('JoinOrganizationModal'),
      )
      try {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()

        await API.graphql({
          query: joinOrganization,
          variables: {
            input: {
              displayName:
                this.organizationName ||
                this.organizationName.replace(/[^a-z0-9-]/gi, '').toLowerCase(),
              userFullName: user.attributes.family_name
                ? `${user.attributes.given_name} ${user.attributes.family_name}`
                : user.attributes.given_name,
              userEmail: user.attributes.email,
            },
          },
        }).then(async () => {
          await this.$store.dispatch('userRequests/getUserRquests', null)
          joinOrgModal.hide()
        })
      } catch (err) {
        if (err.errors[0].errorType === 'LimitReached') {
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'error',
            msg: err.errors[0].message,
          })
        } else {
          this.$store.dispatch('notifications/alerts/appendAlert', {
            type: 'error',
            msg: 'Error joining organization.',
          })
        }
      }
    },
  },
  watch: {
    organizationName(name) {
      if (this.isJoin) return
      this.organizationSlug = name.replace(/[^a-z0-9-]/gi, '').toLowerCase()
    },
    isJoin() {
      if (!this.isJoin)
        this.organizationSlug = this.organizationName
          .replace(/[^a-z0-9-]/gi, '')
          .toLowerCase()
    },
  },
}
</script>
<style lang="scss" scoped>
.same-width {
  min-width: 80px !important;
  flex: 1;
}
</style>
