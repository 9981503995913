export default function BasicFilter(filterInput) {
  const { options, value, labelKey } = filterInput || {}
  this.value = value || []
  this.options = options || []
  this.labelKey = labelKey || 'label'

  this.sortOptions = () => {
    this.options.sort((aOption, bOption) => {
      if (this.value.includes(aOption) && this.value.includes(bOption)) return 0
      if (this.value.includes(aOption) && !this.value.includes(bOption))
        return -1
      if (!this.value.includes(aOption) && this.value.includes(bOption))
        return 1
      return 0
    })
  }
  this.setValue = input => {
    if (!input) return
    const filteredValue = this.options.filter(integration => {
      return input.includes(integration)
    })
    this.value = filteredValue
    this.sortOptions()
  }
  this.addOption = input => {
    if (!input || this.options?.includes(input)) return
    this.options.push(input)
  }
  this.addValue = input => {
    if (!input || this.value?.includes(input)) return
    this.value.push(input)
  }
  // This clears the selected values
  this.clearValue = () => {
    this.value = []
  }
  // This clears the options in the dropsdowns
  this.clearOptions = () => {
    this.options = []
    this.value = []
  }
  this.setValueByLabel = input => {
    if (this.labelKey === 'none') {
      this.setValue(input)
      return
    }
    this.setValueByProperty(this.labelKey, input)
  }
  // Sets value of filter based on array of properties and property name
  this.setValueByProperty = (propName, propValues) => {
    if (!propValues) return
    // Goes through propValues and gets object from options if exist
    const values = propValues.flatMap(
      val => this.options.find(option => option[propName] === val) || [],
    )
    this.setValue(values)
  }

  this.getValueParams = name => {
    const params = this.value.flatMap(entry => {
      const label = this.labelKey === 'none' ? entry : entry[labelKey]
      return label || []
    })
    if (!params.length) return null
    return { name, value: params }
  }
}
