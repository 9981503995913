<template>
  <ModalLayout id="DeleteOrganizationModal">
    <template v-slot:title>Delete Organization</template>
    <template v-slot:body>
      <p>Type your password to confirm the deletion of the organization</p>
      <form class="form-group my-3">
        <!-- This is here to prevent browser warnings for better forms -->
        <input hidden type="text" autocomplete="username" value="username" />
        <input
          v-model="current_pass"
          data-test="curr-pass"
          type="password"
          class="form-control"
          :class="{
            'is-invalid':
              v$.current_pass.$invalid || (error && errorType === 'wrong-pass'),
          }"
          placeholder="Password"
          autocomplete="current-password"
        />
        <small
          v-if="v$.current_pass.required.$invalid"
          class="text-danger mt-2 ms-2"
        >
          This field is required!
        </small>
      </form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-custom" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        data-test="submit-reset-pass"
        class="btn btn-danger"
        type="submit"
        :disabled="deleteOrgLoader"
        @click="deleteOrganization"
      >
        <span
          v-if="deleteOrgLoader"
          class="spinner-border spinner-border-sm mx-3 text-white"
        ></span>
        <span v-else>Delete</span>
      </button>
    </template>
  </ModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Auth } from 'aws-amplify'
import { Modal } from 'bootstrap'
import ModalLayout from './ModalLayout.vue'

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      current_pass: '',
      error: false,
      errorMsg: '',
      errorType: '',
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    current_pass: {
      required,
    },
  },
  methods: {
    async deleteOrganization() {
      const DeleteOrganizationModal = Modal.getOrCreateInstance(
        document.getElementById('DeleteOrganizationModal'),
      )

      this.$store.dispatch('loadingManager/start', 'deleteOrg')

      const cognitoUser = await Auth.currentAuthenticatedUser()
      Auth.changePassword(cognitoUser, this.current_pass, this.current_pass)
        .then(async () => {
          const org = this.$route.params.orgId
          this.$store
            .dispatch('organization/deleteOrganization', { org })
            .then(async () => {
              this.$store.dispatch('loadingManager/end', 'deleteOrg')
              this.$store.commit('organization/setCurrent', false)
              this.$store.commit('organization/setJoined', {})
              this.$store.commit('resetIntegration')

              await this.$store.dispatch('organization/joined')
              const orgs = this.$store.getters['organization/joined'].filter(
                el => el.status !== 'disabled',
              )
              DeleteOrganizationModal.hide()

              if (orgs.length === 0) {
                this.$router.push({ path: '/onboard' })
              } else {
                this.$router.push({ path: '/organizations' })
              }
            })
        })
        .catch(err => {
          this.$store.dispatch('loadingManager/end', 'deleteOrg')
          if (err.code === 'NotAuthorizedException') {
            this.error = true
            this.errorType = 'wrong-pass'
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: 'error',
              msg: 'Wrong password',
            })
          }
          if (err.code === 'LimitExceededException') {
            this.error = true
            this.errorType = 'wrong-pass'
            this.$store.dispatch('notifications/alerts/appendAlert', {
              type: 'error',
              msg: 'Attempt limit exceeded, please try after some time.',
            })
          }
        })
    },
  },
  computed: {
    currentOrg() {
      return this.$store.getters['organization/current']
    },
    deleteOrgLoader() {
      return this.$store.getters['loadingManager/isLoading']('deleteOrg')
    },
  },
}
</script>
