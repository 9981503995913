<template>
  <div id="app" :class="{ 'not-authorized': !checkLogin }">
    <AuthorizedLayout
      v-if="
        checkLogin &&
        $route.name !== 'onboard' &&
        $route.name !== 'subscription-plan' &&
        $route.name !== 'invoiceInfo'
      "
    />
    <SharedDocument
      v-else-if="
        $route.name === 'shared' ||
        $route.name === 'onboard' ||
        $route.name === 'subscription-plan' ||
        $route.name === 'invoiceInfo'
      "
    />
    <NotAuthorizedLayout v-else />
  </div>
</template>

<script>
import AuthorizedLayout from './layout/Authorized.vue'
import NotAuthorizedLayout from './layout/NotAuthorized.vue'
import SharedDocument from './layout/SharedLayout.vue'

export default {
  name: 'Layout',
  components: {
    AuthorizedLayout,
    NotAuthorizedLayout,
    SharedDocument,
  },
  computed: {
    checkLogin() {
      return this.$store.state.loggedInUser
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import './assets/scss/zettalink.scss';

#app.not-authorized {
  background: $blue;
}
</style>
