// Base Utils
const uniq = array => {
  return array.filter((el, index, arr) => index === arr.indexOf(el))
}

const loading = {
  namespaced: true,
  state: {
    activeLoaders: [],
  },
  mutations: {
    startLoading(state, loaderMessage) {
      state.activeLoaders.push(loaderMessage)
      state.activeLoaders = uniq(state.activeLoaders)
    },
    endLoading(state, loaderMessage) {
      state.activeLoaders = uniq(state.activeLoaders).filter(
        p => p !== loaderMessage,
      )
    },
    clearLoading(state) {
      state.activeLoaders = []
    },
  },
  actions: {
    start: ({ commit }, loaderMessage) => commit('startLoading', loaderMessage),
    end: ({ commit }, loaderMessage) => commit('endLoading', loaderMessage),
    clear: ({ commit }) => commit('clearLoading'),
  },
  getters: {
    isLoading: state => loaderMessage =>
      state.activeLoaders.indexOf(loaderMessage) > -1,
    anyLoading: state => state.activeLoaders.length > 0,
    isOnly: state => loaderMessage =>
      state.activeLoaders.indexOf(loaderMessage) > -1 &&
      state.activeLoaders.length === 1,
  },
}

export default loading
