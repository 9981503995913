import { API, graphqlOperation } from 'aws-amplify'
import { getUserRequests } from '../../graphql/queries'

export default {
  namespaced: true,
  state: {
    userRquests: [],
  },
  actions: {
    async getUserRquests({ commit, dispatch }, nextToken) {
      await API.graphql(
        graphqlOperation(getUserRequests, {
          nextToken,
        }),
      ).then(async requests => {
        commit('setUserRequests', requests.data.getUserRequests.items)
        if (requests.data.getUserRequests.nextToken !== null) {
          await dispatch(
            'getUserRquests',
            requests.data.getUserRequests.nextToken,
          )
        }
      })
    },
    clearRequests({ commit }) {
      commit('clearUserRequests')
    },
    removeUserRequest({ commit, getters }, userRequest) {
      const updatedRequests = getters.getAllUserRequests.filter(
        request => request.tableSortKey !== userRequest.tableSortKey,
      )
      commit('clearUserRequests')
      commit('setUserRequests', updatedRequests)
    },
  },
  mutations: {
    setUserRequests(state, requests) {
      state.userRquests = state.userRquests.concat(requests)
    },
    clearUserRequests(state) {
      state.userRquests = []
    },
  },
  getters: {
    getJoinOrganizationRequests: state =>
      [...state.userRquests].filter(request =>
        request.tableSortKey.includes('joinOrganization'),
      ),
    getAllUserRequests: state => state.userRquests,
  },
}
